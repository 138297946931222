<template>
  <div>
    <span class="sub-title">BES HESAPLARIM</span>
    <div id="grid"></div>
  </div>
</template>
<script>
export default {
  name: "besAccountDetails",
  mounted() {
    /* eslint-disable */
		var token = sessionStorage.token;
		kendo.culture("tr-TR");
		function RunKendoUser() {
			var remoteDataSource = new kendo.data.DataSource({
				pageSize: 10,
				transport: {
					read: {
						url: "https://baymak.tutkal.com.tr/Api/PrivatePensionSystem/Get",
						dataType: "json",
						type: "POST",
						contentType: "application/json",
						beforeSend: function(req) {
							req.setRequestHeader("token", token);
						},
						complete: function(data) {}
					},

					parameterMap: function(options, operation) {
						operation = operation;

						if (operation === "read") {
							//Export settings start
							//Export settings stop
						}

						if (operation !== "read" && options.model) {
							return JSON.stringify(options.models[0]);
						} else {
							return JSON.stringify(options);
						}
					}
				},
				filter: [
					{ field: "statusId", operator: "in", value: "'1','3'", logic: "and" }
				],

				schema: {
					data: "data",
					total: "total",
					model: {
						id: "id",
						fields: {
							id: { editable: false, hidden: true, defaultValue: 0 }
						}
					}
				},
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true,
				error: function(e) {}
			});

			$("#grid").kendoGrid({
				dataSource: remoteDataSource,

				scrollable: true,
				sortable: true,
				height: 290,
				filterable: {
					extra: true,
					operators: {
						string: {
							contains: "Bunu içeren",
							startswith: "Bununla Başlayan",
							endswith: "Bununla biten",
							eq: "Eşit",
							neq: "Eşit olmayan"
						}
					}
				},
				resizable: true,
				pageable: {
					refresh: true,
					pageSizes: false,
					buttonCount: 5
				},
				columns: [
					{
						field: "code",
						title: "Poliçe Numarası",
						filterable: true
					},
					{
						field: "name",
						title: "Ad"
					},
					{
						field: "surname",
						title: "Soyad"
					},
					{
						field: "amount",
						title: "Detay"
					},
					{
						field: "statusTitle",
						title: "Durum"
					}
				]
			});
		}
		RunKendoUser();
	}
};
</script>