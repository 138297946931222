/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/user/Login.vue";
import Forgot from "../views/user/Forgot.vue";
import Faq from "../views/Faq.vue";
import CustomCampaign001 from "../views/CustomCampaign001.vue";
import CustomCampaign002 from "../views/CustomCampaign002.vue";
import CustomCampaign003 from "../views/CustomCampaign003.vue";
import CustomCampaign004 from "../views/CustomCampaign004.vue";
import CustomCampaign005 from "../views/CustomCampaign005.vue";
import CustomCampaignIzmir from "../views/CustomCampaignIzmir.vue";
import CustomCampaignIstanbul from "../views/CustomCampaignIstanbul.vue";
import SafetyPrivacy from "../views/SafetyPrivacy.vue";
import Contact from "../views/Contact.vue";
import WhatIs from "../views/WhatIs.vue";
import Register from "../views/user/Register.vue";
import Account from "../views/Account.vue";
import ActiveCampaigns from "../views/Campaigns/Actives.vue";
import PassiveCampaigns from "../views/Campaigns/Passives.vue";
import CompletedCampaigns from "../views/Campaigns/Completeds.vue";
import JoinedCampaigns from "../views/Campaigns/Joineds.vue";
import newCampaignDetails from "../views/Campaigns/NewDetails.vue";
import SubFirmsCampaigns from "../views/Campaigns/SubFirms.vue";
import SubFirmsCampaignsDetails from "../views/Campaigns/SubFirmsDetails.vue";
import BesAccounts from "../views/BesAccounts.vue";
import Search from "../views/Search.vue";
import { HTTP } from "@/main-source";
import store from "@/store";
import EventBus from "@/components/event-bus";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
    as: "Anasayfa",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    as: "",
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    as: "",
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: Forgot,
    as: "",
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    as: "Sıkça Sorulan Sorular",
  },
  {
    path: "/customCampaign001",
    name: "CustomCampaign001",
    component: CustomCampaign001,
    as: "Kampanya",
  },
  {
    path: "/customCampaign002",
    name: "CustomCampaign002",
    component: CustomCampaign002,
    as: "Kampanya",
  },
  {
    path: "/CustomCampaign003",
    name: "CustomCampaign003",
    component: CustomCampaign003,
    as: "Kampanya",
  },
  {
    path: "/CustomCampaign004",
    name: "CustomCampaign004",
    component: CustomCampaign004,
    as: "Kampanya",
  },
  {
    path: "/CustomCampaign005",
    name: "CustomCampaign005",
    component: CustomCampaign005,
    as: "Kampanya",
  },

  {
    path: "/customCampaignIzmir",
    name: "CustomCampaignIzmir",
    component: CustomCampaignIzmir,
    as: "Kampanya",
  },

  {
    path: "/customCampaignIstanbul",
    name: "CustomCampaignIstanbul",
    component: CustomCampaignIstanbul,
    as: "Kampanya",
  },
  {
    path: "/SafetyPrivacy",
    name: "SafetyPrivacy",
    component: SafetyPrivacy,
    as: "",
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    as: "iletişim",
  },
  {
    path: "/baymaklife",
    name: "whatis",
    component: WhatIs,
    meta: {
      requiresAuth: true,
    },
    as: "Baymaklife Nedir?",
  },
  {
    path: "/myaccount/:active?",
    name: "myAccount",
    component: Account,
    meta: {
      requiresAuth: true,
    },
    as: "Hesabım",
  },
  {
    path: "/myaccount/Info",
    name: "profile",
    component: Account,
    meta: {
      requiresAuth: true,
    },
    as: "Profilim",
  },
  {
    path: "/campaigns/",
    name: "activeCampaigns",
    component: ActiveCampaigns,
    meta: {
      requiresAuth: true,
    },
    as: "Aktif Kampanyalar",
  },
  {
    path: "/campaigns/completeds",
    name: "completedCampaigns",
    component: CompletedCampaigns,
    meta: {
      requiresAuth: true,
    },
    as: "Tamamlanan Kampanyalar",
  },
  {
    path: "/campaigns/joineds",
    name: "joinedCampaigns",
    component: JoinedCampaigns,
    meta: {
      requiresAuth: true,
    },
    as: "Kampanya Takibi",
  },
  {
    path: "/campaigns/passives",
    name: "passiveCampaigns",
    component: PassiveCampaigns,
    meta: {
      requiresAuth: true,
    },
    as: "Pasif Kampanyalar",
  },
  {
    path: "/campaigns/subfirms",
    name: "subFirmsCampaigns",
    component: SubFirmsCampaigns,
    meta: {
      requiresAuth: true,
    },
    as: "Alt Firma Kampanyaları",
  },
  {
    path: "/campaigns/subfirms/:id?",
    name: "subFirmsCampaignsDetails",
    component: SubFirmsCampaignsDetails,
    meta: {
      requiresAuth: true,
    },
    as: "Alt Firmalar Kampanya Takibi",
  },
  {
    path: "/campaigns/:id",
    name: "campaignDetails",
    component: newCampaignDetails,
    meta: {
      requiresAuth: true,
    },
    as: "",
  },
  {
    path: "/besaccounts",
    name: "besAccounts",
    component: BesAccounts,
    meta: {
      requiresAuth: true,
    },
    as: "Bes Hesaplarım",
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      requiresAuth: true,
    },
    as: "",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (
      to.path != "/campaigns" &&
      to.path != "/campaigns/completeds" &&
      to.path != "/campaigns/subfirms" &&
      to.path != "/campaigns/joineds" &&
      to.path != "/campaigns/passives"
    ) {
      return { x: 0, y: 0 };
    }
  },
});

/* eslint-disable */
router.beforeEach((to, from, next) => {
  let token = sessionStorage.token;

  if (!token && to.meta.requiresAuth) {
    sessionStorage.removeItem("notifyseen");
    next({
      path: "/login",
    });
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth) || token) {
    HTTP.get("/User/CheckToken")
      .then((result) => {
        if (result.status == 200) {
          EventBus.$emit("logged", true);
          store.state.isLoggedIn = true;
          sessionStorage.isPointUpload = result.data.isPointUpload;
          sessionStorage.isPasswordChance = result.data.isPasswordChance;
          if (to.path == "/login") {
            next({
              name: "Home",
            });
          }
          if (result.data.isPasswordChance == false) {
            next({
              path: "/myaccount",
              query: {
                changePassword: true,
              },
            });
          }
          if (result.data.isReActive == true) {
            next({
              path: "/myaccount",
              query: {
                isReActive: true,
              },
            });
          }
          next();
        }
      })
      .catch((e) => {
        if (e.response.status == 400) {
          store.state.isLoggedIn = false;
          EventBus.$emit("logged", false);
          sessionStorage.removeItem("notifyseen");
          next({
            path: "/login",
          });
        }
      });
  }
  EventBus.$emit("logged", false);
  next();
});
/* eslint-disable */

export default router;
