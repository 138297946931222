<template>
  <div class="newBesAccount">
    <span class="h3">BES HESABI EKLEME FORMU</span>

    <div class="col-md-12 pd0">
      <div class="form-group">
        <the-mask
          mask="###########"
          v-model="newBesAccount.identityNumber"
          class="brand-input"
          type="phone"
          placeholder="TC KİMLİK NO"
        ></the-mask>
        <span
          class="error"
          :class="{ show: notValid.includes('identityNumber') }"
          >Lütfen TC Kimlik Numarası giriniz.</span
        >
      </div>
    </div>
    <div v-show="step == 'second'">
      <div class="col-md-12 pd0">
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="newBesAccount.code"
            placeholder="POLİÇE NO"
            maxlength="24"
            disabled
          />
          <span class="error" :class="{ show: notValid.includes('code') }"
            >Lütfen poliçe numarası giriniz.</span
          >
        </div>
      </div>

      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="newBesAccount.name"
            placeholder="AD"
          />
          <span class="error" :class="{ show: notValid.includes('name') }"
            >Lütfen adınızı giriniz.</span
          >
        </div>
      </div>

      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="newBesAccount.surname"
            placeholder="SOYAD"
          />
          <span class="error" :class="{ show: notValid.includes('surname') }"
            >Lütfen soyadınızı giriniz.</span
          >
        </div>
      </div>

      <div class="col-md-12 pd0">
        <div class="form-group">
          <the-mask
            mask="0 (5##) ### ## ##"
            v-model="newBesAccount.gsm"
            class="brand-input"
            type="phone"
            placeholder="CEP TELEFONU"
          ></the-mask>
          <span class="error" :class="{ show: notValid.includes('gsm') }"
            >Lütfen geçerli bir cep telefonu numarası giriniz.</span
          >
        </div>
      </div>

      <div class="col-md-12 pd0">
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="newBesAccount.email"
            placeholder="E-POSTA"
          />
          <span class="error" :class="{ show: notValid.includes('email') }"
            >Lütfen geçerli bir e-posta adresi giriniz.</span
          >
        </div>
      </div>

      <div class="col-md-12 pd0">
        <div class="form-group">
          <textarea
            type="text"
            class="brand-input address"
            v-model="newBesAccount.address"
            placeholder="AÇIK ADRES"
          ></textarea>
          <span class="error" :class="{ show: notValid.includes('address') }"
            >Lütfen adres giriniz.</span
          >
        </div>
      </div>

      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group brand-select">
          <v-select
            :options="provinces"
            v-model="city"
            class="brand-select"
            placeholder="İL"
            :clearable="false"
          >
            <template v-slot:no-options>Şehrinizi bulamadık..</template>
          </v-select>
          <span class="error" :class="{ show: notValid.includes('city') }"
            >Lütfen şehir seçiniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-l-5">
        <div class="form-group brand-select">
          <v-select
            :options="districts"
            v-model="district"
            class="brand-select"
            placeholder="İLÇE"
            :clearable="false"
          >
            <template slot="option" slot-scope="option">{{
              option.label
            }}</template>
            <template v-slot:no-options>İlçenizi bulamadık..</template>
          </v-select>
          <span class="error" :class="{ show: notValid.includes('district') }"
            >Lütfen ilçe seçiniz.</span
          >
        </div>
      </div>
    </div>
    <button class="brand-button" @click="getInfo" v-if="step == 'first'">
      İLERLE
      <Loading v-if="isProcessing"></Loading>
    </button>
    <button class="brand-button" @click="createAccount" v-if="step == 'second'">
      KAYDET
      <Loading v-if="isProcessing"></Loading>
    </button>
    <!-- Modal -->
    <span
      class="hide"
      id="trigger"
      data-toggle="modal"
      data-target="#toggleModal"
    ></span>
    <div class="modal fade" id="toggleModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <div v-html="resultMessage"></div>
        </div>
      </div>
    </div>
    <!-- Modal End -->
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import { TheMask } from "vue-the-mask";

export default {
  name: "createBesAccount",
  data() {
    return {
      provinces: [],
      districts: [],
      city: "",
      district: "",
      newBesAccount: {
        code: "",
        identityNumber: "",
        name: "",
        surname: "",
        gsm: "",
        email: "",
        cityId: "",
        districtId: "",
        address: ""
      },
      notValid: [],
      resultMessage: "",
      step: "first",
      isProcessing: false
    };
  },
  components: {
    TheMask
  },
  created() {
    HTTP.post("City/GetList").then(result => {
      result.data.forEach(city => {
        this.provinces.push({ id: city.id, label: city.title });
      });
    });
  },
  methods: {
    validEmail: function(email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
      /* eslint-disable */
    },
    checkForm: function() {
      if (!this.newBesAccount.code) {
        this.notValid.push("code");
      } else {
        this.notValid = this.notValid.filter(item => item != "code");
      }
      if (this.newBesAccount.name.length == 0) {
        this.notValid.push("name");
      } else {
        this.notValid = this.notValid.filter(item => item != "name");
      }

      if (this.newBesAccount.surname.length == 0) {
        this.notValid.push("surname");
      } else {
        this.notValid = this.notValid.filter(item => item != "surname");
      }

      if (this.newBesAccount.gsm.length < 9) {
        this.notValid.push("gsm");
      } else {
        this.notValid = this.notValid.filter(item => item != "gsm");
      }

      if (!this.validEmail(this.newBesAccount.email)) {
        this.notValid.push("email");
      } else {
        this.notValid = this.notValid.filter(item => item != "email");
      }

      if (this.newBesAccount.address.length < 5) {
        this.notValid.push("address");
      } else {
        this.notValid = this.notValid.filter(item => item != "address");
      }
      if (this.newBesAccount.cityId.length == 0) {
        this.notValid.push("city");
      } else {
        this.notValid = this.notValid.filter(item => item != "city");
      }
      if (this.newBesAccount.districtId.length == 0) {
        this.notValid.push("district");
      } else {
        this.notValid = this.notValid.filter(item => item != "district");
      }

      if (this.newBesAccount.identityNumber.length != 11) {
        this.notValid.push("identityNumber");
      } else {
        this.notValid = this.notValid.filter(item => item != "identityNumber");
      }
    },
    getInfo: function() {
      this.isProcessing = true;
      if (this.newBesAccount.identityNumber.length == 11) {
        this.notValid = this.notValid.filter(item => item != "identityNumber");
        let data = {
          identityNumber: this.newBesAccount.identityNumber
        };
        HTTP.post("PrivatePensionSystem/GetByIdentityNumber", data).then(
          res => {
            console.log(res.data);
            if (res.data.success == false) {
              this.resultMessage = res.data.errorMessage;
              document.getElementById("trigger").click();
            } else {
              this.step = "second";
              this.newBesAccount = {
                id: res.data.data.id,
                code: res.data.data.code,
                identityNumber: res.data.data.identityNumber,
                name: res.data.data.name,
                surname: res.data.data.surname,
                gsm: res.data.data.gsm,
                email: res.data.data.email,
                cityId: res.data.data.cityId,
                districtId: res.data.data.districtId,
                address: res.data.data.address
              };
            }
            this.isProcessing = false;
          }
        );
      } else {
        this.notValid.push("identityNumber");
        this.isProcessing = false;
      }
    },
    createAccount: function() {
      this.isProcessing = true;
      this.checkForm();

      if (this.notValid.length == 0) {
        this.newBesAccount.gsm = "5" + this.newBesAccount.gsm;
        HTTP.post("PrivatePensionSystem/Add", this.newBesAccount)
          .then(result => {
            if (result.data.success == true) {
              this.resultMessage =
                "Hesap ekleme işleminiz başarılı! <br> Eklediğiniz hesap Baymak tarafından onaylandığında SMS ve e-posta ile bilgilendirileceksiniz. ! <br> Yönlendiriliyorsunuz...";
              this.newBesAccount = {
                code: "",
                identityNumber: "",
                name: "",
                surname: "",
                gsm: "",
                email: "",
                cityId: "",
                districtId: "",
                address: ""
              };
              setTimeout(() => {
                this.$emit("success", true);
                document.getElementById("trigger").click();
              }, 3000);
            } else {
              this.resultMessage = result.data.data;
            }
            document.getElementById("trigger").click();
            this.isProcessing = false;
          })
          .catch(e => {
            let data = e.response.data.errors;
            for (var propName in data) {
              if (data.hasOwnProperty(propName)) {
                var propValue = data[propName];
                this.resultMessage += propValue + "<br>";
              }
            }

            document.getElementById("trigger").click();
            this.isProcessing = false;
          });
      } else {
        this.isProcessing = false;
      }
    }
  },
  watch: {
    city: function() {
      this.newBesAccount.cityId = this.city.id;
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.city.id
      }).then(result => {
        this.district = "";
        this.districts = [];
        result.data.forEach(item => {
          this.districts.push({ id: item.id, label: item.title });
        });
      });
      if (this.city.length != 0) {
        this.notValid = this.notValid.filter(item => item != "province");
      }
    },
    district: function() {
      this.newBesAccount.districtId = this.district.id;
    }
  }
};
</script>
<style lang="scss">
.newBesAccount {
  .brand-input {
    padding: 10px 12px;
  }
  .address {
    resize: none;
    min-height: 140px;
    &:focus {
      outline: none;
    }
  }
}
</style>