<template>
  <div id="bes-table" class="container leads-table">
    <div class="col-md-2 pd0 left col-xs-12">
      <span class="h1 head">
        BES
        <br />LİDERLİK <br />TABLOSU
        <br />
      </span>
      <img
        src="@/assets/images/maskot/tada1.png"
        alt="Baymak"
        class="img-responsive mascot"
      />
    </div>
    <div class="col-md-10 col-xs-12">
      <div class="table">
        <div class="head">
          <span>Tüm İller</span>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="firm-info">
              <img src="@/assets/images/person.png" alt />
              <span class="firmName">{{ firmName }}</span>
              <p>
                Toplam Kazanılan
                <span class="total-point">{{ activeUserTotalEarn }} TL</span>
              </p>
              <p>
                <i>Türkiye Geneli Sıralamanız</i>
                <span class="ranking">{{ activeUserOrder }}</span>
              </p>
            </div>
          </div>
          <div class="col-md-8 list">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-2 col-xs-2">Sıra</div>
                <div class="col-md-5 col-xs-5">Kazanım</div>
                <div class="col-md-5 pd0 col-xs-5">Firma Adı</div>
              </div>
            </div>

            <div
              class="col-md-12"
              v-for="(item, index) in data"
              :key="index"
              :class="{ current: item.userId == currentUserId }"
            >
              <div class="col-md-12 t">
                <div class="col-md-2 col-xs-2">
                  <span>{{ item.rowNumber }}</span>
                </div>
                <div class="col-md-5 col-xs-5">{{ item.amount }} TL</div>
                <div class="col-md-5 col-xs-5">
                  {{ item.userId == currentUserId ? item.firmName : "***" }}
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";

export default {
  name: "Bes-Leads-Table",
  data() {
    return {
      data: [],
      userInfo: [],
      activeUserOrder: "",
      activeUserTotalEarn: "0 "
    };
  },
  created() {
    HTTP.post("User/GetLeaders", {
      transactionType: 5 // BES Liderleri
    }).then(result => {
      this.data = result.data;
      this.setActiveUserData();
    });
  },
  methods: {
    setActiveUserData: function() {
      if (this.data) {
        let user = this.data.filter(
          user => user.userId == sessionStorage.userId
        )[0];
        this.activeUserOrder = user.rowNumber ? user.rowNumber : "0";
        this.activeUserTotalEarn = user.amount ? user.amount : "0";
      }
    }
  },
  computed: {
    firmName: function() {
      return sessionStorage.firmName;
    },
    currentUserId: function() {
      return sessionStorage.userId;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/colors";

.leads-table {
  .left {
    .head {
      font-weight: 800;
      color: $brand-color-2;
    }
    .mascot {
      margin-top: 30px;
    }
  }
}
.table {
  background: $bg-soft-color;
  min-height: 100px;
  border-top: 30px solid $brand-color-2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 15px 30px 15px;
  .head {
    margin-top: -30px;
    span {
      background: $grey;
      padding: 7px 50px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      font-weight: bold;
      font-size: 17px;
    }
  }
  .firm-info {
    background: darken($grey, 5%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    margin-top: 80px;
    padding: 15px;
    color: $dark;
    img {
      width: 22px;
      height: auto;
      margin-top: -10px;
      margin-right: 5px;
    }
    .firmName {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 20px;
    }
    p {
      font-weight: 500;
      margin-top: 20px;
    }
    .total-point {
      display: block;
      font-size: 41px;
      font-weight: 800;
      margin-top: -10px;
    }
    .ranking {
      color: $white;
      background: $brand-color-2;
      display: block;
      border-radius: 5px;
      padding: 5px auto;
      text-align: center;
      font-weight: bold;
      font-size: 25px;
      margin-top: 5px;
    }
  }
  .list {
    margin-top: 20px;
    .col-md-12 {
      background: transparent;
      border-radius: 3px;
    }
  }
  .t {
    border-bottom: 1px solid darken($bg-soft-color, 10%);
    padding: 30px auto;
    div {
      padding: 5px 0px;
      line-height: 25px;
    }
    .col-md-2 {
      span {
        background: $brand-color-2;
        width: 25px;
        height: 25px;
        text-align: center;
        display: inline-block;
        margin-left: -15px;
        color: $white;
        border-radius: 3px;
      }
    }
  }
  .current {
    background: $brand-color-2 !important;
    color: $white;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    * {
      border: none !important;
    }
    .col-md-2 {
      span {
        background: $brand-color !important;
      }
    }
  }
}
@media (max-width: 767.98px) {
  .leads {
    padding: 0;
    .firm-info {
      margin-top: 20px;
    }
  }
}
</style>
