<template>
  <div id="app">
    <router-link to="/myaccount/segmentGifts">
      <notifications position="top left" />
    </router-link>
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Footer from "@/components/Footer";
import { mapActions } from "vuex";
import EventBus from "@/components/event-bus";

export default {
  created() {},
  components: {
    Header,
    Footer,
  },
  methods: {
    ...mapActions(["setLoginStatus"]),
  },
  mounted: function() {
    /* eslint-disable */
    EventBus.$on("logged", function(isLogged) {
      if (isLogged) {
        window.addEventListener("load", function() {
          Tawk_API.showWidget();
        });
      } else {
        
        window.addEventListener("load", function() {
          Tawk_API.hideWidget();
        });
      }
    });
    /* eslint-disable */
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
