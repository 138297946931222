<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <h1 class="h1">KAMPANYA TAKİBİ</h1>
      <div
        class="col-md-10 col-md-offset-1 col-xs-12 content"
        v-if="userRoleId != 11"
      >
        <div class="col-md-6 first-detail">
          <span class="h3">{{ data.title }}</span>
          <div v-html="data.shortDescription"></div>
        </div>
        <div class="col-md-6">
          <img v-if="data.image" :src="data.image" class="img-responsive" alt />
        </div>

        <statusBar :data="data" v-if="data.campaignLimitsView"></statusBar>

        <div class="col-md-12 details">
          <span class="h4">Kampanya Açıklaması</span>
          <div v-html="data.description"></div>
        </div>
      </div>
      <div
        class="col-md-10 col-md-offset-1 col-xs-12 content"
        v-if="userRoleId == 11"
      >
        <div class="col-md-6 first-detail">
          <span class="h3">{{ data.title }}</span>
          <div v-html="data.description"></div>
          <p class="download" v-if="data.document && data.status == 1">
            <b>Kampanya Katılım Taahhütnamesini</b>
            <a
              :href="data.document"
              target="_blank"
              class="brand-button btn-fix-width"
              download
              >İNDİR</a
            >
          </p>
        </div>
        <div class="col-md-6">
          <img v-if="data.image" :src="data.image" class="img-responsive" alt />
        </div>

        <div class="col-md-12 details" v-html="data.detail"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import statusBar from "@/components/campaigns/Status-Bar";

export default {
  name: "Details",
  data() {
    return {
      data: [],
    };
  },
  components: {
    statusBar,
  },
  created() {
    let id = this.$route.params.id;
    HTTP.post("/Banner/Campaign", {}).then((result) => {
      this.data = result.data.filter((d) => d.id == id)[0];
    });
  },
  methods: {},
  computed: {
    userRoleId: function() {
      return sessionStorage.userRoleId;
    },
  },
};
</script>
