import axios from "axios";

export const HTTP = axios.create({
  baseURL: "https://baymak.tutkal.com.tr/Api/"
});

let token = sessionStorage.token;
if (token) {
  HTTP.defaults.headers = {
    token: token
  };
}
