<template>
  <div>
    <span class="info">ŞİFRE DEĞİŞİKLİĞİ İÇİN CEP TELEFONUNUZU GİRİNİZ</span>
    <div class="form-group" v-on:keyup.enter="sendCode">
      <the-mask
        mask="(5##) ### ## ##"
        v-model="gsm"
        class="brand-input"
        type="phone"
        placeholder="CEP TELEFONU"
      ></the-mask>
      <span class="error" :class="{ show: notValid.includes('gsm') }"
        >Lütfen geçerli bir cep telefonunu giriniz.</span
      >
      <span class="error" :class="{ show: error.length > 0 }">{{ error }}</span>
    </div>
    <vue-recaptcha
      sitekey="6Lf_nTgaAAAAAOvJFMkNzYDBPYp-cTEZ39pD1QXt"
      :loadRecaptchaScript="true"
      @verify="captchaStatus"
      @expired="captchaStatus"
    >
    </vue-recaptcha>
    <button type="button" class="brand-button" @click="sendCode">
      DEĞİŞTİR
    </button>
  </div>
</template>
<script>
import { TheMask } from "vue-the-mask";
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "sendCode",
  data() {
    return {
      gsm: "",
      notValid: [],
      error: "",
      captchaCode: null
    };
  },
  components: {
    TheMask,
    VueRecaptcha
  },
  methods: {
    sendCode: function() {
      if (this.gsm.length <= 8) {
        this.notValid.push("gsm");
      } else {
        this.notValid = this.notValid.filter(item => item != "gsm");
      }
      if (
        this.notValid.length == 0 &&
        this.captchaCode != null &&
        this.captchaCode != undefined
      ) {
        HTTP.post("Login/SendSMSCode", {
          gsm: "5" + this.gsm,
          captchaCode: this.captchaCode
        }).then(result => {
          if (result.data.success == true) {
            this.notValid = [];
            this.error = "";
            EventBus.$emit("sendCodeSuccess", "5" + this.gsm);
            this.$emit("sendCodeSuccess", true);
          }
          this.error = result.data.errorMessage;
        });
      }
    },
    captchaStatus: function(val) {
      this.captchaCode = val;
    }
  }
};
</script>
