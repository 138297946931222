<template>
  <div class="container leads-table point-table">
    <div class="col-md-2 pd0 left">
      <span class="h1 head">
        PUAN
        <br />LİDERLİK <br />TABLOSU
        <br />
      </span>
      <img
        src="@/assets/images/maskot/tada2.png"
        alt="Baymak"
        class="img-responsive mascot"
      />
    </div>
    <div class="col-md-10 col-xs-12">
      <div class="table">
        <div class="head">
          <span>Tüm İller</span>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="firm-info">
              <img src="@/assets/images/person.png" alt />
              <span class="firmName">{{ firmName }}</span>
              <p>
                Toplam Kazanılan
                <span class="total-point">{{ userInfo.totalPoint }} Puan</span>
              </p>
              <p>
                <i>Türkiye Geneli Sıralamanız</i>
                <span class="ranking">{{ activeUserOrder }}</span>
              </p>
            </div>
          </div>
          <div class="col-md-8 list col-xs-12">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-2 col-xs-2">Sıra</div>
                <div class="col-md-5 col-xs-5">Kazanım</div>
                <div class="col-md-5 col-xs-5 pd0">Firma Adı</div>
              </div>
            </div>

            <div
              class="col-md-12"
              v-for="(item, index) in data"
              :key="index"
              :class="{ current: item.userId == currentUserId }"
            >
              <div class="col-md-12 t">
                <div class="col-md-2 col-xs-2">
                  <span>{{ item.rowNumber }}</span>
                </div>
                <div class="col-md-5 col-xs-5">{{ item.amount }} puan</div>
                <div class="col-md-5 col-xs-5">
                  {{ item.userId == currentUserId ? item.firmName : "***" }}
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
export default {
  name: "Point-Leads-Table",
  data() {
    return {
      data: [],
      userInfo: [],
      activeUserOrder: ""
    };
  },
  created() {
    HTTP.post("User/GetLeaders", {
      transactionType: 1 // Puan Liderleri
    }).then(result => {
      this.data = result.data;
      this.setActiveUserOrder();
    });
    HTTP.get("Transaction/GetUserPoint", {}).then(result => {
      this.userInfo = result.data.data;
    });
  },
  methods: {
    setActiveUserOrder: function() {
      if (this.data) {
        let rowNumber =
          this.data.filter(user => user.userId == sessionStorage.userId)[0]
            .rowNumber || 0;
        this.activeUserOrder = rowNumber;
      }
    }
  },
  computed: {
    firmName: function() {
      return sessionStorage.firmName;
    },
    currentUserId: function() {
      return sessionStorage.userId;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/colors";
.point-table {
  .left {
    .head {
      color: $brand-color-3;
    }
  }

  .table {
    border-top: 30px solid $brand-color-3;
    .ranking {
      color: $white;
      background: $brand-color-3;
    }
  }

  .t {
    border-bottom: 1px solid darken($bg-soft-color, 10%);

    .col-md-2 {
      span {
        background: $brand-color-3;

        color: $white;
      }
    }
  }
  .current {
    background: $brand-color-3 !important;
    color: $white;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    * {
      border: none !important;
    }
    .col-md-2 {
      span {
        background: $brand-color !important;
      }
    }
  }
}
@media (max-width: 767.98px) {
  .leads {
    padding: 0;
    .firm-info {
      margin-top: 20px;
    }
  }
}
</style>
