<template>
  <div>
    <div class="col-md-12 campaign-status">
      <span class="h4">Katılım Durumu</span>
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          aria-valuenow="70"
          aria-valuemax="100"
          :style="'width:' + currentPoint + '%'"
        >
          <span class="circle"></span>
          <span
            class="desc"
            :class="{ hidden: data.uploadedSerialNumberCount != endPoint }"
            >TEBRİKLER HEDEFE ULAŞTINIZ</span
          >
        </div>
      </div>
      <div class="info">
        <div class="col-md-4 pd0 col-xs-4">0 Seri No</div>
        <div class="col-md-4 text-center pd0 col-xs-4">
          {{ userPoint }} seri no yüklediniz
        </div>
        <div class="col-md-4 text-right pd0 col-xs-4">
          {{ endPoint }} Seri No
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center col-xs-12" v-if="leftDays => 0">
      Kampanya Bitimine Kalan Süre {{ leftDays }} gün
    </div>
    <div class="clearfix"></div>
    <div class="wrap" v-if="data.campaignLimitsView">
      <div class="inner">
        <ul>
          <li v-for="item in data.campaignLimitsView" :key="item.id">
            <strong>{{ item.title }}</strong>
            <p>{{ item.description }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "statusBar2",
  props: ["data"],
  computed: {
    userPoint: function() {
      return this.data.uploadedSerialNumberCount
        ? this.data.uploadedSerialNumberCount
        : 0;
    },
    startDate: function() {
      let start = new Date(this.data.startDate);
      let dateString =
        ("0" + start.getDate()).slice(-2) +
        "-" +
        ("0" + start.getMonth() + 1).slice(-2) +
        "-" +
        start.getFullYear();
      return dateString;
    },
    endDate: function() {
      let end = new Date(this.data.endDate);
      let dateString =
        ("0" + end.getDate()).slice(-2) +
        "-" +
        ("0" + (end.getMonth() + 1)).slice(-2) +
        "-" +
        end.getFullYear();
      return dateString;
    },
    leftDays: function() {
      let current = new Date();
      let end = new Date(this.data.endDate);
      let difference = end.getTime() - current.getTime();
      let days = difference / (1000 * 3600 * 24);
      return days.toFixed(0);
    },
    endPoint: function() {
      let biggest = this.data.campaignLimitsView[0].total;
      if (this.data.campaignLimitsView.length > 1) {
        this.data.campaignLimitsView.forEach(campaign => {
          if (campaign.total > biggest) {
            biggest = campaign.total;
          }
        });
      }

      return biggest;
    },
    currentPoint: function() {
      let percent = (100 * this.userPoint) / this.endPoint;
      if (percent < 10) {
        percent = 10;
      }
      return percent;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors";
@import "@/assets/scss/mixins";
.wrap {
  @include brand-bg;
  border-radius: 5px;
  padding: 5px 1px 1px;
  margin-top: 20px;
}
.inner {
  background: $white;
  border-radius: 5px;
  padding: 20px 10px 10px 5px;
}
.h3 {
  font-weight: 800;
  font-size: 28px;
}
.head {
  padding-top: 25px;
  text-align: left;
}
.brand-button {
  margin-top: 25px;
  margin-left: 20px;
  display: inline-block;
  padding: 7px 40px;
  font-size: 22px;
}
small {
  font-size: 11px;
}
</style>
