<template>
  <div class="service-request-form">
    <span class="h3" v-if="step == 'first' || step == 'second'"
      >SERVİS TALEP FORMU</span
    >
    <div class="clearfix"></div>
    <div v-if="step == 'first'">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="choice1" class="btn-radio">
              <input type="radio" value="yes" v-model="choice" id="choice1" />
              AÇMAK İSTİYORUM
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form-group">
            <label for="choice2" class="btn-radio">
              <input type="radio" value="no" v-model="choice" id="choice2" />
              AÇMAK İSTEMİYORUM
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="col-md-8 col-md-offset-2">
          <button
            type="button"
            class="brand-button"
            :disabled="choice == ''"
            @click="next()"
          >
            İLERLE
            <Loading v-if="isProcessing"></Loading>
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="step == 'second'">
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input"
            maxlength="25"
            v-model="serviceForm.serviceRequestName"
            placeholder="Ad"
            :disabled="!editable"
          />

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestName') }"
            >Lütfen adınızı giriniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-l-5">
        <div class="form-group">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input"
            v-model="serviceForm.serviceRequestSurname"
            maxlength="25"
            placeholder="Soyad"
            :disabled="!editable"
          />

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestSurname') }"
            >Lütfen soyadınızı giriniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group brand-select">
          <the-mask
            mask="0 (###) ### ## ##"
            v-model="serviceForm.serviceRequestPhone"
            class="brand-input"
            type="phone"
            placeholder="Telefon"
            :disabled="!editable"
          ></the-mask>

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestPhone') }"
            >Lütfen geçerli bir cep telefonu numarası giriniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group brand-select">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input email"
            v-model="serviceForm.serviceRequestEmail"
            placeholder="E-Posta"
            :disabled="!editable"
          />

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestEmail') }"
            >Lütfen e-posta adresinizi giriniz</span
          >
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group brand-select">
          <v-select
            autocomplete="nope"
            :options="serviceRequestCitys"
            v-model="serviceRequestCity"
            class="brand-select"
            placeholder="İl"
            :clearable="false"
            :disabled="!editable"
          >
            <template v-slot:no-options>Şehrinizi bulamadık..</template>
          </v-select>

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestCity') }"
            >Lütfen il seçiniz.</span
          >
        </div>
      </div>

      <div class="col-md-6 pd0 pd-l-5">
        <div class="form-group brand-select">
          <v-select
            autocomplete="nope"
            :options="serviceRequestDistricts"
            v-model="serviceRequestDistrict"
            class="brand-select"
            placeholder="İlçe"
            :clearable="false"
            :disabled="!editable"
          >
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>
            <template v-slot:no-options>İlçenizi bulamadık..</template>
          </v-select>

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestDistrict') }"
            >Lütfen ilçe seçiniz.</span
          >
        </div>
      </div>
      <div class="col-md-12 pd0">
        <div class="form-group brand-select">
          <v-select
            autocomplete="nope"
            :options="serviceRequestNeighborhoods"
            v-model="serviceRequestNeighborhood"
            class="brand-select"
            placeholder="Mahalle"
            :clearable="false"
            :disabled="!editable"
          >
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>
            <template v-slot:no-options>Mahallenizi bulamadık..</template>
          </v-select>

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestNeighborhood') }"
            >Lütfen mahalle seçiniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input"
            maxlength="25"
            placeholder="Cadde"
            v-model="serviceForm.serviceRequestMainStreet"
            :disabled="!editable"
          />

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestMainStreet') }"
            >Lütfen cadde bilgisi giriniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input"
            maxlength="25"
            placeholder="Sokak"
            v-model="serviceForm.serviceRequestStreet"
            :disabled="!editable"
          />

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestStreet') }"
            >Lütfen sokak bilgisi giriniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input"
            maxlength="25"
            placeholder="Apartman No"
            v-model="serviceForm.serviceRequestApartment"
            :disabled="!editable"
          />

          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestApartment') }"
            >Lütfen apartman bilgisi giriniz.</span
          >
        </div>
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group">
          <input
            autocomplete="nope"
            type="text"
            class="brand-input"
            maxlength="25"
            placeholder="Daire"
            v-model="serviceForm.serviceRequestLivingRoom"
            :disabled="!editable"
          />
          <span
            class="error"
            :class="{ show: notValid.includes('serviceRequestLivingRoom') }"
            >Lütfen daire numarası giriniz.</span
          >
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="col-md-12" v-if="error != null">
        <span class="error show">{{ error }}</span>
      </div>
      <div class="col-md-8 col-md-offset-2">
        <button
          type="button"
          class="brand-button"
          @click="createServiceRequest()"
          v-if="editable"
        >
          İLERLE
          <Loading v-if="isProcessing"></Loading>
        </button>
      </div>
    </div>
    <div v-else-if="step == 'third'">
      <button
        type="button"
        class="brand-button"
        @click="sendVerificationCode()"
      >
        DOĞRULAMA KODU GÖNDER
        <Loading v-if="isProcessing"></Loading>
      </button>
    </div>
    <div v-else-if="step == 'fourth'">
      <div class="row" v-if="!verificationError && !verificationSuccess">
        <div class="col-md-10 col-md-offset-1">
          <div class="form-group">
            <input
              autocomplete="nope"
              type="text"
              class="brand-input"
              maxlength="25"
              v-model="verificationCode"
              placeholder="Doğrulama Kodu"
            />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-md-6 col-md-offset-3">
          <button
            type="button"
            class="brand-button"
            :disabled="verificationCode.trim().length === 0"
            @click="verifyCode()"
          >
            DOĞRULA
            <Loading v-if="isProcessing"></Loading>
          </button>
        </div>
      </div>
      <div class="row" v-else-if="verificationError">
        <div class="col-md-12">
          <div class="spacing">
            Doğrulama kodu hatalıdır. Lütfen tekrar deneyiniz.
          </div>
        </div>
        <div class="col-md-6 col-md-offset-3">
          <button type="button" class="brand-button" @click="tryAgain()">
            TEKRAR DENE
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="step == 'fifth'">
      <div class="row">
        <div class="col-md-12">
          <div class="spacing">
            <span v-if="verificationSuccess"
              >Servis talep formunuz başarılı şekilde alınmıştır.</span
            >
            <span v-else
              >İşleminiz başarıyla gerçekleşmiştir.
              <span v-if="successMessage">{{ successMessage }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="error !== null">
      {{ error }}
    </div>
    <span v-if="successMessage !== null">{{ successMessage }}</span>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import { TheMask } from "vue-the-mask";
import Loading from "@/components/loading";
export default {
  name: "serviceRequestForm",
  components: {
    TheMask,
    Loading,
  },
  props: ["data"],
  data() {
    return {
      step: "first",
      choice: "",
      error: null,
      verificationCodeId: "",
      isProcessing: false,
      editable: true,
      successMessage: null,
      serviceForm: {
        serviceRequestName: "",
        serviceRequestSurname: "",
        serviceRequestPhone: "",
        serviceRequestCity: "",
        serviceRequestDistrict: "",
        serviceRequestNeighborhood: "",
        serviceRequestMainStreet: "",
        serviceRequestStreet: "",
        serviceRequestApartment: "",
        serviceRequestLivingRoom: "",
        serviceRequestEmail: "",
      },
      districtId: "",
      citys: [],
      districts: [],
      notValid: [],
      serviceRequestCity: null,
      serviceRequestDistrict: null,
      serviceRequestNeighborhood: null,
      serviceRequestCitys: [],
      serviceRequestDistricts: [],
      serviceRequestNeighborhoods: [],
      verificationCode: "",
      verificationError: false,
      verificationSuccess: false,
    };
  },
  created() {
    this.refreshAndCreate();
  },
  mounted() {
    if (this.data.serviceRequestUpdate) {
      this.step = "second";
      this.choice = "yes";
      if (this.data.data && this.data.data.serviceRequestCityId == 0) {
        this.editable = true;
      } else {
        this.editable = false;
        this.serviceForm = Object.assign({}, this.data.data);
      }
    } else if (false === this.data.canCreateServiceForm) {
      this.step = "";
      this.sendRequestForm();
    }
  },
  watch: {
    serviceRequestCity: function() {
      let dataDistrictId = 0;
      if (
        this.data &&
        this.data.data &&
        this.data.data.serviceRequestDistrictId > 0
      ) {
        dataDistrictId = this.data.data.serviceRequestDistrictId;
      }
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.serviceRequestCity.cityId,
      }).then((result) => {
        this.serviceForm.serviceRequestDistrictId = null;
        this.serviceRequestDistrict = null;
        this.serviceRequestDistricts = [];
        this.serviceForm.serviceRequestNeighborhoodId = null;
        this.serviceRequestNeighborhood = null;
        this.serviceRequestNeighborhoods = [];
        result.data.forEach((item) => {
          this.serviceRequestDistricts.push({
            baymakDistrictId: item.baymakDistrictId,
            id: item.id,
            label: item.title,
          });
          if (dataDistrictId === item.baymakDistrictId) {
            this.serviceForm.serviceRequestDistrictId = dataDistrictId;
            this.serviceRequestDistrict = {
              baymakDistrictId: item.baymakDistrictId,
              id: item.id,
              label: item.title,
            };
          }
        });
      });
    },
    serviceRequestDistrict: function() {
      if (this.serviceRequestDistrict) {
        this.getServiceRequestDistrict();
      }
    },
  },
  methods: {
    next() {
      if (this.choice === "yes") {
        this.step = "second";
      } else {
        // send form
        this.sendRequestForm();
      }
    },
    getServiceRequestDistrict() {
      let dataNeighborhoodId = 0;
      if (
        this.data &&
        this.data.data &&
        this.data.data.serviceRequestNeighborhoodId > 0
      ) {
        dataNeighborhoodId = this.data.data.serviceRequestNeighborhoodId;
      }
      HTTP.post("Neighborhood/GetListByDistrictId", {
        districtId: this.serviceRequestDistrict.id,
      }).then((result) => {
        this.serviceForm.serviceRequestNeighborhoodId = null;
        this.serviceRequestNeighborhood = null;
        this.serviceRequestNeighborhoods = [];
        result.data.forEach((item) => {
          this.serviceRequestNeighborhoods.push({
            id: item.id,
            baymakId: item.baymakNeighborhoodId,
            label: item.title,
          });
          if (dataNeighborhoodId === item.baymakNeighborhoodId) {
            this.serviceForm.serviceRequestNeighborhoodId = dataNeighborhoodId;
            this.serviceRequestNeighborhood = {
              id: item.id,
              baymakId: item.baymakNeighborhoodId,
              label: item.title,
            };
          }
        });
      });
    },
    refreshAndCreate() {
      let dataCityId = 0;
      if (
        this.data &&
        this.data.data &&
        this.data.data.serviceRequestCityId > 0
      ) {
        dataCityId = this.data.data.serviceRequestCityId;
      }
      HTTP.post("User/GetUserAuthorizedCity", {}).then((result) => {
        result.data.forEach((city) => {
          const data = {
            id: city.id,
            baymakCityId: city.baymakCityId,
            cityId: city.cityId,
            label: city.cityTitle,
          };
          this.citys.push(data);
          this.serviceRequestCitys.push(data);
          if (dataCityId === city.baymakCityId) {
            this.serviceRequestCity = data;
          }
        });
      });
    },
    createServiceRequest: function() {
      if (this.serviceForm.serviceRequestName.length == 0) {
        if (this.notValid.indexOf("serviceRequestName") === -1) {
          this.notValid.push("serviceRequestName");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestName"
        );
      }
      if (this.serviceForm.serviceRequestSurname.length == 0) {
        if (this.notValid.indexOf("serviceRequestSurname") === -1) {
          this.notValid.push("serviceRequestSurname");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestSurname"
        );
      }

      if (this.serviceForm.serviceRequestPhone.length < 7) {
        if (this.notValid.indexOf("serviceRequestPhone") === -1) {
          this.notValid.push("serviceRequestPhone");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestPhone"
        );
      }

      if (!this.serviceRequestCity) {
        if (this.notValid.indexOf("serviceRequestCity") === -1) {
          this.notValid.push("serviceRequestCity");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestCity"
        );
      }
      if (!this.serviceRequestDistrict) {
        if (this.notValid.indexOf("serviceRequestDistrict") === -1) {
          this.notValid.push("serviceRequestDistrict");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestDistrict"
        );
      }
      if (!this.serviceRequestNeighborhood) {
        if (this.notValid.indexOf("serviceRequestNeighborhood") === -1) {
          this.notValid.push("serviceRequestNeighborhood");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestNeighborhood"
        );
      }

      if (this.serviceForm.serviceRequestMainStreet.length == 0) {
        if (this.notValid.indexOf("serviceRequestMainStreet") === -1) {
          this.notValid.push("serviceRequestMainStreet");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestMainStreet"
        );
      }

      if (this.serviceForm.serviceRequestStreet.length == 0) {
        if (this.notValid.indexOf("serviceRequestStreet") === -1) {
          this.notValid.push("serviceRequestStreet");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestStreet"
        );
      }

      if (this.serviceForm.serviceRequestApartment.length == 0) {
        if (this.notValid.indexOf("serviceRequestApartment") === -1) {
          this.notValid.push("serviceRequestApartment");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestApartment"
        );
      }

      if (this.serviceForm.serviceRequestLivingRoom.length == 0) {
        if (this.notValid.indexOf("serviceRequestLivingRoom") === -1) {
          this.notValid.push("serviceRequestLivingRoom");
        }
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestLivingRoom"
        );
      }

      if (this.notValid.length === 0) {
        this.step = "third";
      }
    },
    sendRequestForm() {
      this.error = null;
      this.isProcessing = true;
      let data;
      let endpoint = "Transaction/SetSerialCode";
      if (this.choice === "yes") {
        if (this.data.serviceRequestUpdate) {
          endpoint = "Transaction/ServiceRequestUpdate";
          data = {
            id: this.data.data.id,
            serviceRequestName: this.serviceForm.serviceRequestName,
            serviceRequestSurname: this.serviceForm.serviceRequestSurname,
            serviceRequestCityId: this.serviceRequestCity.baymakCityId,
            serviceRequestDistrictId: this.serviceRequestDistrict
              .baymakDistrictId,
            serviceRequestNeighborhoodId: this.serviceRequestNeighborhood
              .baymakId,
            serviceRequestMainStreet: this.serviceForm.serviceRequestMainStreet,
            serviceRequestStreet: this.serviceForm.serviceRequestStreet,
            serviceRequestApartment: this.serviceForm.serviceRequestApartment,
            serviceRequestLivingRoom: this.serviceForm.serviceRequestLivingRoom,
            serviceRequestPhone: this.serviceForm.serviceRequestPhone,
            serviceRequestEmail: this.serviceForm.serviceRequestEmail,
          };
        } else {
          data = {
            baymakCityId: this.data.baymakCityId,
            districtId: this.data.districtId,
            serialNumber: this.data.code,
            privatePensionSystemId: this.data.selectedBesAccount,
            isCombiInstallationControlForm: true,
            serviceRequestName: this.serviceForm.serviceRequestName,
            serviceRequestSurname: this.serviceForm.serviceRequestSurname,
            serviceRequestCityId: this.serviceRequestCity.baymakCityId,
            serviceRequestDistrictId: this.serviceRequestDistrict
              .baymakDistrictId,
            serviceRequestNeighborhoodId: this.serviceRequestNeighborhood
              .baymakId,
            serviceRequestAddress: this.serviceForm.serviceRequestAddress,
            serviceRequestMainStreet: this.serviceForm.serviceRequestMainStreet,
            serviceRequestStreet: this.serviceForm.serviceRequestStreet,
            serviceRequestApartment: this.serviceForm.serviceRequestApartment,
            serviceRequestLivingRoom: this.serviceForm.serviceRequestLivingRoom,
            serviceRequestAppointmentDate: "",
            serviceRequestPhone: this.serviceForm.serviceRequestPhone,
            serviceRequestEmail: this.serviceForm.serviceRequestEmail,
          };
        }
      } else {
        data = {
          baymakCityId: this.data.baymakCityId,
          districtId: this.data.districtId,
          serialNumber: this.data.code,
          privatePensionSystemId: this.data.selectedBesAccount,
          isCombiInstallationControlForm: true,
        };
      }

      HTTP.post(endpoint, data).then(
        (result) => {
          this.isProcessing = false;

          console.log("🚀 ~ sendRequestForm ~ result:", result);
          if (result.data.isScenarioComplete && result.data.success) {
            this.successMessage = result.data.data;
            return;
          }

          if (!result.data.success) {
            this.error = result.data.data;
            return;
          }

          if (!result.data.isScenarioComplete && result.data.success) {
            this.step = "fifth";
            this.error = null;
            return;
          }
          this.isProcessing = false;
        },
        (error) => {
          console.log(error.response.status);
          this.isProcessing = false;
        }
      );
    },
    sendVerificationCode() {
      this.error = null;
      this.isProcessing = true;
      const data = {
        firstName: this.serviceForm.serviceRequestName,
        lastName: this.serviceForm.serviceRequestSurname,
        msisdn: this.serviceForm.serviceRequestPhone,
        email: this.serviceForm.serviceRequestEmail,
      };
      HTTP.post("MobileDEV/SendPermissionRequest", data).then(
        (result) => {
          this.isProcessing = false;
          if (!result.data.success) {
            this.error = result.data.data;
            console.log(this.error);
          } else {
            this.verificationCodeId = result.data.data.dataId;
            this.error = null;
            this.step = "fourth";
          }
        },
        (error) => {
          console.log(error.response.status);
          this.isProcessing = false;
        }
      );
    },
    verifyCode() {
      this.error = null;
      this.isProcessing = true;
      const data = {
        permissionRequestCode: this.verificationCodeId,
        item: this.serviceForm.serviceRequestPhone,
        code: this.verificationCode,
      };
      HTTP.post("MobileDEV/Verify", data).then(
        (result) => {
          this.isProcessing = false;
          if (
            result.data.errorMessage !== undefined &&
            result.data.errorMessage !== ""
          ) {
            this.verificationError = true;
          } else {
            this.verificationError = false;
            this.verificationSuccess = true;
            this.sendRequestForm();
            this.step = "fifth";
          }
        },
        (error) => {
          this.isProcessing = false;
          this.verificationError = true;
          this.verificationSuccess = false;
          console.log(error.response.status);
        }
      );
    },
    tryAgain() {
      this.verificationCode = "";
      this.verificationError = false;
    },
    validEmail: function(email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
      /* eslint-disable */
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors";
.h3 {
  margin-bottom: 20px !important;
}
.code {
  .brand-input {
    height: 50px !important;
    font-weight: bold !important;
    text-align: center !important;
    background: $white !important;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.31) !important;
  }
}
.brand-button {
  margin-bottom: 10px;
  width: 100%;
}
.form-group {
  position: relative;
  .f-info {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ccc;
    cursor: pointer;
  }
  .s-info {
    top: 15px;
    right: 40px;
    font-size: 14px;
  }
  .popover {
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
  }
}
.text-left {
  text-align: left !important;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}

.service-request-form {
  .small {
    margin-top: -10px;
    display: block;
  }
  .small-2 {
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .bessmall {
    margin-top: -5px;
    font-size: 11px;
  }
}
.contractform {
  .btn-radio {
    font-size: 13px;
  }
}
.service-form {
  .h4 {
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
  }
}
.date {
  .brand-input {
    width: 100% !important;
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 28%;
  height: 35px;
  width: 35px;
  background: #f0f8ef;
  border-radius: 50%;
}
.spacing {
  padding-bottom: 0px;
}
</style>
