<template>
  <div id="slider" class="slider carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div
        class="item"
        v-for="(slide, index) in slides"
        :key="index"
        :class="{ active: index == 0 }"
      >
        <a
          :href="slide.url != 'photoCampaign' ? slide.url : '#'"
          @click="checkUrl(slide.url)"
        >
          <img
            :src="slide.image"
            alt="Slider"
            class="hidden-xs"
            @click="slide.isPopupImageOpen ? showModal(slide.popupImage) : ''"
          />
          <img
            :src="slide.mobileImage"
            alt="Slider"
            class="hidden-md hidden-lg hidden-sm"
            @click="slide.isPopupImageOpen ? showModal(slide.popupImage) : ''"
          />
        </a>
      </div>
    </div>
    <div class="container slide-nav">
      <a href="#slider" class="left" data-slide="prev">
        <img src="@/assets/images/slider/left.png" alt="Left" />
      </a>
      <a class="right" href="#slider" data-slide="next">
        <img src="@/assets/images/slider/right.png" alt="Left" />
      </a>
    </div>
    <transition name="modal" v-if="isModalActive">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="closeModal">
          <div @click.stop.prevent="closeModal">
            <div class="modal-body">
              <slot name="body">
                <div class="img-wrap">
                  <span class="m-close">
                    <i class="fas fa-times"></i>
                  </span>
                  <img :src="modalImg" alt />
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- <button id="show-modal" @click="isModalActive = true">Show Modal</button> -->
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
export default {
  name: "Slider",
  data: function() {
    return {
      slides: null,
      isModalActive: false,
      modalImg: ""
    };
  },
  created: function() {
    HTTP.post("Banner/GetList").then(result => {
      this.slides = result.data;
    });
  },
  methods: {
    checkUrl(url) {
      if (url == "photoCampaign") {
        EventBus.$emit("photoCampaign", true);
      }
    },
    showModal: function(url) {
      this.isModalActive = true;
      this.modalImg = url;
    },
    closeModal: function() {
      this.isModalActive = false;
    }
  }
};
</script>
