<template>
  <div>
    <span class="h3">BES HESABI SEÇİNİZ</span>
    <div class="list">
      <div class="row subhead">
        <div class="col-md-3 col-md-offset-1">TCKN</div>
        <div class="col-md-4">İsim Soyisim</div>
        <div class="col-md-3">Hesap Numarası</div>
      </div>
      <div class="row" v-for="(item, index) in besAccountList" :key="index">
        <label :for="'option' + index" class="btn-radio pd-l-0">
          <div class="col-md-1 text-center">
            <input
              type="radio"
              v-model="selectedAccountId"
              :value="item.id"
              name="a"
              :id="'option' + index"
            />
            <span class="checkmark"></span>
          </div>
          <div class="col-md-3">{{ item.identityNumber }}</div>
          <div class="col-md-4">{{ item.name }} {{ item.surname }}</div>
          <div class="col-md-3">{{ item.code }}</div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "besAccountList",
  props: ["besAccountList"],
  data() {
    return {
      selectedAccountId: 0
    };
  },
  watch: {
    selectedAccountId: function() {
      this.$emit("selectAccount", this.selectedAccountId);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors";
.list {
  background: $grey;
  border-radius: 20px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.31);
  min-height: 40px;
  margin: 20px auto;
  padding: 15px;
  text-align: left;
  font-size: 14px;
}
label {
  display: block !important;
}
.checkmark {
  background: $white !important;
  transform: scale(0.6) !important;
  left: calc(50% - 6px) !important;
}

.subhead {
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  color: $dark2;
}
.pd-l-0 {
  padding-left: 0 !important;
}
</style>
