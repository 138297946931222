<template>
  <div>
    <div class="col-12 pad" v-if="!user.isReActive">
      Üyelik bilgilerinizi değiştirmek için Baymaklife İletişim alanından
      bizlere form iletebilir veya Baymaklife Danışma Hattı'nı arayabilirsiniz.
    </div>
    <div class="col-12 pad" v-if="user.isReActive">
      <div class="alert alert-warning">
        <b
          >Lütfen üyelik bilgilerinizi güncelleyiniz. Üyelik bilgileriniz güncel
          ise "Bilgilerimin doğruluğunu kabul ederim." onayını vererek kaydet
          butonuna basınız.</b
        >
      </div>
    </div>
    <div
      class="col-md-10 col-xs-12"
      :class="{ 'account-form': user.isReActive }"
    >
      <div class="form-group has-head" v-if="!user.isReActive">
        <label class="head">Şehir:</label>
        <input
          type="text"
          class="brand-input"
          :value="[user.addressList[0] ? user.addressList[0].cityTitle : '']"
          disabled="disabled"
        />
      </div>
      <div class="form-group has-head" v-if="!user.isReActive">
        <label class="head">Bayi Adı:</label>
        <input
          type="text"
          class="brand-input"
          v-model="user.firmName"
          disabled="disabled"
        />
      </div>
      <div class="form-group has-head" v-if="!user.isReActive">
        <label class="head">Ana Bayi Adı:</label>
        <input
          type="text"
          class="brand-input"
          v-model="user.parentFirmNames"
          disabled="disabled"
        />
      </div>
      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group has-head">
          <label class="head">Yetkili Adı:</label>
          <input
            type="text"
            class="brand-input"
            v-model="user.name"
            :disabled="!user.isReActive"
          />
        </div>
      </div>
      <div class="col-md-6 pd0 pd-l-5">
        <div class="form-group has-head">
          <label class="head">Yetkili Soyadı:</label>
          <input
            type="text"
            class="brand-input"
            v-model="user.surname"
            :disabled="!user.isReActive"
          />
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="form-group has-head" v-if="!user.isReActive">
        <label class="head">Doğum Tarihi:</label>
        <input
          v-model="computedDateValue"
          class="brand-input"
          disabled="disabled"
        />
      </div>
      <div class="form-group has-head datepicker" v-if="user.isReActive">
        <label class="head">Doğum Tarihi:</label>
        <date-picker
          v-model="user.birthDate"
          class="brand-input"
          format="DD/MM/YYYY"
          value-type="MM/DD/YYYY"
        ></date-picker>
      </div>

      <div class="form-group has-head" v-if="!user.isReActive">
        <label class="head">Vergi No / TCK No:</label>
        <input
          type="text"
          class="brand-input"
          v-model="user.taxNo"
          disabled="disabled"
        />
      </div>
      <div class="form-group has-head" v-if="!user.isReActive">
        <label class="head">Cep Telefonu:</label>
        <input
          type="text"
          class="brand-input"
          v-model="user.gsm"
          :disabled="!user.isReActive"
        />
      </div>
      <div class="form-group has-head">
        <label class="head">Sabit Telefonu:</label>
        <input
          type="text"
          class="brand-input"
          v-model="user.phone"
          v-if="!user.isReActive"
        />
        <the-mask
          mask="0(###) ### ## ##"
          v-model="user.phone"
          class="brand-input"
          type="phone"
          placeholder="SABİT TELEFONU"
          v-if="user.isReActive"
        ></the-mask>
      </div>
      <div class="form-group has-head">
        <label class="head">E-Posta Adresi:</label>
        <input
          type="text"
          class="brand-input"
          v-model="user.email"
          :disabled="!user.isReActive"
        />
      </div>

      <div class="col-md-6 pd0 pd-r-5">
        <div class="form-group has-head il">
          <label class="head">İL:</label>
          <input
            type="text"
            class="brand-input"
            :value="[user.addressList[0] ? user.addressList[0].cityTitle : '']"
            disabled="disabled"
          />
        </div>
      </div>
      <div class="col-md-6 pd0 pd-l-5 ilce">
        <div class="form-group has-head">
          <label class="head">İLÇE:</label>
          <input
            type="text"
            class="brand-input"
            :value="[
              user.addressList[0] ? user.addressList[0].districtTitle : '',
            ]"
            disabled="disabled"
            v-if="!user.isReActive"
          />
          <v-select
            :options="districts"
            v-model="district"
            class="brand-select"
            placeholder="İLÇE"
            :clearable="false"
            v-if="user.isReActive"
          >
            <template slot="option" slot-scope="option">{{
              option.label
            }}</template>
            <template v-slot:no-options>İlçenizi bulamadık..</template>
          </v-select>
        </div>
      </div>
      <div class="col-md-12 pd0">
        <div class="form-group btn-textarea has-head">
          <label class="head">Açık Adres:</label>
          <textarea
            type="text"
            class="brand-input"
            placeholder="AÇIK ADRES"
            :disabled="!user.isReActive"
            v-model="address"
          ></textarea>
        </div>
      </div>
      <div class="row" v-if="!user.isReActive">
        <div class="col-md-12">
          <label for="sms" class="btn-radio">
            <input
              type="checkbox"
              v-model="subscribeStatus.isSubscribedSMS"
              name="a"
              id="sms"
            />
            SMS ONAY
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="col-md-12">
          <label for="email" class="btn-radio">
            <input
              type="checkbox"
              v-model="subscribeStatus.isSubscribedEmail"
              name="a"
              id="email"
            />
            E-POSTA ONAY
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div
        class="col-md-12 pd0"
        v-if="user.isReActive"
        style="margin:10px 0px;"
      >
        <label for="sms" class="btn-radio">
          <input type="checkbox" v-model="isAcceptMyData" name="a" id="sms" />
          Bilgilerimin doğruluğunu kabul ederim.
          <span class="checkmark"></span>
        </label>
      </div>
      <button
        v-if="user.isReActive"
        class="brand-button-outline btn-active"
        @click="updateUser"
      >
        Kaydet
      </button>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Swal from "sweetalert2";
import EventBus from "@/components/event-bus";

export default {
  name: "Info",
  components: { TheMask, DatePicker },
  data() {
    return {
      user: {},
      disabled: true,
      city: null,
      isAcceptMyData: false,
      citys: [],
      provinces: [],
      address: null,
      district: null,
      districts: [],
      subscribeStatus: {
        isSubscribedSMS: false,
        isSubscribedEmail: false,
      },
      computedDateValue: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getCity() {
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.user.addressList[0].cityId,
      }).then((result) => {
        console.log("🚀 ~ getCity ~ result:", result);
        this.district = "";
        this.districts = [];
        result.data.forEach((item) => {
          this.districts.push({ id: item.id, label: item.title });
        });
      });
    },
    getData() {
      HTTP.get("/User/CheckToken").then((result) => {
        this.user = result.data;
        this.address = this.user.addressList[0]?.address;
        if (this.user.isReActive) {
          EventBus.$emit("updateFormActive", true);
          this.getCity();
        } else {
          EventBus.$emit("updateFormActive", false);
        }

        let val = new Date(this.user.birthDate).toLocaleDateString("tr");
        this.computedDateValue = val || null;
        this.subscribeStatus.isSubscribedSMS = this.user.isSubscribedSMS;
        this.subscribeStatus.isSubscribedEmail = this.user.isSubscribedEmail;
      });
    },
    updateUser() {
      /* eslint-disable */

      if (
        this.user.name.length == 0 ||
        this.user.surname.length == 0 ||
        !this.district ||
        this.address.length < 3 ||
        !this.isAcceptMyData
      ) {
        Swal.fire({
          type: "warning",
          title: "Bilgi Güncelleme",
          customClass: "photo-upload-campaign",
          text:
            "Lütfen girdiğiniz bilgileri kontrol ederek gerekli alanarı doldurduğunuzdan emin olunuz.",
          confirmButtonText: "Tamam",
        });
        return;
      }
      let data = {
        name: this.user.name,
        surname: this.user.surname,
        phone: this.user.gsm,
        email: this.user.email,
        districtId: this.district?.id,
        adress: this.address,
        birthDate: this.user.birthDate,
        isAcceptMyData: this.isAcceptMyData,
      };
      HTTP.post("User/UpdateByReactivate", data).then((result) => {
        if (result.data.success) {
          EventBus.$emit("updateFormActive", false);
          Swal.fire({
            type: "success",
            title: "Bilgi Güncelleme",
            customClass: "photo-upload-campaign",
            text: result.data.data,
            confirmButtonText: "Tamam",
          });
          this.getData();
          // window.location = "/";
        } else {
          Swal.fire({
            type: "warning",
            title: "Bilgi Güncelleme",
            customClass: "photo-upload-campaign",
            text: result.data.data,
            confirmButtonText: "Tamam",
          });
        }
      });
    },
  },
  watch: {
    "subscribeStatus.isSubscribedSMS": function() {
      HTTP.post("User/SubscribeEmailAndSms", this.subscribeStatus);
    },
    "subscribeStatus.isSubscribedEmail": function() {
      HTTP.post("User/SubscribeEmailAndSms", this.subscribeStatus);
    },
  },
};
</script>
<style lang="scss">
.has-head {
  position: relative;
  .head {
    font-weight: normal;
    position: absolute;
    left: 19px;
    top: 8px;
    font-size: 11px;
  }
  input,
  textarea {
    font-weight: 600;
    padding-top: 20px;
    padding-left: 20px;
    border-radius: 30px;
  }
}
.pad {
  padding: 15px;
}
</style>
