<template>
  <div class="container-fluid footer">
    <div class="top">
      <div class="container">
        <div class="row">
          <div class="col-md-2 col-xs-12" @click="sendTop">
            <a href="https://www.baymak.com.tr/" target="_blank" rel="nooponer">
              <img src="@/assets/images/baymak_logo.png" alt="Logo" />
            </a>
          </div>
          <div class="col-md-10 col-xs-12">
            <ul class="nav navbar-nav pd0">
              <li v-if="isLoggedIn">
                <router-link :to="{ name: 'Home' }">ANA SAYFA</router-link>
              </li>
              <li>
                <a
                  href="#"
                  class="ot-sdk-show-settings"
                  style="text-transform: uppercase;"
                >
                  TANIMLAMA BİLGİSİ AYARLARI
                </a>
              </li>
              <li>
                <router-link :to="{ name: 'Faq' }"
                  >SIKÇA SORULAN SORULAR</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Contact' }">İLETİŞİM</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container bottom">
      <div class="col-md-6 col-xs-12">
        <router-link
          :to="{
            name: 'SafetyPrivacy'
          }"
          >Kişisel Verilerin Korunması ve Gizlilik</router-link
        >

        <a
          href="https://e-sirket.mkk.com.tr/esir/Dashboard.jsp#/sirketbilgileri/11493"
          target="_blank"
          rel="noopener noreferrer"
          >Bilgi Toplumu Hizmetleri</a
        >
        <a href="tel:+908504500575">0 850 450 05 75</a>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="pull-right">
          <div class="social">
            <a
              href="https://www.facebook.com/baymakofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/images/facebook.png" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/baymakofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/images/instagram.png" alt="Instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCXCuNB_XkXL_0LQQA3ZGJ6A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/images/youtube.png" alt="Youtube" />
            </a>
            <a
              href="https://www.linkedin.com/company/baymakbdrthermeagroup/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="@/assets/images/linkedin.png" alt="Youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  methods: {
    sendTop: function() {
      if (this.$route.name == "Home" || this.$route.name == "Login") {
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>
