<template>
  <div>
    <router-link
      :to="{
        name: [sub ? 'subFirmsCampaignsDetails' : 'campaignDetails'],
        params: {
          id: campaign.id,
        },
      }"
      v-if="!noLink"
    >
      <div class="col-md-12">
        <img
          src="@/assets/images/klima-1.jpg"
          alt="Klima"
          v-if="campaign.image == null"
        />
        <img :src="campaign.image" alt="Klima" v-if="campaign.image != null" />
        <span class="h3 bold" style="display:block;margin-top:15px;">{{ campaign.title }}</span>
      </div>
    </router-link>

    <div class="col-md-12" v-if="noLink">
      <img
        src="@/assets/images/klima-1.jpg"
        alt="Klima"
        v-if="campaign.image == null && !noImage"
      />
      <img
        :src="campaign.image"
        alt="Klima"
        v-if="campaign.image != null && !noImage"
      />

      <span class="h3 bold">
        {{ campaign.title ? campaign.title : "Kampanya Başlığı" }}
      </span>
    </div>
    <div class="clearfix"></div>
  </div>
</template>
<script>
export default {
  name: "campaign",
  props: ["campaign", "sub", "noLink", "noImage"],
};
</script>
