<template>
  <div class="five">
    <div class="campaign-actions">
      <div class="col-md-3 col-xs-12" @click="showSerialNumberForm">
        <div>
          <img src="@/assets/images/serial-number.png" alt="Seri No Ekle" />
          <span class="h4">SERİ NO EKLE</span>
        </div>
      </div>

      <router-link
        :to="{
          name: 'activeCampaigns',
        }"
        class="col-md-3 col-xs-3"
      >
        <div>
          <img
            src="@/assets/images/active-campaign.png"
            alt="Aktif Kampanyalar"
          />
          <span class="h4">KAMPANYALAR</span>
        </div>
      </router-link>
      <router-link
        :to="{
          name: 'besAccounts',
        }"
        class="col-md-3 col-xs-3"
      >
        <div>
          <img src="@/assets/images/bes-accounts.png" alt="Bes Hesaplarım" />
          <span class="h4">
            BES
            <br />HESAPLARIM
          </span>
        </div>
      </router-link>
      <router-link
        v-if="false"
        :to="{
          name: 'joinedCampaigns',
        }"
      >
        <div class="col-md-3 col-xs-3">
          <img
            src="@/assets/images/follow-campaign.png"
            alt="Kampanya Takibi"
          />
          <span class="h4">KAMPANYA TAKİBİ</span>
        </div>
      </router-link>
      <router-link
        v-if="false"
        :to="{
          name: 'completedCampaigns',
        }"
      >
        <div class="col-md-3 col-xs-3">
          <img
            src="@/assets/images/completed-campaign.png"
            alt="Aktif Kampanyalar"
          />
          <span class="h4">TAMAMLANAN KAMPANYALAR</span>
        </div>
      </router-link>
    </div>
    <!-- Modal -->
    <span
      class="hide"
      id="toggleModal"
      data-toggle="modal"
      data-target="#serialNumber"
    ></span>
    <div class="modal fade" id="serialNumber" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>
        <div class="modal-content">
          <serialNumber></serialNumber>
        </div>
      </div>
    </div>
    <!-- Modal End -->
  </div>
</template>

<script>
import EventBus from "@/components/event-bus";
import serialNumber from "@/components/modal/Serial-Number";

export default {
  name: "subDealer",
  data() {
    return {};
  },
  components: {
    serialNumber,
  },
  methods: {
    showSerialNumberForm: function() {
      EventBus.$emit("clicked", true);
      document.getElementById("toggleModal").click();
    },
  },
};
</script>
<style lang="scss">
.five {
  @media (min-width: 768.99px) {
    .campaign-actions {
      gap: 20px;
      display: flex;
      .col-md-3 {
        width: 33% !important;
        aspect-ratio: 1 / 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .five .campaign-actions .col-md-3 {
    width: auto;
    padding: 0px 0px;
  }
  .five .campaign-actions .col-xs-12 {
    width: 100% !important;
    img {
      margin-top: 30px;
    }
    .h4 {
      margin-top: 20px;
    }
  }
  .five .campaign-actions .col-xs-3 {
    width: 50% !important;
    .h4 {
      height: 20px;
      margin-top: 10px;
      font-size: 13px;
    }
  }
}
.modal-content {
  position: relative;
  overflow-y: auto;
  max-height: 600px;
  padding: 15px;
}
</style>
<style scoped></style>
