<template>
  <div class="list">
    <div
      class="col-md-4"
      v-for="(campaign, index) in campaignList"
      :key="index"
    >
      <campaign :campaign="campaign" :sub="sub"></campaign>
    </div>
  </div>
</template>

<script>
import campaign from "@/components/campaigns/Item";
import { HTTP } from "@/main-source";

export default {
  name: "List",
  components: {
    campaign,
  },
  props: ["sub", "completeds", "passive"],
  data() {
    return {
      campaignList: [],
    };
  },
  computed: {
    userRoleId: function() {
      return sessionStorage.userRoleId;
    },
  },
  created() {
    let uri = "Banner/Campaign";
    // if (this.userRoleId == 11 && this.sub != true) {
    //   uri = "DealerCampaign/GetListOnlyActive";
    // }

    // if (this.userRoleId == 11 && this.sub != true && this.passive == true) {
    //   uri = "DealerCampaign/GetListOnlyPassive";
    // }

    // if (this.completeds == true) {
    //   uri = "Campaign/GetListOnlyOld";
    // }

    HTTP.post(uri).then((result) => {
      console.log(
        "🚀 ~ file: List.vue:49 ~ HTTP.post ~ result.data:",
        result.data
      );
      this.campaignList = result.data;
    });
  },
};
</script>
<style lang="scss"></style>
