<template>
  <div>
    <span class="h3">Bes Hesap Detaylarım</span>
    <pointDetails :isPoint="false"></pointDetails>
    <div id="grid"></div>
  </div>
</template>
<script>
import pointDetails from "@/components/account/partials/pointDetails";

export default {
  name: "BesAccountDetails",
  data() {
    return {};
  },
  components: {
    pointDetails,
  },
  mounted() {
    /* eslint-disable */

    setTimeout(() => {
      $(".k-grid-excel")[0].onmousedown = function(e) {
        $("#loadingDiv").show();
      };
    }, 1000);

    var token = sessionStorage.token;
    kendo.culture("tr-TR");
    function RunKendoUser() {
      var remoteDataSource = new kendo.data.DataSource({
        pageSize: 10,
        transport: {
          read: {
            url: "https://baymak.tutkal.com.tr/Api/Transaction/GetActive",
            dataType: "json",
            type: "POST",
            contentType: "application/json",
            beforeSend: function(req) {
              req.setRequestHeader("token", token);
            },
            complete: function(data) {},
          },

          parameterMap: function(options, operation) {
            operation = operation;

            if (operation === "read") {
              //Export settings start
              //Export settings stop
            }

            if (operation !== "read" && options.model) {
              return JSON.stringify(options.models[0]);
            } else {
              return JSON.stringify(options);
            }
          },
        },
        filter: [
          {
            field: "transactionType",
            operator: "eq",
            value: "5",
            logic: "and",
          },
        ],
        schema: {
          data: "data",
          total: "total",
          model: {
            id: "id",
            fields: {
              id: { editable: false, hidden: true, defaultValue: 0 },
            },
          },
        },
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        error: function(e) {},
      });

      $("#grid").kendoGrid({
        dataSource: remoteDataSource,
        toolbar: ["excel"],
        dataSource: remoteDataSource,
        excel: {
          fileName: "Bes Hesap Detaylarım.xlsx",
          filterable: true,
          allPages: true,
        },
        excelExport: function(e) {
          setExcel(e);
        },
        scrollable: true,
        sortable: true,
        height: 490,
        filterable: {
          extra: true,
          operators: {
            string: {
              contains: "Bunu içeren",
              startswith: "Bununla Başlayan",
              endswith: "Bununla biten",
              eq: "Eşit",
              neq: "Eşit olmayan",
            },
          },
        },
        resizable: true,
        pageable: {
          refresh: true,
          pageSizes: false,
          buttonCount: 5,
        },
        columns: [
          {
            field: "created",
            title: "Oluşturma Tarihi",
            filterable: false,
            template: "#= kendo.toString(new Date(created),'dd/MM/yyyy') #",
          },
          {
            field: "campaignTitle",
            title: "Kampanya Adı",
            filterable: true,
          },
          {
            field: "serialNumber",
            title: "Seri No",
            filterable: true,
          },
          {
            field: "transactionTypeText",
            title: "Açıklama",
            filterable: true,
          },
          {
            field: "amount",
            title: "Kazanım",
            filterable: true,
          },
          {
            field: "statusTitle",
            title: "Durum",
            filterable: true,
          },
        ],
      });
    }
    RunKendoUser();
  },
};
</script>
<style>
.k-current-page {
  display: none !important;
}
</style>
