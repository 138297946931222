<template>
  <div>
    <div class="form-group">
      <span class="info">
        TELEFONUNUZA GELEN KODU GİRİN VE SİSTEME GİRİŞ YAPABİLMEK İÇİN YENİ
        ŞİFRE OLUŞTURUN
      </span>
      <div class="form-group">
        <input
          type="text"
          v-model="user.code"
          class="brand-input"
          placeholder="DOĞRULAMA KODU"
        />
        <span class="error" :class="{ show: notValid.includes('code') }"
          >Lütfen cep telefonunuza gönderilen doğrulama kodunu giriniz.</span
        >
      </div>
      <div class="form-group">
        <input
          type="password"
          v-model="user.newPassword"
          class="brand-input"
          placeholder="YENİ ŞİFRE"
          maxlength="20"
        />
        <span class="error" :class="{ show: notValid.includes('newPassword') }">
          Lütfen yeni şifrenizi giriniz.
          <br />Unutmayın, üyelik şifreniz en az 6 haneli olmalıdır. Rakamların
          yanı sıra mutlaka bir harf kullanmalısınız.
        </span>
      </div>
      <div class="form-group">
        <input
          type="password"
          v-model="user.newPasswordCheck"
          class="brand-input"
          placeholder="YENİ ŞİFRE TEKRAR"
          @keyup.enter="changePassword"
          maxlength="20"
        />
        <span
          class="error"
          :class="{ show: notValid.includes('newPasswordCheck') }"
        >
          Lütfen yeni şifrenizi giriniz.
          <br />Unutmayın, üyelik şifreniz en az 6 haneli olmalıdır. Rakamların
          yanı sıra mutlaka bir harf kullanmalısınız.
        </span>
        <span class="error" :class="{ show: notValid.includes('notmatch') }"
          >Girdiğiniz şifreler eşleşmiyor.</span
        >
      </div>
      <vue-recaptcha
        sitekey="6Lf_nTgaAAAAAOvJFMkNzYDBPYp-cTEZ39pD1QXt"
        :loadRecaptchaScript="true"
        @verify="captchaStatus"
        @expired="captchaStatus"
      ></vue-recaptcha>
      <button type="button" class="brand-button" @click="changePassword">
        KAYDET
      </button>
    </div>
    <!-- Modal -->
    <span
      class="hide"
      id="toggleModal"
      data-toggle="modal"
      data-target="#myModal"
    ></span>
    <div class="modal fade" id="myModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content" v-html="resultMessage"></div>
      </div>
    </div>
    <!-- Modal End -->
  </div>
</template>
<script>
import EventBus from "@/components/event-bus";
import { HTTP } from "@/main-source";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "changePassword",
  data() {
    return {
      user: {
        gsm: "",
        code: "",
        newPassword: "",
        newPasswordCheck: ""
      },
      notValid: [],
      resultMessage: "",
      captchaCode: null
    };
  },
  components: {
    VueRecaptcha
  },
  methods: {
    checkForm: function() {
      /*eslint-disable*/
			let regEx = /^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{6,20}$/;

			if (!regEx.test(this.user.newPassword)) {
				this.notValid.push("newPassword");
			} else {
				this.notValid = this.notValid.filter(item => item != "newPassword");
			}
			if (!regEx.test(this.user.newPasswordCheck)) {
				this.notValid.push("newPasswordCheck");
			} else {
				this.notValid = this.notValid.filter(
					item => item != "newPasswordCheck"
				);
			}

			/*eslint-disable*/

			if (this.user.code.length < 4) {
				this.notValid.push("code");
			} else {
				this.notValid = this.notValid.filter(item => item != "code");
			}

			if (this.user.newPassword != this.user.newPasswordCheck) {
				this.notValid.push("notmatch");
			} else {
				this.notValid = this.notValid.filter(item => item != "notmatch");
			}
		},
		changePassword: function() {
			this.checkForm();
			if (this.notValid.length == 0 &&
        this.captchaCode != null &&
        this.captchaCode != undefined) {
          this.user.captchaCode = this.captchaCode;
				HTTP.post("Login/ChangePasswordByCode", this.user).then(result => {
					if (result.data.success == true) {
						this.resultMessage =
							result.data.data + "<br><br>Giriş ekranına yönlendiriliyorsunuz.";
						setTimeout(() => {
							document.getElementById("toggleModal").click();

							setTimeout(() => {
								router.push("/login");
							}, 300);
						}, 3500);
					} else {
						this.resultMessage = result.data.errorMessage;
					}
					document.getElementById("toggleModal").click();
				});
			}
    },
     captchaStatus: function(val) {
      this.captchaCode = val;
    }
	},
	mounted() {
		EventBus.$on("sendCodeSuccess", phone => {
			this.user.gsm = phone;
		});
	}
};
</script>
