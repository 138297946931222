// Transpiler for older browsers
import "babel-polyfill";
import "events-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import { Grid } from "@progress/kendo-vue-grid";
import "vue2-datepicker/index.css";
import VuePlyr from "@skjnldsv/vue-plyr";
import Notifications from 'vue-notification'
// import 'sweetalert2/src/sweetalert2.scss';
import "@skjnldsv/vue-plyr/dist/vue-plyr.css";

Vue.component("v-select", vSelect);
Vue.component("Grid", Grid);
Vue.use(VuePlyr);
Vue.use(Notifications)

Vue.prototype.$currentUser = { isActivePhotoCampaign: true };
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
