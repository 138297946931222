var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campaign-actions"},[_c('router-link',{attrs:{"to":{
      name: 'activeCampaigns'
    }}},[_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('img',{attrs:{"src":require("@/assets/images/active-campaign.png"),"alt":"Aktif Kampanyalar"}}),_c('span',{staticClass:"h4"},[_vm._v("AKTİF KAMPANYALAR")])])]),_c('router-link',{attrs:{"to":{
      name: 'passiveCampaigns'
    }}},[_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('img',{attrs:{"src":require("@/assets/images/follow-campaign.png"),"alt":"Aktif Kampanyalar"}}),_c('span',{staticClass:"h4"},[_vm._v("PASİF KAMPANYALAR")])])]),_c('router-link',{attrs:{"to":{
      name: 'subFirmsCampaigns'
    }}},[_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('img',{attrs:{"src":require("@/assets/images/sub-company.png"),"alt":"ALT FİRMALAR KAMPANYA TAKİBİ"}}),_c('span',{staticClass:"h4"},[_vm._v("ALT FİRMALAR KAMPANYA TAKİBİ")])])]),_c('router-link',{attrs:{"to":{
      name: 'completedCampaigns'
    }}},[_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('img',{attrs:{"src":require("@/assets/images/completed-campaign.png"),"alt":"ALT FİRMALAR TAMAMLANAN KAMPANYANLAR"}}),_c('span',{staticClass:"h4"},[_vm._v("ALT FİRMALAR TAMAMLANAN KAMPANYALAR")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }