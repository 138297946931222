import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false
  },
  mutations: {
    setLoginStatus(state, value) {
      state.isLoggedIn = value;
    }
  },
  actions: {
    setLoginStatus(context, value) {
      context.commit("setLoginStatus", value);
    }
  },
  modules: {},
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn;
    }
  }
});
