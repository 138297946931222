<template>
  <div>
    <div class="inner-page-top-bg">
      <div class="color">
        <h1 class="h1 text-center mg-0">ARAMA SONUÇLARI</h1>
        <p>
          "{{ $route.query.q.trim() }}" için sonuçlar aşağıda listelenmektedir.
        </p>
        <img src="@/assets/images/arrow-down-dark.png" alt />
      </div>
    </div>
    <div class="container page-negative account mg-100">
      <div class="col-md-12 content col-xs-12 results">
        <!-- name: [isDealer ? 'subFirmsCampaignsDetails' : 'campaignDetails'], -->
        <span
          class="d-block text-center"
          v-if="results.length == 0 && pageResults.length == 0"
          >Sonuç yok.</span
        >

        <router-link
          v-for="(page, index) in pageResults"
          :to="{
            name: page.name
          }"
          :key="index"
        >
          {{ page.as }}
          <img src="@/assets/images/arrow-right-dark.png" alt />
        </router-link>

        <router-link
          v-for="(result, index) in results"
          :to="{
            name: 'campaignDetails',
            params: {
              id: result.id
            }
          }"
          :key="index"
        >
          {{ result.title }}
          <img src="@/assets/images/arrow-right-dark.png" alt />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
export default {
  name: "Search",
  data() {
    return {
      results: [],
      pageResults: []
    };
  },
  created() {
    this.search();
  },
  methods: {
    search: function() {
      let uri = "Campaign/Search";
      if (sessionStorage.userRoleId == 11) {
        uri = "DealerCampaign/Search";
      }
      let sq = {
        key: this.$route.query.q
      };
      HTTP.post(uri, sq).then(result => {
        this.results = result.data;
      });

      this.pageResults = [];
      this.$router.options.routes.forEach(page => {
        let pageName = page.as.toLowerCase();
        let q = this.$route.query.q.toLowerCase();
        if (pageName.search(q) >= 0) {
          if (
            (!this.isDealer && page.name == "subFirmsCampaigns") ||
            page.name == "subFirmsCampaignsDetails"
          ) {
            return;
          }
          this.pageResults.push(page);
        }
      });
    }
  },
  watch: {
    "$route.query.q": function() {
      this.search();
    }
  },
  computed: {
    isDealer: function() {
      return sessionStorage.userRoleId == 11 ? true : false;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/colors";
.mg-0 {
  margin-left: 0 !important;
}
.color {
  color: $dark;
}
.mg-100 {
  margin-top: -100px !important;
}
.results {
  margin-top: 15px !important;
  a {
    display: block;
    padding: 20px 0px;
    border-bottom: 1px solid $brand-color;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    img {
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: auto;
      transition: all 0.3s ease;
    }
    &:hover {
      color: $black;
      border-bottom-color: lighten($brand-color, 20%);
      img {
        transform: translateX(-5px);
      }
    }
  }
}
</style>
