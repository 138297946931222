<template>
  <div class="container-fluid visible-page double-action" lang="tr">
    <div class="container">
      <div class="col-md-11 col-md-offset-1 col-xs-12">
        <!-- Right -->
        <div class="col-md-6 right-pad col-md-push-6 col-xs-12">
          <span class="h3 brand-color title-1 right-head">KAYIT FORMU</span>
          <img
            src="@/assets/images/maskot/signup.png"
            alt="Baymak Welcome"
            class="img-responsive centered-mascot"
          />
        </div>
        <!-- Right End -->
        <!-- Left -->
        <div class="col-md-6 col-md-pull-6 col-xs-12">
          <div class="form-group brand-select" width="200"></div>
          <div class="form-group brand-select">
            <v-select
              :options="citys"
              v-model="city"
              class="brand-select"
              placeholder="ŞEHİR"
              :clearable="false"
              :filter="getCity"
            >
              <template slot="option" slot-scope="option">{{
                option.label
              }}</template>
              <template v-slot:no-options>Şehrinizi bulamadık.</template>
            </v-select>
            <span class="error" :class="{ show: notValid.includes('city') }"
              >Lütfen şehir seçiniz.</span
            >
          </div>
          <div class="form-group brand-select">
            <v-select
              :options="firms"
              v-model="firm"
              ref="firms"
              class="brand-select"
              placeholder="FİRMA SEÇİNİZ"
              :clearable="false"
            >
              <template slot="option" slot-scope="option">{{
                option.label
              }}</template>
              <template v-slot:no-options="{ search }">
                &nbsp;
                <div v-if="search.length > 0">
                  <b>{{ search }}</b>
                  adlı firmanızı bulamıyoruz.
                  <br />Firmanızın cari adını eksiksiz şekilde yazıp "Yeni Firma
                  Ekle" butonuna basarak firma adınızı ekleyebilirsiniz.
                  <span class="error show">
                    <small>
                      * Lütfen kayıt formundaki diğer alanları da eksiksiz
                      doldurmayı unutmayın!
                    </small>
                  </span>
                </div>
              </template>
              <template v-slot:list-footer="{ search, filteredOptions }">
                <button
                  v-if="filteredOptions.length == 0"
                  type="button"
                  class="brand-button add-new"
                  @click="newFirm(search)"
                >
                  YENİ FİRMA EKLE
                </button>
              </template>
            </v-select>
            <span class="newFirmInfo">
              Değerli Kullanıcımız, Firma isminiz açılan listede bulunmuyorsa
              lütfen aynı hanede (Firma Seçiniz hanesinde) firmanızın ismini
              yazın. Firma isminizi doğru şekilde yazdıktan sonra “Yeni Firma
              Ekle” butonuna basın.
            </span>
            <span class="error" :class="{ show: notValid.includes('firm') }"
              >Lütfen firma bilgisi belirtiniz.</span
            >
          </div>

          <div
            class="form-group brand-select"
            v-if="checkParent && isNewFirm == false"
          >
            <v-select
              :options="parentFirms"
              v-model="parentFirm"
              ref="parentFirm"
              class="brand-select"
              placeholder="BAĞLI BULUNDUĞUNUZ BAYİ"
            >
              <template v-slot:no-options>Kayıtlı bayi bulunmuyor.</template>
            </v-select>
            <span
              class="error"
              :class="{ show: notValid.includes('parentFirm') }"
              >Lütfen bağlı bulunduğunuz bayi seçiniz.</span
            >
          </div>

          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group">
              <input
                type="text"
                class="brand-input"
                v-model="name"
                placeholder="YETKİLİ ADI"
              />
              <span class="error" :class="{ show: notValid.includes('name') }"
                >Lütfen adınızı giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-l-5">
            <div class="form-group">
              <input
                type="text"
                class="brand-input"
                v-model="surname"
                placeholder="SOYADI"
              />
              <span
                class="error"
                :class="{ show: notValid.includes('surname') }"
                >Lütfen soyadınızı giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-12 pd0">
            <div class="form-group datepicker register">
              <date-picker
                v-model="birthDate"
                placeholder="DOĞUM TARİHİ"
                class="brand-input"
                format="DD/MM/YYYY"
                value-type="MM/DD/YYYY"
              ></date-picker>
            </div>
          </div>

          <div class="col-md-12 pd0">
            <div class="form-group">
              <the-mask
                mask="###########"
                v-model="taxNo"
                class="brand-input"
                type="phone"
                placeholder="VERGİ NO / TCKN"
                maxlength="11"
              ></the-mask>
              <span class="error" :class="{ show: notValid.includes('taxNo') }"
                >Lütfen geçerli bir vergi numarası / TCK numarası giriniz.</span
              >
            </div>
          </div>

          <div class="col-md-12 pd0 pd-l-5">
            <div class="form-group">
              <small>Vergi Levhası görseli yükleyiniz</small>
              <input
                type="file"
                accept="image/*"
                class="brand-input"
                @change="setPhoto"
              />
              <span
                class="error"
                :class="{ show: notValid.includes('taxSheet') }"
                >Lütfen vergi levhası yükleyiniz.
                <small>(Sadece fotoğraf kabul ediliyor.)</small></span
              >
            </div>
          </div>

          <div class="col-md-12 pd0">
            <div class="form-group">
              <the-mask
                mask="0 (5##) ### ## ##"
                v-model="gsm"
                class="brand-input"
                type="phone"
                placeholder="CEP TELEFONU"
              ></the-mask>

              <span class="error" :class="{ show: notValid.includes('gsm') }"
                >Lütfen geçerli bir cep telefonu numarası giriniz.</span
              >
            </div>
          </div>

          <div class="col-md-12 pd0">
            <div class="form-group">
              <the-mask
                mask="0(###) ### ## ##"
                v-model="phone"
                class="brand-input"
                type="phone"
                placeholder="SABİT TELEFONU"
              ></the-mask>
            </div>
          </div>

          <div class="col-md-12 pd0">
            <div class="form-group">
              <input
                type="text"
                class="brand-input email"
                v-model="email"
                placeholder="E-POSTA"
              />
              <span class="error" :class="{ show: notValid.includes('email') }"
                >Lütfen e-posta adresinizi giriniz.</span
              >
            </div>
          </div>

          <div class="col-md-12 pd0">
            <div class="form-group btn-textarea">
              <textarea
                type="text"
                class="brand-input"
                v-model="address"
                placeholder="FİRMA AÇIK ADRES"
              ></textarea>
              <span
                class="error"
                :class="{ show: notValid.includes('address') }"
                >Lütfen açık adresinizi giriniz.</span
              >
            </div>
          </div>

          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group brand-select">
              <v-select
                :options="provinces"
                v-model="province"
                class="brand-select"
                placeholder="İL"
                :clearable="false"
                disabled
              >
                <template v-slot:no-options>Şehrinizi bulamadık..</template>
              </v-select>
              <span
                class="error"
                :class="{ show: notValid.includes('province') }"
                >Lütfen şehir seçiniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-l-5">
            <div class="form-group brand-select">
              <v-select
                :options="districts"
                v-model="district"
                class="brand-select"
                placeholder="İLÇE"
                :clearable="false"
              >
                <template slot="option" slot-scope="option">{{
                  option.label
                }}</template>
                <template v-slot:no-options>İlçenizi bulamadık..</template>
              </v-select>
              <span
                class="error"
                :class="{ show: notValid.includes('district') }"
                >Lütfen ilçe seçiniz.</span
              >
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="radio-btn-list">
            <div class="col-md-12 pd0">
              <label for="condition" class="btn-radio">
                <input
                  type="checkbox"
                  v-model="conditionAccept"
                  name="a"
                  id="condition"
                />
                <u data-toggle="modal" data-target="#termsandconditions"
                  >Kampanya Katılım Koşullarını</u
                >
                okudum, onaylıyorum
                <span class="checkmark"></span>
              </label>
              <span
                class="error"
                :class="{ show: notValid.includes('conditionAccept') }"
                >Lütfen onaylayınız.</span
              >
            </div>

            <div class="col-md-12 pd0">
              <label for="kvkk" class="btn-radio">
                <input
                  type="checkbox"
                  v-model="kvkkAccept"
                  name="a"
                  id="kvkk"
                />
                <u data-toggle="modal" data-target="#safetyModal"
                  >KVKK Aydınlatma metnini</u
                >
                okudum. Onaylıyorum.
                <span class="checkmark"></span>
              </label>
              <span
                class="error"
                :class="{ show: notValid.includes('kvkkAccept') }"
                >Lütfen onaylayınız.</span
              >
            </div>

            <div class="col-md-12 pd0">
              <label for="remember1" class="btn-radio">
                <input type="checkbox" name="a2" id="remember1" checked />
                SMS iletişimine onay veriyorum.
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="col-md-12 pd0">
              <label for="remember2" class="btn-radio">
                <input type="checkbox" name="a3" id="remember2" checked />
                E-Posta iletişimine onay veriyorum.
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="form-group">
            <span class="error" :class="{ show: notValid.length > 0 }"
              >Lütfen girdiğiniz verileri kontrol ediniz.</span
            >
            <button type="button" class="brand-button" @click="register">
              KAYIT OL
              <Loading v-if="isRegistering"></Loading>
            </button>
          </div>
        </div>
        <!-- Left End -->
      </div>
    </div>
    <!-- Modal -->
    <span
      class="hide"
      id="toggleModal"
      data-toggle="modal"
      data-target="#myModal"
    ></span>
    <div class="modal fade" id="myModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <span class="h3">ÜYE KAYIT</span>
          Üyelik talebiniz başarıyla işleme alınmıştır. Talebiniz Baymak
          tarafından incelenip onaylanacaktır. Üyelik onayının ardından
          Baymaklife sistemine girişiniz için üyelik şifreniz cep telefonunuza
          SMS olarak gönderilecektir.
          <br />
          <br />Yönlendiriliyorsunuz..
        </div>
      </div>
    </div>
    <!-- Modal End -->
    <!-- Modal -->
    <span
      class="hide"
      id="trigger"
      data-toggle="modal"
      data-target="#errorModal"
    ></span>
    <div class="modal fade" id="errorModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content" v-if="errorMessage">
          <span class="h3">ÜYE KAYIT</span>
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <!-- Modal End -->

    <!-- Safety Modal -->

    <div class="modal fade" id="safetyModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <SafetyPrivacyText></SafetyPrivacyText>
        </div>
      </div>
    </div>
    <!-- Safety Modal End -->

    <!-- Safety Modal -->

    <div class="modal fade" id="termsandconditions" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <termsandconditions></termsandconditions>
        </div>
      </div>
    </div>
    <!-- Safety Modal End -->
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import { TheMask } from "vue-the-mask";
import Loading from "@/components/loading";
import router from "@/router";
import SafetyPrivacyText from "@/components/static/Safety-Privacy";
import termsandconditions from "@/components/static/Terms-Conditions";
import DatePicker from "vue2-datepicker";

export default {
  name: "Register",
  data() {
    return {
      city: "",
      province: "",
      district: "",
      firm: "",
      parentFirm: "",
      gsm: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      birthDate: "",
      address: "",
      password: "",
      taxNo: "",
      districts: [],
      citys: [],
      provinces: [],
      firms: [],
      firmsAllData: [],
      parentFirms: [],
      taxSheetData: null,
      newFirmName: "",
      isNewFirm: false,
      notValid: [],
      isRegistering: false,
      conditionAccept: false,
      kvkkAccept: false,
      errorMessage: "",
      checkParent: false,
    };
  },
  components: {
    Loading,
    TheMask,
    SafetyPrivacyText,
    termsandconditions,
    DatePicker,
  },
  created() {
    HTTP.post("City/GetList").then((result) => {
      result.data.forEach((city) => {
        this.citys.push({
          id: city.id,
          label: city.title,
        });
        this.provinces.push({ id: city.id, label: city.title });
      });
    });
  },
  methods: {
    toBase64: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      }),
    setPhoto(event) {
      const selectedFile = event.target.files[0];
      this.toBase64(selectedFile)
        .then((result) => {
          this.taxSheetData = result;
        })
        .catch(() => {
          this.taxSheetData = null;
        })
        .finally(() => {
          if (this.notValid.length > 0) {
            this.checkForm();
          }
        });
    },
    getCity: function(list, q) {
      return list.filter((item) =>
        item.label
          .toLocaleLowerCase("tr-TR")
          .includes(q.toLocaleLowerCase("tr-TR"))
      );
    },
    newFirm: function(search) {
      this.isNewFirm = true;
      this.firm = {
        id: 0,
        label: search,
      };

      this.$refs.firms.open = false;
      this.$refs.firms.searchEl.blur();
      this.checkParent = true;
    },
    register: function() {
      this.checkForm();

      if (this.notValid.length == 0) {
        this.isRegistering = true;
        let data = {
          gsm: "5" + this.gsm,
          phone: this.phone,
          name: this.name,
          surname: this.surname,
          email: this.email,
          taxNo: this.taxNo,
          password: "",
          address: this.address,
          cityId: this.province.id,
          districtId: this.district.id,
          taxSheet: this.taxSheetData,
        };

        if (this.firm != "" && this.firm.id == 0) {
          data.firmName = this.firm.label;
          data.id = 0;
        } else {
          if (this.firm.id != undefined) {
            data.id = this.firm.id;
          }
        }

        /* eslint-disable */
        if (this.parentFirm != "") {
          data.parentId = this.parentFirm.id;
        }
        if (this.birthDate != "") {
          data.birthDate = this.birthDate;
        }

        HTTP.post("Login/Register", data)
          .then((result) => {
            if (result.data.success == true) {
              document.getElementById("toggleModal").click();
              setTimeout(() => {
                /* eslint-disable */
                if ($("#myModal").is(":visible")) {
                  document.getElementById("toggleModal").click();
                }
                /* eslint-disable */
                router.push({
                  name: "Login",
                });
              }, 5000);
            } else {
              document.getElementById("trigger").click();
              console.log(result.data);
              this.errorMessage = result.data.data;
              console.log(
                "🚀 ~ file: Register.vue:507 ~ this.errorMessage",
                this.errorMessage
              );
            }
            this.isRegistering = false;
          })
          .catch((e) => {
            document.getElementById("trigger").click();
            this.isRegistering = false;
          });
        /* eslint-disable */
      }
    },
    validEmail: function(email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
      /* eslint-disable */
    },
    isNumber: function(val) {
      let re = /^[0-9]*$/;
      return val.match(re);
    },
    checkForm: function() {
      if (!this.conditionAccept) {
        this.notValid.push("conditionAccept");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "conditionAccept"
        );
      }

      if (!this.kvkkAccept) {
        this.notValid.push("kvkkAccept");
      } else {
        this.notValid = this.notValid.filter((item) => item != "kvkkAccept");
      }

      if (!this.taxSheetData) {
        this.notValid.push("taxSheet");
      } else {
        this.notValid = this.notValid.filter((item) => item != "taxSheet");
      }

      if (this.name.length == 0) {
        this.notValid.push("name");
      } else {
        this.notValid = this.notValid.filter((item) => item != "name");
      }

      if (this.surname.length == 0) {
        this.notValid.push("surname");
      } else {
        this.notValid = this.notValid.filter((item) => item != "surname");
      }

      if (this.gsm.length < 7) {
        this.notValid.push("gsm");
      } else {
        this.notValid = this.notValid.filter((item) => item != "gsm");
      }

      // if (this.phone.length < 7) {
      //   this.notValid.push("phone");
      // } else {
      //   this.notValid = this.notValid.filter((item) => item != "phone");
      // }

      if (this.taxNo.length < 5) {
        this.notValid.push("taxNo");
      } else {
        this.notValid = this.notValid.filter((item) => item != "taxNo");
      }

      if (!this.validEmail(this.email)) {
        this.notValid.push("email");
      } else {
        this.notValid = this.notValid.filter((item) => item != "email");
      }

      if (this.address.length < 5) {
        this.notValid.push("address");
      } else {
        this.notValid = this.notValid.filter((item) => item != "address");
      }

      if (this.province.length == 0) {
        this.notValid.push("province");
      } else {
        this.notValid = this.notValid.filter((item) => item != "province");
      }

      if (this.city.length == 0) {
        this.notValid.push("city");
      } else {
        this.notValid = this.notValid.filter((item) => item != "city");
      }

      if (
        this.parentFirm.length == 0 &&
        this.checkParent == true &&
        this.isNewFirm == false
      ) {
        this.notValid.push("parentFirm");
      } else {
        this.notValid = this.notValid.filter((item) => item != "parentFirm");
      }
      // console.log(this.firm);
      if (!this.firm) {
        this.notValid.push("firm");
      } else {
        this.notValid = this.notValid.filter((item) => item != "firm");
      }

      if (this.district.length == 0) {
        this.notValid.push("district");
      } else {
        this.notValid = this.notValid.filter((item) => item != "district");
      }
    },
  },
  watch: {
    province: function(province) {
      HTTP.post("Districts/GetListByCityId", { cityId: this.province.id }).then(
        (result) => {
          this.district = "";
          this.districts = [];
          result.data.forEach((item) => {
            this.districts.push({ id: item.id, label: item.title });
          });
        }
      );
      if (this.province.length != 0) {
        this.notValid = this.notValid.filter((item) => item != "province");
      }
    },
    district: function() {
      if (this.district.length != 0) {
        this.notValid = this.notValid.filter((item) => item != "district");
      }
    },
    city: function() {
      this.province = this.city;

      HTTP.post("Login/GetFirmList", {
        cityId: this.city.id,
      }).then((result) => {
        // console.log(result.data.data.length);
        this.firms = [];
        this.firmsAllData = [];
        this.firm = "";
        if (result.data.data.length >= 1) {
          this.firms = [];
          this.firmsAllData = result.data.data;
          result.data.data.forEach((firm) => {
            this.firms.push({ id: firm.id, label: firm.firmName });
          });
        }
      });

      HTTP.post("Login/GetDealerList", {
        cityId: this.city.id,
      }).then((result) => {
        // console.log(result.data.data.length);
        this.parentFirms = [];
        this.firm = "";
        if (result.data.data.length >= 1) {
          this.parentFirms = [];

          this.parentFirms = result.data.data.map((firm) => {
            return { id: firm.id, label: firm.firmName };
          });
          // result.data.data.forEach((firm) => {
          //   this.parentFirms.push();
          // });
        }
      });
    },
    firm: function() {
      let firm = this.firmsAllData.filter((firm) => firm.id == this.firm.id)[0];
      if (
        firm.role.roleId == 1 ||
        firm.role.roleId == 2 ||
        firm.role.roleId == 3
      ) {
        this.checkParent = true;
      } else {
        this.isNewFirm = false;
        this.checkParent = false;
      }

      if (firm.id != 0) {
        this.isNewFirm = false;
      } else {
        this.checkParent = false;
      }
      this.parentFirm = "";

      console.log(firm);
    },
    name: function() {
      if (this.name.length > 0) {
        this.notValid = this.notValid.filter((item) => item != "name");
      }
    },
    surname: function() {
      if (this.surname.length > 0) {
        this.notValid = this.notValid.filter((item) => item != "surname");
      }
    },
    gsm: function(val) {
      if (this.gsm.length < 7) {
        this.notValid.push("gsm");
      } else {
        this.notValid = this.notValid.filter((item) => item != "gsm");
      }
    },
    // phone: function(val) {
    //   if (this.phone.length < 7) {
    //     this.notValid.push("phone");
    //   } else {
    //     this.notValid = this.notValid.filter((item) => item != "phone");
    //   }
    // },
    taxNo: function(val) {
      this.taxNo = this.taxNo.replace(" ", "");
      if (!this.isNumber(val)) {
        this.taxNo = this.taxNo;
        this.notValid.push("taxNo");
      } else {
        this.notValid = this.notValid.filter((item) => item != "taxNo");
      }
    },
    email: function() {
      if (!this.validEmail(this.email)) {
        this.notValid.push("email");
      } else {
        this.notValid = this.notValid.filter((item) => item != "email");
      }
    },
    address: function() {
      if (this.address.length > 5) {
        this.notValid = this.notValid.filter((item) => item != "address");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors";

.right-head {
  text-align: right;
}

.v-select {
  background: $input-grey;
  border: none !important;
  border-radius: 30px;
  padding: 7px 12px;

  .vs__dropdown-toggle {
    border: none;
  }

  .vs__dropdown-menu {
    margin-top: 10px;
    padding-top: 10px;
    border: 0;
    background: $input-grey;
    border-radius: 15px;
  }

  .vs__dropdown-option--highlight {
    background: $brand-color;
  }
}

.add-new {
  margin: 15px auto 10px auto;
  width: 98%;
  display: block;

  &:hover {
    transition: none;
    transform: none;
  }
}

.brand-select {
  position: relative;
  font-size: 18px;

  ::placeholder {
    color: #757575;
  }

  &::-webkit-input-placeholder {
    color: #757575;
  }

  &:-moz-placeholder {
    color: #757575;
  }

  &::-moz-p {
    color: #757575;
  }

  &:-ms-input-placeholder {
    color: #757575;
  }
}

.mgt-20 {
  margin-top: 50px;
}

.error {
  font-size: 14px;
}

.radio-btn-list {
  .error {
    display: none;
  }

  .error.show {
    display: block;
  }
}

.double-action {
  .brand-select input,
  .brand-select b,
  .brand-input {
    text-transform: capitalize !important;

    &::-webkit-input-placeholder {
      color: #757575;
    }

    &:-moz-placeholder {
      color: #757575;
    }

    &::-moz-p {
      color: #757575;
    }

    &:-ms-input-placeholder {
      color: #757575;
    }
  }

  .email {
    text-transform: lowercase !important;
  }
}

.newFirmInfo {
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-top: 5px;
  margin-left: 5px;
}
</style>
