<template>
  <div class="text-left">
    <p class="p1">
      <span class="s1">
        <b>Baymaklife Sadakat Sistemi Üyelik Koşulları</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >18 yaşından küçükler sisteme üye olamazlar. Bu durumun tespiti halinde
        ilgili kullanıcıların üyeliği sonlandırılacak ve varsa sistemden elde
        edilen tüm hakları iptal edilecektir. Sisteme girilen bilgilerin
        doğruluğu kullanıcıların sorumluluğundadır. Verilen yanlış bilgilerden
        dolayı oluşabilecek hata ya da hak kayıplarından Baymak Makina Sanayi ve
        Ticaret A.Ş. (Baymak) ve/veya Tutkal Bilişim Hizmetleri A.Ş. (Tutkal
        Bilişim) sorumlu tutulamaz. Baymaklife, aksi Baymak tarafından
        belirtilmediği sürece sadece Türkiye Cumhuriyeti sınırları içinde
        geçerlidir. Baymak’ın önceden haber vermeden kampanya şartlarını
        değiştirme ya da kampanyayı kısmen ya da tümü ile iptal etme hakları
        saklıdır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >Baymaklife üyelik başvurusu yapan alt bayi Baymak tarafından kontrol
        edilir. Üyelik değerlendirmesi Baymak tarafında yapıldıktan sonra üyelik
        aktif hale getirilir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >Baymaklife’ a kaydolan kullanıcılar www.baymaklife.com.tr adresindeki
        web sitesi/mobil uygulama ve bu portal üzerinden ulaştığı online hediye
        sitesinde açıklanan şartları kabul etmiş sayılırlar.</span
      >
    </p>
    <p class="p2">
      <span class="s1">
        <b>Nasıl Puan Kazanabilirim?</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >Baymaklife’da kazanmak çok kolaydır. Puan, BES veya hediyeler
        kazanabilmek için Baymak ürünlerindeki ürünlerindeki seri numarasını
        Baymaklife web portal üzerinden veya Baymaklife mobil uygulamadan
        yüklemeniz yeterli. İlk çalıştırması yapılan her seri numarasından uygun
        kampanyaya dahil olan kazanımı sağlayabilirsiniz.</span
      >
    </p>
    <p class="p2">
      <span class="s1">
        <b>Nasıl Hediye Seçebilirim?</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >Baymak’ın Baymaklife kampanyasına dahil ettiği ürünlerin üzerinde
        bulunan seri numaralarını web portal veya mobil uygulamadan girerek
        biriktirdiğiniz puanlarınız ile www.baymaklife.com.tr web sitesindeki ve
        mobil uygulamadaki hediye kataloğu üzerinden istediğiniz hediyeyi
        sipariş edebilirsiniz.</span
      >
    </p>
    <p class="p2">
      <span class="s1">HEDİYENİZİ NASIL ALACAKSINIZ?</span>
    </p>
    <p class="p2">
      <span class="s1"
        >Baymak tarafından onaylanan hediyeleriniz kargo ile belirttiğiniz
        adrese ulaştırılacaktır. Siparişinizin takibini www.baymak.com.tr sitesi
        ve mobil uygulama üzerindeki “Siparişlerim” bölümünden
        gerçekleştirilebilirsiniz. Hediyelere ait kargo bedelleri Baymak
        tarafından karşılanacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1">
        <b>Dikkat Edilecek Hususlar</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >1.Baymak tarafından hazırlanan “Baymaklife” kampanyası 01.07.2020 -
        31.12.2020 tarihleri arasında geçerli olup, Baymak önceden bildirim
        yapmadan ve herhangi bir neden göstermeden bu kampanyayı durdurabilir
        veya uzatabilir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >2.Hediye Kataloğunda yer alan ürünler stoklarla sınırlıdır. Seçtiğiniz
        hediyenin teknik nedenlerden dolayı sağlanamaması ya da stoklarının
        tükenmesi durumunda verilebiliyorsa muadil markadan eş değer ürün
        sunulacak veya www.baymak.com.tr portalindeki hediye kataloğu üzerinden
        yeni bir seçim yapma imkanı sağlanacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >3.Seçilen hediyeler Baymak tarafından temin edildikten sonra herhangi
        bir değişiklik yapılamaz. Seçtiğiniz hediyenin herhangi bir nedenle
        sağlanamaması durumunda, verilebiliyorsa muadil markadan eş değer ürün
        gönderilir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >4. Hediye ürün teslimatlarında; kargo teslim fişini imzalamadan önce
        ürünün kargo poşetinde yırtık, zarar vb. hasarlar olup olmadığını
        kontrol ediniz. Kargo poşeti hasarlı olan hediyelerinizi teslim almayıp,
        kargo görevlisine iade etmeniz gerekmektedir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >5. Şayet ürünü teslim almak istiyorsanız (4. Madde) zarar gören
        poşetlerin tarafınızdan teslim alınması tamamen alıcının
        sorumluluğundadır. Baymak, hasarlı kargo poşetlerinden ve/veya eksik
        içerikten sorumlu olmayacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >6.Ürünün kargo poşetini sorunsuz teslim aldıktan sonra kargo poşeti
        içindeki üründe eksiklik ve/veya yanlışlık tespit ederseniz aynı gün (24
        saat içinde) Baymaklife Danışma Hattı’ nı 0850 450 05 75 aramanızı rica
        ederiz. Aksi halde ürünle ilgili herhangi bir prosedür işlem
        uygulanamayacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >7.Yukarıda bildirilen yönlendirmelerden (4. -5 Ve 6. Madde) hiçbirisini
        yapmadıysanız ne yazık ki ürününüzle ilgili iade ve değişim
        yapılamayacak ve bu durumdan Baymak sorumlu tutulamayacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1">8.Hediye puanlar nakde çevrilemez.</span>
    </p>
    <p class="p2">
      <span class="s1"
        >9.Seçilen hediyelerin teslim adresleri Türkiye Cumhuriyeti sınırları
        içerisinde olmalıdır.</span
      >
    </p>
    <p class="p2">
      <span class="s1">10.Hediyelerin teslimat süresi 20 iş günüdür.</span>
    </p>
    <p class="p2">
      <span class="s1"
        >11.Her türlü anlaşmazlık halinde Baymak ve Tutkal Bilişim’ in kayıtları
        kesin delil olarak esas alınacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >12.Katalog baskısında oluşabilecek Tipografik ve baskı hatalarından
        kaynaklanan sorunlardan dolayı Baymak ve/veya Tutkal Bilişim sorumlu
        değildir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >13. www.baymaklife.com.tr web sitesi veya mobil uygulamadaki hediye
        kataloğundan hediye siparişi verilmesi, burada yer alan tüm kampanya
        şartlarının kabulü anlamına gelir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >14. Üyelerin, Baymaklife internet portalı üzerinde yapabilecekleri
        işlemler, üyelere bildirilmiş olup, üyelerin, yetkilendirilmedikleri
        işlemlere ait modüllerin, Baymak internet portalında sehven açık kalması
        ve Üye tarafından işlem şifresi ile işlem yapılabilir halde olması
        durumunda, Üye bu işlemleri yapmaktan kaçınacak ve durumu derhal Baymak
        yetkililerine bildirecektir. Bu bildirim yapılmadan, yetkisi olmadığı
        halde Üye tarafından işlem yapılması halinde, bu işlemle ilgili Baymak’a
        yüklenebilecek her türlü maddi ve manevi tazminat tutarların ve
        zararların Üye tarafından karşılanacağını, Üye gayri kabili rücu olarak
        kabul ve taahhüt etmiştir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >15. Baymaklife internet portalında sunulan tüm içerik, görüş ve
        bilgilerin doğruluğu, eksiksiz ve değişmez olduğu Baymak tarafından
        garanti edilmemektedir. Bu nedenle, Üyelerin; internet portalı üzerinden
        elde edilen içerik, görüş ve bilgilere dayanarak hareket etmeden önce
        içerik, görüş ve bilgilerin doğruluğunu kontrol ettirmeleri ve bu
        içerik, görüş ve bilgilere dayanarak alacakları kararları bu kapsamda
        vermeleri önerilir. İçerik, görüş ve bilgilerin yanlışlık veya
        eksikliğinden Baymak hiçbir şekilde sorumlu bulunmamaktadır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >16.Baymak’a ait yazılı ticari kayıtları ile gerek Üye ticari kayıtları
        ve/veya internet portalı kayıtları arasında farklılıklar olması
        durumunda, uyuşmazlığın hallinde Baymak yazılı ticari kayıtlarının esas
        alınacağı, taraflarca gayri kabili rücu olarak kabul ve taahhüt
        edilmiştir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >17. Baymak internet portalında, sehven yayınlanan bir bilgi veya
        belgenin Üye lehine delil teşkil etmesi söz konusu değildir. Taraflar
        arasında doğacak uyuşmazlıklar hallinde, Baymak’a ait ticari defter ve
        kayıtlarının kesin delil teşkil edeceğini taraflar gayri kabili rücu
        olarak kabul etmiştir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >18. Üye, Baymak’ın kendilerine sunmuş olduğu bu imkânı, iyi niyetli ve
        basiretli bir tacirden beklenecek özen ve gayret içinde kullanacaktır.
        Üye yalnızca kendisine ait ürüne ilişkin seri numaralarını sisteme
        girecektir. Bu kapsamda üye usulsüz giriş ve kullanımlardan
        kaçınacaktır. Üye’nin usulsüz giriş ve kullanımlarından dolayı,
        Baymak’ın ve/veya 3. Şahısların uğrayacağı her türlü zarar ve ziyandan
        Üye sorumlu olacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >Üye’ nin usulsüz kullanımlarından dolayı, Baymak tarafından ödenmesi
        gereken her türlü maddi ve manevi tazminat ve zarar bedelleri, Üye’ nin
        rızası aranmaksızın öncelikli olarak, Baymak nezdindeki her türlü
        alacağından mahsup edilecektir. Bu alacaklarının yeterli gelmemesi
        halinde, Baymak kalan tutar için Üye aleyhine her türlü kanuni takip
        işlemi yapmaya muhtar olacaktır. Üye tarafından bu kanuni takip
        işlemlerine karşı itiraz edilmeyeceği kabul ve taahhüt edilmiştir.</span
      >
    </p>
    <p class="p2">
      <span class="s1">
        <b>Gizlilik</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >Üye, Baymak ile ilgili gizlilik kurallarına ve ticari itibar ve
        unvanının korunmasına ve korumaya hassasiyetle riayet edecektir. Her ne
        suretle olursa olsun istem dışı olarak dahi vakıf olduğu veya kendisine
        Baymak tarafından verilen bilgileri başka bir Üyeye, üçüncü şahıslara ve
        ilgisi olmayan kişilere sözlü, yazılı veya herhangi bir başka biçimde
        aktarmayacaktır. Bu maddedeki yükümlülüğe aykırılık halinde, Baymak’ ın
        Üye’ ye ve sorumlu şahsa karşı gizlilik ve sadakat borçlarına aykırılık
        nedeniyle her türlü dava ve talep hakkı mevcuttur.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >Baymak’ ın hizmetleri ile ilgili ürünler, aktiviteler, organizasyonlar,
        eğitimler ve kampanyalar hakkında link ve yönlendirmeleri, tanıtımları,
        haberleri veya reklamları da içerebilen şekilde beni bilgilendirmesi
        için Baymak’ın tüm bilgilerimi (telefon numaraları, faks, posta ve
        e-posta adreslerimi) bana ulaşmak için kullanmasına, yasal düzenleme
        kapsamında ticari iletişim ve ticari elektronik ileti (sms, e-mail v.s.)
        göndermesine izin/onay veriyorum.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >Baymak’a, işbu form ile beyan etmiş olduğum kişisel verilerimin
        (“Kişisel Veri/ler”) doğru olduğunu ve Kişisel Verilerin Korunması
        Hakkındaki Kanun’da belirlenmiş olan esaslar çerçevesinde işlenmesine,
        3. kişilere aktarılmasına açıkça rıza gösterdiğimi kabul ve beyan
        ederim. Kişisel verilerin işlenmesi ve 3. kişilere aktarılması, Baymak
        ile aramızda kurulan iş ilişkisine dayanmaktadır. Kişisel Verilerimin,
        Baymak ve iştirakleri tarafından işbu iş ilişkisi kapsamında, sözleşme
        ilişkisi bulunan iş ortakları tarafından karşılıklı yükümlülükler,
        analiz, istatistik gibi amaçlarla işlenebileceğini, 3. kişilere
        aktarılarak veya anonimleştirilerek kullanılabilir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >Madde 24- Tüm bu koşullar Üye’nin ayrıca imzasına gerek olmaksızın
        üyelik için başvuruda bulunması, sistemden kayıtlı olarak fiili
        kullanımla yararlanıyor olması, ya da hediye talep etmesi ve/veya
        gönderilen hediyeyi teslim alması ile işbu hükümlere tabidir ve hükümler
        hukuken geçerli olur.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >İşbu koşulların uygulanmasından doğabilecek her türlü uyuşmazlıkların
        çözümünde İstanbul Mahkemeleri ve İcra Müdürlükleri yetkili
        olacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1">
        <b>Tatil Katılım Şartları</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >- Tatil kampanyalarında, Umre, yurtdışı ve yurtiçi seyahatlerin katılım
        koşulları, kampanya tarihlerine ve katılımın gerçekleşeceği lokasyona
        göre değişiklik göstermektedir. Baymak tarafından tatil katılım
        şartları, kampanya süreleri içinde Baymaklife web sitesi kampanyalar
        alanında detaylandırılacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >- Tatil hak edişi kazanmış olan üyelerin, tatile katılamaması durumunda
        hakları yeniden kullanıma açık olmayacaktır.</span
      >
    </p>
    <p class="p2">
      <span class="s1"></span>
    </p>
    <p class="p2">
      <span class="s1">
        <b>Bilgilerim Nasıl Kullanılacak?</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >“Baymaklife” a kayıt için verilen bilgiler, sisteme kayıt, BES veya
        puan kazanımı ve puanların hediyeye dönüştürülmesi gibi sistem içi
        işlemlerde kullanılacaktır. Bunların dışındaki kullanım aşağıdaki
        şartlara tabidir. Üyelerin bilgileri, Tutkal Bilişim tarafından veri
        tabanında saklanacak ve 3. kişilerle üyelerin isteği dışında, aşağıdaki
        şartlar haricinde paylaşılmayacaktır. Sisteme kayıt olan kişi ve
        firmalar bu şartları kabul etmiş sayılırlar.</span
      >
    </p>
    <p class="p2">
      <span class="s1">
        • Baymak Makina Sanayi ve Ticaret A.Ş.
        <span class="Apple-converted-space"></span>ve bünyesinde barındırdığı
        tüm diğer şirketler, üyelerin bilgilerini bu üyelere tanıtım yapmak
        amaçlı kullanabilir.
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >• Tutkal Bilişim Hizmetleri A.Ş., “Baymaklife” kulübünü işletebilmek
        (web sitesi yapımı, üye kaydı, BES veya puan kazanımı vb.) için birlikte
        çalıştığı 3. firmalara üye bilgilerini vermek zorunda kalabilir. Ancak,
        bu firmalar üyelerin bilgilerini Baymak Makina Sanayi ve Ticaret A.Ş.’
        den ve üyelerin kendilerinden izin almadan “Baymaklife” dışında hiçbir
        yerde kullanamaz, “Baymaklife” a dâhil hiçbir yerde diğer kişi ve
        firmaların görebileceği ve kullanabileceği şekilde paylaşamazlar.
        “Baymaklife” data havuzunda tutulan bilgiler diğer üyelere
        gösterilmez.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >• “Baymaklife” üyelerinin bilgileri, üyelerin bireysel bilgileri
        açıklanmadan istatistiki veri üretmek ve bunları 3. kişi ve firmalarla
        paylaşmak amacı ile kullanılabilir.</span
      >
    </p>
    <p class="p2">
      <span class="s1"
        >• Üye, üye olması esnasında verdiği bilgilerin gerek Baymak, gerekse
        Baymak tarafından belirlenen üçüncü kişiler tarafından posta, e-posta,
        sms vb. yolu ile gönderilebilecek aktivitelere davet ve kampanya
        duyuruları için kullanılmak üzere paylaştığını kabul ve beyan
        eder.</span
      >
    </p>
  </div>
</template>
<script>
export default {
  name: "SafetyPrivacy"
};
</script>
