<template>
  <div>
    <span class="info">
      GEÇİCİ ŞİFRENİZİ DEĞİŞTİRMEK VE SİSTEME GİRİŞ
      <br />YAPABİLMEK İÇİN YENİ ŞİFRE OLUŞTURUN
    </span>

    <div class="form-group">
      <input
        type="password"
        name="oldpassword"
        class="brand-input"
        v-model="data.oldPassword"
        placeholder="MEVCUT ŞİFRE"
      />
      <span class="error" :class="{ show: notValid.includes('oldPassword') }"
        >Lütfen eski şifrenizi giriniz.</span
      >
    </div>
    <div class="form-group">
      <input
        type="password"
        class="brand-input"
        v-model="data.newPassword"
        placeholder="YENİ ŞİFRE"
      />
      <span class="error" :class="{ show: notValid.includes('newPassword') }"
        >Lütfen yeni şifrenizi giriniz.</span
      >
      <span
        class="error"
        :class="{ show: notValid.includes('newPasswordContains') }"
      >
        Unutmayın, üyelik şifreniz en az 6 haneli olmalıdır. Rakamların yanı
        sıra mutlaka bir harf kullanmalısınız.
      </span>
    </div>
    <div class="form-group">
      <input
        type="password"
        class="brand-input"
        v-model="data.newPasswordConfirm"
        placeholder="YENİ ŞİFRE TEKRAR"
      />
      <span
        class="error"
        :class="{ show: notValid.includes('newPasswordConfirm') }"
        >Lütfen yeni şifrenizi giriniz.</span
      >

      <span
        class="error"
        :class="{ show: notValid.includes('newPasswordContains') }"
      >
        Unutmayın, üyelik şifreniz en az 6 haneli olmalıdır. Rakamların yanı
        sıra mutlaka bir harf kullanmalısınız.
      </span>
    </div>
    <span class="error" :class="{ show: notValid.includes('notmatch') }"
      >Girdiğiniz şifreler eşleşmiyor.</span
    >
    <button type="button" class="brand-button" @click="update">
      KAYDET
      <Loading v-if="onProcess"></Loading>
    </button>
    <!-- Modal -->
    <span
      class="hide"
      id="toggleModal"
      data-toggle="modal"
      data-target="#myModal"
    ></span>
    <div class="modal fade" id="myModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">{{ resultMessage }}</div>
      </div>
    </div>
    <!-- Modal End -->
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import Loading from "@/components/loading";
import router from "@/router";

export default {
  name: "Change-Password",
  data() {
    return {
      data: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: ""
      },
      notValid: [],
      onProcess: false,
      resultMessage: ""
    };
  },
  components: {
    Loading
  },
  methods: {
    update: function() {
      this.onProcess = true;
      this.checkForm();
      if (this.notValid.length == 0) {
        HTTP.post("User/ChangePassword", this.data).then(result => {
          if (result.data.success == false) {
            this.resultMessage = result.data.data;
          }

          document.getElementById("toggleModal").click();

          if (result.data.success == true) {
            this.resultMessage = "Şifreniz başarıyla güncellendi!";
            setTimeout(() => {
              /* eslint-disable */
              if ($("#myModal").is(":visible")) {
                document.getElementById("toggleModal").click();
              }
              /* eslint-disable */
              setTimeout(() => {
                router.push({
                  name: "Home"
                });
              }, 500);
            }, 2000);
          }

          this.onProcess = false;
        });
      } else {
        this.onProcess = false;
      }
    },
    checkForm: function() {
      let regEx = /^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{6,20}$/;

      if (!regEx.test(this.data.newPassword)) {
        this.notValid.push("newPasswordContains");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "newPasswordContains"
        );
      }

      if (this.data.oldPassword.length < 4) {
        this.notValid.push("oldPassword");
      } else {
        this.notValid = this.notValid.filter(item => item != "oldPassword");
      }
      if (this.data.newPassword.length < 6) {
        this.notValid.push("newPassword");
      } else {
        this.notValid = this.notValid.filter(item => item != "newPassword");
      }

      if (this.data.newPasswordConfirm.length < 6) {
        this.notValid.push("newPasswordConfirm");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "newPasswordConfirm"
        );
      }
      if (this.data.newPassword != this.data.newPasswordConfirm) {
        this.notValid.push("notmatch");
      } else {
        this.notValid = this.notValid.filter(item => item != "notmatch");
      }
    }
  }
};
</script>
<style lang="scss"></style>
