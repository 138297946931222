<template>
  <div>
    <div class="gutter"></div>
    <Slider></Slider>

    <div class="container main">
      <div id="sub-dealer" class="sub-dealer">
        <sub-dealer v-if="userRoleId != 11"></sub-dealer>
      </div>
      <main-dealer v-if="userRoleId == 11"></main-dealer>
    </div>
    <div class="container main-campaigns">
      <span class="h3" v-if="userRoleId != 11">TAMAMLANAN KAMPANYALAR</span>
      <span class="h3" v-if="userRoleId == 11"
        >ALT FİRMALAR TAMAMLANAN KAMPANYALAR</span
      >
      İncelediğiniz kampanyalar sona ermiştir.
      <br />Devam kampanyalar için "Aktif Kampanyalar" sayfasını ziyaret
      edebilirsiniz.
      <CompletedCampaigns :completeds="true"></CompletedCampaigns>
    </div>
  </div>
</template>

<script>
import Slider from "@/components/Slider";
import mainDealer from "@/components/home/Main-Dealer-Nav";
import subDealer from "@/components/home/Sub-Dealer-Nav";
import CompletedCampaigns from "@/components/campaigns/List";

export default {
  name: "Completeds",
  components: {
    Slider,
    mainDealer,
    subDealer,
    CompletedCampaigns
  },
  created() {
    document.body.classList.remove("bg-1");
  },
  computed: {
    userRoleId: function() {
      return sessionStorage.userRoleId;
    }
  },
  mounted() {
    setTimeout(() => {
      const el = document.getElementById("sub-dealer");
      const elPosition = el.offsetTop - 100;
      window.scrollTo(0, elPosition);
    }, 400);
  }
};
</script>
<style lang="scss">
.slide-nav {
  .left {
    float: left;
  }
  .right {
    float: right;
    margin-right: 20px;
  }
}
</style>
