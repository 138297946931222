<template>
  <!-- Tab content start -->
  <div class="panel-group" id="faqAccordion">
    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question1"
      >
        Baymaklife nedir?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question1" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            “Baymaklife” Baymak Makina Sanayi ve Ticaret A.Ş. bünyesinde bulunan
            ürünlerin satışını gerçekleştirip ilk çalıştırmasını sağlayan
            bayilerine özel hazırladığımız bir ayrıcalıklar dünyasıdır.
          </p>
        </div>
      </div>
    </div>
    <!-- question & answer -->

    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question2"
      >
        Neden üye olmalıyım?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question2" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            -Baymak ürünlerinin üzerindeki seri numaraları Baymaklife web
            portalından girerek puan veya BES kazanabilirsiniz. -Baymak
            ürünlerine özel yeniliklerden ve kampanyalardan ilk sizler haberdar
            olursunuz. -Gerçekleşecek Baymaklife ara dönem kampanyalarına
            katılabilir ve puan, BES veya hediyeler kazanabilirsiniz.
            -Baymaklife mobil uygulamasını kullanarak da kampanyalara
            katılabilir ve kazanımlar sağlayabilirsiniz. -Hesabınızda biriken
            puanlarınızla, hediye kataloğundaki hediyeleri alabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <!-- question & answer -->

    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question3"
      >
        Üyelik süreci nasıl işliyor?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question3" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Üyelik için öncelikle Baymak bayi statüsünde olmanız ve web sitesi
            ya da mobil uygulama üzerinden üye kayıt formunu doldurmanız
            gerekmektedir. Üye kayıt formunuz tarafımıza ulaştıktan sonra Baymak
            tarafından incelenecektir. Başvurunuz onaylandığı taktirde
            üyeliğiniz aktif hale getirilecektir.
          </p>
        </div>
      </div>
    </div>
    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question4"
      >
        Baymaklife’a üye kaydı yaptım fakat cep telefonuma kullanıcı bilgilerim
        gelmedi. Sistemi nasıl kullanacağım?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question4" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Kullanıcı bilgileriniz SMS ile tarafınıza ulaşmadıysa üyeliğiniz
            hala onaylanmamış olabilir. Üyeliğiniz onaylanmış olmasına rağmen
            SMS almadıysanız sisteme kaydettiğiniz cep telefonunuzu kontrol
            etmeniz gerekmektedir. Üyelik kayıt bilgilerinizin kontrolü için
            Baymaklife web portal üzerinden “İletişim Formu” doldurabilir veya
            Baymaklife Danışma Hattı’nı (0850 450 05 75) arayabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question5"
      >
        Kullanıcı adımı unuttum. Sisteme nasıl giriş yapabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question5" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Kullanıcı adınız sisteme kayıt gerçekleştirirken bildirdiğiniz cep
            telefonunuzdur. Unutmayın! Baymaklife ile ilgili tüm bilgiler
            sistemde kayıtlı cep telefonunuza gönderilecektir. Bu sebeple
            kayıtlı cep telefonunuzun size ait olması ve doğru olarak sisteme
            girilmesi çok önemlidir.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question6"
      >
        Şifremi unuttum. Sisteme nasıl giriş yapabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question6" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Şifremi unuttum butonunu tıklayarak cep telefonu numaranız ile yeni
            şifre talebinde bulunabilirsiniz. Cep telefonunuza SMS olarak gelen
            doğrulama kodu ile yeni şifrenizi oluşturabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question7"
      >
        Kullanıcı bilgilerimi değiştirmek istiyorum. Nasıl yapabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question7" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Üyelik bilgilerinizi sadece Baymaklife web portalde bulunan
            “İletişim Formu”nu doldurarak veya Baymaklife Danışma Hattı’nı (0850
            450 05 75) arayarak değişiklik talebinizi iletebilirsiniz. Baymak
            tarafından kontrol edilerek değişiklik işlemi yapılacaktır.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question8"
      >
        Baymak Ürünlerinden Nasıl Kazanım Sağlayabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question8" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Baymaklife’da kazanmak çok kolaydır. Puan, BES veya hediyeler
            kazanabilmek için Baymak ürünlerindeki seri numarasını Baymaklife
            web portal üzerinden veya Baymaklife mobil uygulamadan yüklemeniz
            yeterli. İlk çalıştırması yapılan her seri numarasından uygun
            kampanyaya dahil olan kazanımı sağlayabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question9"
      >
        Ana sayfamda gözüken Facebook, Instagram ve Twitter ikonlarına
        tıkladığımda neden görüntüleyemiyorum?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question9" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            İnternete girdiğiniz bağlantı ayarlarında facebook, instagram ve
            twitter gibi sosyal paylaşım ağları özellikle engellenmiş olabilir.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question10"
      >
        Diğer üyeler bana mesaj gönderebilir mi?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question10" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Diğer üyeler size mesaj gönderemez. Mesajlar sadece Baymak
            tarafından duyuru ve kampanyalar için sizleri bilgilendirmek amaçlı
            gönderilmektedir.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question11"
      >
        Baymaklife web sitesini en iyi şekilde hangi web tarayıcılarda
        görüntüleyebilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question11" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Baymaklife web sitesini en güncel olarak Chrome, Explorer edge ve
            Firefox web tarayıcılarının son sürümlerinde kullanmanızı öneririz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question12"
      >
        Baymaklife mobil uygulamasını hangi Apple cihazlar ve sürümler üzerinde
        kullanabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question12" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            IOS 10 ve üzeri sürümlerin yüklenebildiği tüm Apple telefonlarda
            Baymaklife uygulaması indirilebilir. Baymaklife Hediye Sitesi SSS
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question13"
      >
        Hediye Siparişi vermek istiyorum. Nasıl sipariş verebilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question13" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            1. Adım; Hediye sitesine giriş yaptıktan sonra ürünler içerisinde
            beğendiğiniz/talep ettiğiniz hediyenin üzerine tıklayarak ürün detay
            sayfasına ulaşabilirsiniz. Açılan sayfada ürün görselleri ve
            detayları görüntülenmektedir. Ürün görselini büyütmek için ürün
            resminin üzerine tıklamanız yeterlidir. Ürün detay sayfasında ürünü
            inceleyip beğendiğiniz takdirde “Sepete Ekle” butonuna basarak
            alışverişe başlayabilirsiniz. 2. Adım Karşınıza çıkan yeni sayfada
            sepetinizde bulunan ürünleri ve harcayacağınız toplam “PUAN”
            miktarını görebileceksiniz. Eğer başka bir sipariş eklemek için
            devam etmek isterseniz “Alışverişe Devam Et” butonuna tıklayarak
            seçmek istediğiniz ürüne ait yeni seçiminizi sepetinize
            ekleyebilirsiniz. “Sepetim” sayfasında siparişini vereceğiniz tüm
            hediye ürünlerinizi ve harcayacağınız toplam “PUAN” miktarınızı
            görebilecek ve “Sipariş Ver” butonu ile “Teslimat Adresi” adımına
            geçeceksiniz. 3. Adım Adres bilgilerinizin yer aldığı “Teslimat
            Adresi” ekranında, ister mevcut adreslerinizden birini seçerek,
            isterseniz yeni teslimat bilgilerinizi girdikten sonra gönderimin
            yapılmasını istediğiniz adresi işaretledikten sonra “Adrese Gönder”
            butonuna tıklayarak işleminize devam edecek ve “Sipariş Onay”
            sayfasına yönlendirileceksiniz. 4. Adım “Sipariş Onay” ekranında
            karşınıza gelen bilgiler üyelik bilgileriniz, seçtiğiniz ürün ve
            teslimat bilgileri ile Uygulama ve Koşullar dokümanı olacaktır.
            Bilgilerinizin doğruluğunu kontrol ederek, Uygulama ve Koşullar
            dokümanını onayladıktan sonra “Siparişi Onayla” butonuna basarak
            siparişinizi tamamlayabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question14"
      >
        Hediye siparişi sırasında “PUAN” larımın tümünü aynı anda kullanabilir
        miyim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question14" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Hesabınızda görüntülenen “PUAN” larınızı istediğiniz zaman
            kullanabilir ve istediğiniz kadarını harcayabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question15"
      >
        Sepetime eklediğim ürünleri silebilir miyim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question15" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Sepetinize eklediğiniz ürünlerinizi ürün görselinin solunda bulunan
            kutucuğu işaretledikten sonra “Sil” butonuna tıklayarak
            silebilirsiniz. Ayrıca yine sepetinizdeki ürünleri seçtikten sonra
            “Sepeti güncelle” butonunu kullanarak ürünlerinizin güncel “PUAN”
            durumlarını da görüntüleyebilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question16"
      >
        Hediye Siparişimi verdikten sonra durumunu nasıl takip ederim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question16" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Hediye sitesindeki “Siparişlerim” bölümünden tüm sipariş geçmişinizi
            ve siparişlerinizin durumunu görebilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question17"
      >
        Sipariş ettiğim ürün gecikti. Ne yapabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question17" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Siparişini verdiğiniz hediye ürün/ürünlerin takibini “Siparişlerim”
            bölümünden yapabilirsiniz. Tedarik süresini aşan siparişlerinizin
            tedarik durumu öğrenmek için Baymaklife Danışma Hattını (0850 450 05
            75) arayabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question18"
      >
        İsteklerimle ilgili danışma hattını aramadan daha kolay bir şekilde
        Baymaklife operasyon ekibine ulaşabilir miyim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question18" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Baymaklife portal ve hediye sitesinde bulunan “Canlı Destek
            Penceresi” nden operasyon ekibimize ulaşabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question19"
      >
        Sipariş ettiğim hediyemi iade ya da iptal ettirmek istiyorum. “PUAN”
        iadem nasıl yapılacak?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question19" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Sipariş etmiş olduğunuz ürün ‘Siparişiniz İşleme Alınmıştır’
            statüsünde ise iptal edilebilmektedir. İptal taleplerinizi
            Baymaklife Danışma Hattı’nı (0850 450 05 75) arayarak
            iletebilirsiniz. “PUAN” ınız iptal işleminin ardından hesabınıza
            işlenecektir. “Siparişlerim” bölümünden tüm siparişlerinizin
            durumunu öğrenebilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question20"
      >
        Hediyemin bana ulaşmadan sevkiyatı sırasında hasar gördüğünü fark ettim.
        Ne yapabilirim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question20" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Hediye Kataloğunda yer alan ürünler stoklarla sınırlıdır. Seçtiğiniz
            hediyenin teknik nedenlerden dolayı sağlanamaması ya da stoklarının
            tükenmesi durumunda verilebiliyorsa muadil markadan eş değer ürün
            sunulacak veya www.baymak.com.tr portalindeki hediye kataloğu
            üzerinden yeni bir seçim yapma imkanı sağlanacaktır. Seçilen
            hediyeler Baymak tarafından temin edildikten sonra herhangi bir
            değişiklik yapılamaz. Seçtiğiniz hediyenin herhangi bir nedenle
            sağlanamaması durumunda, verilebiliyorsa muadil markadan eş değer
            ürün gönderilir. Hediye ürün teslimatlarında; kargo teslim fişini
            imzalamadan önce ürünün kargo poşetinde yırtık, zarar vb. hasarlar
            olup olmadığını kontrol ediniz. Kargo poşeti hasarlı olan
            hediyelerinizi teslim almayıp, kargo görevlisine iade etmeniz
            gerekmektedir. Şayet ürünü teslim almak istiyorsanız zarar gören
            poşetlerin tarafınızdan teslim alınması tamamen alıcının
            sorumluluğundadır. Baymak, hasarlı kargo poşetlerinden ve/veya eksik
            içerikten sorumlu olmayacaktır. Ürünün kargo poşetini sorunsuz
            teslim aldıktan sonra kargo poşeti içindeki üründe eksiklik ve/veya
            yanlışlık tespit ederseniz aynı gün (24 saat içinde) Baymaklife
            Danışma Hattı’ nı 0 (850) 450 05 75 aramanızı rica ederiz. Aksi
            halde ürünle ilgili herhangi bir prosedür işlem uygulanamayacaktır.
            Yukarıda bildirilen yönlendirmelerden hiçbirisini yapmadıysanız ne
            yazık ki ürününüzle ilgili iade ve değişim yapılamayacak ve bu
            durumdan Baymak sorumlu tutulamayacaktır.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question21"
      >
        Hangi ürünleri iade edememekteyim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question21" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>Hediye çeklerinde iade kabul edilmemektedir.</p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question22"
      >
        Ne kadar kargo ücreti ödeyeceğim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question22" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Hediye siparişlerinizin kargo ücreti Baymak tarafından
            karşılandığından bir ödeme yapmanız gerekmemektedir.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question23"
      >
        Kargo takibimi veya teslimat bilgilerimi nereden öğreneceğim?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question23" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Siparişini verdiğiniz ürünler kargo aracılığı ile sipariş esnasında
            seçtiğiniz teslimat adresinize gönderilecektir. Üye girişinize
            müteakip hediye sitesinde bulunan “Siparişlerim” linkine tıkladıktan
            sonra kargoya verilen siparişlerinizin durumunu “Kargom Nerede”
            butonuna basarak takip edebilirsiniz. Ayrıca kargo numarası SMS ile
            tarafınıza gönderilecektir.
          </p>
        </div>
      </div>
    </div>
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question24"
      >
        Biriken puanlarım yıl sonunda silinecek mi?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question24" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Bir takvim yılı sonunda kazanılmış puanlar geçerliliğini
            yitirecektir. Örneğin 1 Ocak 2021 tarihinde 2020 yılı içerisinde
            kazanarak kullanmadığınız puanlarınız var ise bu puanlar
            geçerliliğini kaybedecektir.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- content end -->
</template>

<script>
export default {
  name: "FaqAuth"
};
</script>
