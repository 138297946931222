<template>
  <div class="container-fluid visible-page double-action">
    <div class="container">
      <div class="col-md-11 col-md-offset-1">
        <!-- Right -->
        <div class="col-md-6 right-pad col-md-push-6">
          <span class="h3 brand-color title-1 right-head">ŞİFREMİ UNUTTUM</span>
          <img
            src="@/assets/images/maskot/forgot-n1.png"
            alt="Baymak"
            class="img-responsive centered-mascot"
            v-if="step == 1"
          />
          <img
            src="@/assets/images/maskot/forgot1.png"
            alt="Baymak"
            class="img-responsive centered-mascot"
            v-if="step == 2"
          />
        </div>
        <!-- Right End -->
        <!-- Left -->
        <div class="col-md-6 left-action col-md-pull-6">
          <sendCode v-show="step == 1" @sendCodeSuccess="step = 2"></sendCode>
          <changePassword v-show="step == 2"></changePassword>
        </div>
        <!-- Left End -->
      </div>
    </div>
  </div>
</template>
<script>
import sendCode from "@/components/forgotPassword/Send-Code";
import changePassword from "@/components/forgotPassword/Change-Password";

export default {
  name: "Forgot",
  data() {
    return {
      step: 1
    };
  },
  components: {
    sendCode,
    changePassword
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/colors";
.error {
  margin: 10px 0px;
  color: $red;
  display: block;
}
.right-head {
  text-align: right;
}
.info {
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
}
</style>
