<template>
  <div>
    <div class="gutter"></div>
    <div class="inner-page-top-bg faq">
      <div>
        <h1 class="h1">SIKÇA SORULAN SORULAR</h1>
        <p>
          Merak ettiğiniz soruların yanıtlarını,
          <br />aşağıdaki soru başlıklarına tıklayarak görüntüleyebilirsiniz.
        </p>
        <img src="@/assets/images/arrow-down.png" alt />
      </div>
    </div>

    <div class="container page-negative negative-2 faq">
      <div class="col-md-5 col-xs-12">
        <span class="h1">Sıkça Sorulan Sorular</span>
      </div>
      <div class="col-md-6 col-xs-12">
        <!-- content start -->
        <FaqGuest v-if="!isLoggedIn"></FaqGuest>
        <FaqAuth v-if="isLoggedIn"></FaqAuth>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FaqGuest from "@/components/static/Faq";
import FaqAuth from "@/components/static/Faq-Auth";

export default {
  name: "Faq",
  data() {
    return {};
  },
  components: {
    FaqGuest,
    FaqAuth
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  }
};
</script>
