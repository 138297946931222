<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <h1 class="h1">Baymaklife Nedir?</h1>
      <div class="col-md-11 col-md-offset-1 content col-xs-12">
        <div class="col-md-12">
          <vue-plyr class="video">
            <video
              :src="
                'https://baymak.tutkal.com.tr/upload/Baymak_Life_Final_Rev.mp4'
              "
              poster="@/assets/images/baymaklife-nedir.jpg"
            >
              <source
                :src="
                  'https://baymak.tutkal.com.tr/upload/Baymak_Life_Final_Rev.mp4'
                "
                type="video/mp4"
              />
            </video>
          </vue-plyr>
        </div>
        <div class="col-md-12 mg-t-10">
          <b>Baymaklife nedir?</b>
          <br />“Baymaklife” Baymak Makina Sanayi ve Ticaret A.Ş. bünyesinde
          bulunan ürünlerin satışını gerçekleştirip ilk çalıştırmasını sağlayan
          bayilerine özel hazırladığımız bir ayrıcalıklar dünyasıdır.
          <br />
          <br />
          <b>Neden üye olmalıyım?</b>
          <br />-Baymak ürünlerinin üzerindeki seri numaraları Baymaklife web
          portalından girerek puan veya BES kazanabilirsiniz. <br />-Baymak
          ürünlerine özel yeniliklerden ve kampanyalardan ilk sizler haberdar
          olursunuz. <br />-Gerçekleşecek Baymaklife ara dönem kampanyalarına
          katılabilir ve puan, BES veya hediyeler kazanabilirsiniz.
          <br />-Baymaklife mobil uygulamasını kullanarak da kampanyalara
          katılabilir ve kazanımlar sağlayabilirsiniz. <br />-Hesabınızda
          biriken puanlarınızla, hediye kataloğundaki hediyeleri alabilirsiniz.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "whatis",
  data() {
    return {};
  }
};
/* eslint-disable */
</script>
<style lang="scss">
.mg-t-10 {
  margin-top: 10px;
}
</style>
