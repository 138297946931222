<template>
  <div class="wrap">
    <div class="inner">
      <div class="col-md-8 col-md-offset-2 col-xs-12">
        <div class="row">
          <div class="col-md-8 pd0">
            <div class="col-md-2 col-xs-12">
              <img src="@/assets/images/gift-box.png" alt="Hedie Kataloğu" />
            </div>
            <div class="col-md-10 head">
              <span class="h3">HEDİYE KATALOĞU</span>
            </div>
          </div>
          <div class="col-md-4 text-center">
            <form
              action="https://baymak-hediye.tutkal.com.tr/customLogin.aspx"
              id="myForm"
              method="POST"
              target="_blank"
            >
              <input type="hidden" name="Token" :value="token" />
              <input type="hidden" name="url" value="/" />
            </form>
            <button class="brand-button" @click="catalogLogin">GİRİŞ</button>
          </div>
        </div>
        <small>
          Hediye kataloğuna tıklayarak sizler için oluşturulmuş hediye dünyasına
          ulaşabilirsiniz.
        </small>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Catalog",
  computed: {
    token: function() {
      return sessionStorage.token;
    }
  },
  methods: {
    catalogLogin: function() {
      document.getElementById("myForm").submit();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors";
@import "@/assets/scss/mixins";
.wrap {
  @include brand-bg;
  border-radius: 5px;
  padding: 5px 1px 1px;
}
.inner {
  background: $white;
  border-radius: 5px;
  padding: 10px;
}
.h3 {
  font-weight: 800;
  font-size: 28px;
}
.head {
  padding-top: 25px;
  text-align: left;
}
.brand-button {
  margin-top: 25px;
  margin-left: 20px;
  display: inline-block;
  padding: 7px 40px;
  font-size: 22px;
}
small {
  font-size: 11px;
}
@media (max-width: 767.98px) {
  .inner {
    text-align: center;
  }
  .brand-button {
    margin-left: 0px;
  }
}
</style>
