var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"inner-page-top-bg"}),_c('div',{staticClass:"container page-negative"},[_c('h1',{staticClass:"h1"},[_vm._v("BES HESAPLARIM")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"col-md-10 col-md-offset-1 col-xs-12"},[_c('div',{staticClass:"sub-navigation"},[_c('button',{staticClass:"brand-button-outline",class:{ 'btn-active': _vm.selectedComponent == 'createBesAccount' },on:{"click":function($event){_vm.selectedComponent = 'createBesAccount'}}},[_vm._v(" BES HESABI EKLE ")]),_c('button',{staticClass:"brand-button-outline",class:{
              'btn-active': _vm.selectedComponent == 'besAccountDetails'
            },on:{"click":function($event){_vm.selectedComponent = 'besAccountDetails'}}},[_vm._v(" BES HESAPLARIM ")]),_c('router-link',{staticClass:"brand-button-outline",attrs:{"to":{
              name: 'myAccount',
              params: {
                active: 'BesAccountDetails'
              }
            }}},[_vm._v("BES HESAP DETAYLARIM")])],1),_c(_vm.selectedComponent,{tag:"component",on:{"success":function($event){_vm.selectedComponent = 'besAccountDetails'}}})],1)]),_c('div',{staticClass:"clearfix"}),(_vm.selectedComponent == 'besAccountDetails')?_c('div',{staticClass:"col-md-10 col-md-offset-1 col-xs-12 new-entry"},[_c('newEntries'),_c('div',{staticClass:"clearfix"}),_vm._m(0)],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap",attrs:{"id":"gbbva"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"col-md-1 col-md-offset-1 col-xs-12"},[_c('img',{attrs:{"src":require("@/assets/images/bes-accounts.png"),"alt":"Bes Hesaplarım"}})]),_c('div',{staticClass:"col-md-10 col-xs-12"},[_vm._v(" BES hesaplarınıza ait devlet katkısı ve diğer detayları görebilmek için "),_c('br'),_c('b',[_c('a',{attrs:{"href":"https://www.garantiemeklilik.com.tr","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("www.garantiemeklilik.com.tr")])]),_vm._v(" adresini ziyaret ediniz. ")]),_c('div',{staticClass:"clearfix"})])])
}]

export { render, staticRenderFns }