<template>
  <div>
    <span class="h3">Başarısız Seri Noları</span>

    <div id="grid"></div>
    <!-- Modal -->
    <span
      class="hide"
      id="toggleModal"
      data-toggle="modal"
      data-target="#requestForm"
    ></span>
    <div class="modal fade" id="requestForm" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
          @click="closeModal()"
        >
          X
        </button>
        <div class="modal-content">
          <service-request-form
            :data="{ serviceRequestUpdate: true, data: selectedData }"
            v-if="showModal"
          ></service-request-form>
        </div>
      </div>
    </div>
    <!-- Modal End -->
  </div>
</template>
<script>
/* eslint-disable */
import { HTTP } from "@/main-source";
import Loading from "@/components/loading";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/tr";
import ServiceRequestForm from '../modal/Service-Request-Form.vue';

export default {
  name: "UnsuccessSerialCodes",
  data() {
    return {
      showModal: false,
      selectedId: null,
      selectedData: null,
      showControlFormCheckbox: false,
      isCombiInstallationControlForm: false,
      serviceRequestAppointmentDate: null,
      serviceForm: {
        serviceRequestName: "",
        serviceRequestSurname: "",
        serviceRequestCityId: "",
        serviceRequestDistrictId: "",
        serviceRequestNeighborhoodId: "",
        serviceRequestMainStreet: "",
        serviceRequestStreet: "",
        serviceRequestApartment: "",
        serviceRequestLivingRoom: "",
        serviceRequestAppointmentDate: "",
        serviceRequestPhone: "",
        serviceRequestEmail: ""
      },
      serviceRequestCity: null,
      serviceRequestDistrict: null,
      serviceRequestNeighborhood: null,
      serviceRequestPhone: null,
      serviceRequestCitys: [],
      serviceRequestDistricts: [],
      serviceRequestNeighborhoods: [],
      notValid: [],
      resultMessage: null,
      isProcessing: false
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
      //$('.k-link')[0].click();
    },
    edit: function(id) {
      this.selectedId = id;
      this.selectedData = null;
      let data = { id: id };

      HTTP.post("Transaction/GetPassive", data).then(result => {
        if (result.data && result.data.data) {
          for(const index in result.data.data) {
            if (result.data.data[index].id === parseInt(this.selectedId, 10)) {
              this.selectedData = result.data.data[index];
              document.getElementById("toggleModal").click();
              this.showModal = true;
              break;
            }
          }
        }
      });
    },
    update: function() {
      this.isProcessing = true;
      this.checkServiceForm();
      if (this.notValid.length == 0) {
        let data = {
          id: this.selectedId,
          serviceRequestName: this.serviceForm.serviceRequestName,
          serviceRequestSurname: this.serviceForm.serviceRequestSurname,
          serviceRequestCityId: this.serviceRequestCity.id,
          serviceRequestDistrictId: this.serviceRequestDistrict.id,
          serviceRequestNeighborhoodId: this.serviceRequestNeighborhood.id,
          serviceRequestMainStreet: this.serviceForm.serviceRequestMainStreet,
          serviceRequestStreet: this.serviceForm.serviceRequestStreet,
          serviceRequestApartment: this.serviceForm.serviceRequestApartment,
          serviceRequestLivingRoom: this.serviceForm.serviceRequestLivingRoom,
          serviceRequestAppointmentDate: "",
          serviceRequestPhone: this.serviceForm.serviceRequestPhone,
          serviceRequestEmail: this.serviceForm.serviceRequestEmail
        };
        HTTP.post("Transaction/ServiceRequestUpdate", data).then(result => {
          this.resultMessage = result.data.data;
          this.isProcessing = false;
          this.showModal = false;
          document.getElementsByClassName("k-pager-refresh")[0].click();
        });
      } else {
        this.isProcessing = false;
      }
    },
    validEmail: function(email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
      /* eslint-disable */
    },
    checkServiceForm: function() {
      if (
        this.showControlFormCheckbox &&
        !this.isCombiInstallationControlForm
      ) {
        this.notValid.push("isCombiInstallationControlForm");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "isCombiInstallationControlForm"
        );
      }
      if (this.serviceForm.serviceRequestName.length == 0) {
        this.notValid.push("serviceRequestName");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestName"
        );
      }
      if (this.serviceForm.serviceRequestSurname.length == 0) {
        this.notValid.push("serviceRequestSurname");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestSurname"
        );
      }

      if (!this.serviceRequestCity) {
        this.notValid.push("serviceRequestCity");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestCity"
        );
      }
      if (!this.serviceRequestDistrict) {
        this.notValid.push("serviceRequestDistrict");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestDistrict"
        );
      }
      if (!this.serviceRequestNeighborhood) {
        this.notValid.push("serviceRequestNeighborhood");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestNeighborhood"
        );
      }

      if (this.serviceForm.serviceRequestMainStreet.length == 0) {
        this.notValid.push("serviceRequestMainStreet");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestMainStreet"
        );
      }

      if (this.serviceForm.serviceRequestStreet.length == 0) {
        this.notValid.push("serviceRequestStreet");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestStreet"
        );
      }

      if (this.serviceForm.serviceRequestApartment.length == 0) {
        this.notValid.push("serviceRequestApartment");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestApartment"
        );
      }

      if (this.serviceForm.serviceRequestLivingRoom.length == 0) {
        this.notValid.push("serviceRequestLivingRoom");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestLivingRoom"
        );
      }

      /*if (this.serviceForm.serviceRequestAppointmentDate.length == 0) {
        this.notValid.push("serviceRequestAppointmentDate");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestAppointmentDate"
        );
      }*/

      if (this.serviceForm.serviceRequestPhone.length < 7) {
        this.notValid.push("serviceRequestPhone");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestPhone"
        );
      }

      if (!this.validEmail(this.serviceForm.serviceRequestEmail)) {
        this.notValid.push("serviceRequestEmail");
      } else {
        this.notValid = this.notValid.filter(
          item => item != "serviceRequestEmail"
        );
      }
    }
  },
  components: {
    Loading,
    TheMask,
    DatePicker,
    ServiceRequestForm
  },
  created() {
    HTTP.post("User/GetUserAuthorizedCity", {}).then(result => {
      result.data.forEach(city => {
        this.serviceRequestCitys.push({
          id: city.id,
          baymakCityId: city.baymakCityId,
          cityId: city.cityId,
          label: city.cityTitle
        });
      });
    });
  },
  mounted() {
    var token = sessionStorage.token;
    var editRecordID = null;

    setTimeout(() => {
      $(".k-grid-excel")[0].onmousedown = function(e) {
        $("#loadingDiv").show();
      };
    }, 1000);

    kendo.culture("tr-TR");
    function RunKendoUser() {
      var remoteDataSource = new kendo.data.DataSource({
        pageSize: 10,
        transport: {
          read: {
            url: "https://baymak.tutkal.com.tr/Api/Transaction/GetPassive",
            dataType: "json",
            type: "POST",
            contentType: "application/json",
            beforeSend: function(req) {
              req.setRequestHeader("token", token);
            },
            complete: function(data) {}
          },

          parameterMap: function(options, operation) {
            operation = operation;

            if (operation === "read") {
              //Export settings start
              //Export settings stop
            }

            if (operation !== "read" && options.model) {
              return JSON.stringify(options.models[0]);
            } else {
              return JSON.stringify(options);
            }
          }
        },
        // filter: [{ "field": "transactionType", "operator": "eq", "value": "5", logic: "and" }],

        schema: {
          data: "data",
          total: "total",
          model: {
            id: "id",
            fields: {
              id: { editable: false, hidden: true, defaultValue: 0 }
            }
          }
        },
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        error: function(e) {}
      });

      $("#grid").kendoGrid({
        dataSource: remoteDataSource,
        toolbar: ["excel"],
        dataSource: remoteDataSource,
        excel: {
          fileName: "Başarısız Seri Numaraları.xlsx",
          filterable: true,
          allPages: true,
        },
        excelExport: function(e) {
          setExcel(e);
        },
        scrollable: true,
        sortable: true,
        height: 490,
        filterable: {
          extra: true,
          operators: {
            string: {
              contains: "Bunu içeren",
              startswith: "Bununla Başlayan",
              endswith: "Bununla biten",
              eq: "Eşit",
              neq: "Eşit olmayan"
            }
          }
        },
        resizable: true,
        pageable: {
          refresh: true,
          pageSizes: false,
          buttonCount: 5
        },
        columns: [
          {
            field: "created",
            title: "Oluşturma Tarihi",
            filterable: false,
            template: "#= kendo.toString(new Date(created),'dd/MM/yyyy') #",
			width:"90px"
          },

          {
            field: "serialNumber",
            title: "Seri No",
            filterable: true,
			width:"140px"
          },
          {
            field: "description",
            title: "Açıklama",
            filterable: true
          },
          // {
          //   field: "amount",
          //   title: "Kazanım",
          //   filterable: true
          // },
          {
              field: "serialRuleSetResult",
              title: "Bilgi",
              filterable: true,
			  width:"140px"
            },
			
			 {
              field: "productMainGroupInfo",
              title: "Ürün Bilgi",
              filterable: true,
			  width:"140px"
            },
            {
                field: "baymakServiceRequestApiResultServiceName",
              title: "Servis Adı",
              filterable: true,
			  width:"120px"
          },
          {
            field: "statusTitle",
            title: "Durum",
            filterable: true,
			width:"110px"
          },

           {
             field: "",
            title: "",
          template:
          "<button type='button' class='brand-button edit-service' data-id='#=data.id#'>Servis Talep Formu  </button>",
             width: "170px"
           }
        ]
      });
    }
    RunKendoUser();
    var instance = this;
    document
      .getElementById("grid")
      .addEventListener("click", function(e, ins = instance) {
        if (e.target.getAttribute("data-id")) {
          ins.edit(e.target.getAttribute("data-id"));
        }
      });
  },
  watch: {
    serviceRequestCity: function() {
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.serviceRequestCity.cityId
      }).then(result => {
        this.serviceForm.serviceRequestDistrictId = null;
        this.serviceRequestDistrict = null;
        this.serviceRequestDistricts = [];
        this.serviceForm.serviceRequestNeighborhoodId = null;
        this.serviceRequestNeighborhood = null;
        this.serviceRequestNeighborhoods = [];
        result.data.forEach(item => {
          this.serviceRequestDistricts.push({
            baymakId: item.baymakDistrictId,
            id: item.id,
            label: item.title
          });
        });
      });
    },
    serviceRequestDistrict: function() {
      if (this.serviceRequestDistrict) {
        HTTP.post("Neighborhood/GetListByDistrictId", {
          districtId: this.serviceRequestDistrict.id
        }).then(result => {
          this.serviceForm.serviceRequestNeighborhoodId = null;
          this.serviceRequestNeighborhood = null;
          this.serviceRequestNeighborhoods = [];
          result.data.forEach(item => {
            this.serviceRequestNeighborhoods.push({
              id: item.id,
              baymakId: item.baymakNeighborhoodId,
              label: item.title
            });
          });
        });
      }
    },
    isCombiInstallationControlForm: function() {
      if (this.isCombiInstallationControlForm) {
        this.contractModal();
      }
    },
    showModal: function() {
      if (this.showModal == false) {
        this.notValid = [];
        this.serviceForm = {
          serviceRequestName: "",
          serviceRequestSurname: "",
          serviceRequestCityId: "",
          serviceRequestDistrictId: "",
          serviceRequestNeighborhoodId: "",
          serviceRequestMainStreet: "",
          serviceRequestStreet: "",
          serviceRequestApartment: "",
          serviceRequestLivingRoom: "",
          serviceRequestAppointmentDate: "",
          serviceRequestPhone: "",
          serviceRequestEmail: ""
        };
        this.serviceRequestCity = null;
        this.serviceRequestDistrict = null;
        this.serviceRequestNeighborhood = null;
        this.serviceRequestPhone = null;
      }
    }
  }
};
</script>
<style lang="scss">
.k-current-page {
  display: none !important;
}
.edit-service {
  padding: 4px 10px !important;
  font-size: 12px !important;
}
.service-form {
  .h4 {
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
  }
}
.date {
  position: relative !important;
  display: block;
  .brand-input {
    width: 100% !important;
  }
}
.edit-form {
  max-width: 60vw !important;
  max-height: 90vh !important;
  overflow-y: scroll !important;
}
.close-form {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 99;
}
.mx-datepicker-main.mx-datepicker-popup {
  z-index: 999999;
}
.modal-content {
    position: relative;
    overflow-y: auto;
    max-height: 600px;
    padding: 15px;
}
</style>