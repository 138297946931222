<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <h1 class="h1"> 1 Yıl Kuralını Kaldırdık </h1>
      <div class="col-md-11 col-md-offset-1 content col-xs-12">
        <img
          src="https://www.baymaklife.com.tr/img/1920x630-Sadakat.jpg"
          style="width:100%"
        />

        <div class="col-md-12 mg-t-10">
          
          <p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Kampanya Şartları: </span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">&bull;Sisteme kaydedilen seri numaralarının ilk &ccedil;alıştırmalarda puan kazanımlarını etkileyen &ldquo;İlk 1 yıl&rdquo; şartı kaldırılmıştır. </span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">&bull;Sisteme eklediğiniz seri numaralarına ait kombilerin ilk gaz a&ccedil;ılışına ait puan kazanım hakkınız 1 yıl sonrasında da devam edecektir. </span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">&bull;Mevcutta ge&ccedil;erli olan diğer Baymaklife koşulları ise değişiklik g&ouml;stermeden devam etmektedir.</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">&bull;Bu kural <strong>15 Eyl&uuml;l 2023</strong> sonrası i&ccedil;in ge&ccedil;erlidir. Mevcutta hata almış cihazlar i&ccedil;in bu kural değişikliği s&ouml;z konusu değildir.</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">&bull;Daha &ouml;nce bu sebeple sistemden hata alan cihazların silinip tekrar eklenmesi durumu kabul edilmemektedir.</span></span></p>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "whatis",
  data() {
    return {};
  }
};
/* eslint-disable */
</script>
<style lang="scss">
.mg-t-10 {
  margin-top: 10px;
}
</style>
