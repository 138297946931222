<template>
  <div>
    <div class="gutter"></div>
    <div class="inner-page-top-bg faq">
      <div>
        <h1 class="h1">KİŞİSEL VERİLERİN KORUNMASI VE GİZLİLİK</h1>

        <img src="@/assets/images/arrow-down.png" alt />
      </div>
    </div>

    <div class="container page-negative negative-2 contract">
      <SafetyPrivacyText></SafetyPrivacyText>
    </div>
  </div>
</template>

<script>
import SafetyPrivacyText from "@/components/static/Safety-Privacy";

export default {
  name: "SafetyPrivacy",
  data() {
    return {};
  },
  components: {
    SafetyPrivacyText
  }
};
</script>
<style lang="scss">
.contract {
  text-align: justify;
}
</style>
