var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse pull-right pd0 col-xs-12"},[_c('ul',{staticClass:"nav navbar-nav pd0"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'whatis' }},nativeOn:{"click":function($event){return _vm.isSame.apply(null, arguments)}}},[_vm._v("BAYMAKLIFE NEDIR?")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'activeCampaigns' }},nativeOn:{"click":function($event){return _vm.isSame.apply(null, arguments)}}},[_vm._v("AKTİF KAMPANYALAR")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'besAccounts' }}},[_vm._v("BES HESAPLARIM")])],1),_c('li',[_c('a',{attrs:{"href":[
            _vm.isPasswordChance == false
              ? '#'
              : 'https://baymak-hediye.tutkal.com.tr/customLogin.aspx?token=' +
                _vm.token,
          ],"target":[_vm.isPasswordChance == false ? '' : '']}},[_vm._v("HEDİYE KATALOĞU")])]),_c('li',[_c('router-link',{attrs:{"to":"/myaccount/segmentGifts"}},[_vm._v("STATÜ HEDİYELERİM")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Contact' }}},[_vm._v("İLETİŞİM")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-header"},[_c('button',{staticClass:"navbar-toggle",attrs:{"type":"button","data-toggle":"collapse","data-target":".navbar-collapse"}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})])])
}]

export { render, staticRenderFns }