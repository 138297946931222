var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"inner-page-top-bg"},[_c('div',{staticClass:"color"},[_c('h1',{staticClass:"h1 text-center mg-0"},[_vm._v("ARAMA SONUÇLARI")]),_c('p',[_vm._v(" \""+_vm._s(_vm.$route.query.q.trim())+"\" için sonuçlar aşağıda listelenmektedir. ")]),_c('img',{attrs:{"src":require("@/assets/images/arrow-down-dark.png"),"alt":""}})])]),_c('div',{staticClass:"container page-negative account mg-100"},[_c('div',{staticClass:"col-md-12 content col-xs-12 results"},[(_vm.results.length == 0 && _vm.pageResults.length == 0)?_c('span',{staticClass:"d-block text-center"},[_vm._v("Sonuç yok.")]):_vm._e(),_vm._l((_vm.pageResults),function(page,index){return _c('router-link',{key:index,attrs:{"to":{
          name: page.name
        }}},[_vm._v(" "+_vm._s(page.as)+" "),_c('img',{attrs:{"src":require("@/assets/images/arrow-right-dark.png"),"alt":""}})])}),_vm._l((_vm.results),function(result,index){return _c('router-link',{key:index,attrs:{"to":{
          name: 'campaignDetails',
          params: {
            id: result.id
          }
        }}},[_vm._v(" "+_vm._s(result.title)+" "),_c('img',{attrs:{"src":require("@/assets/images/arrow-right-dark.png"),"alt":""}})])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }