<template>
  <div class="loader"></div>
</template>
<script>
export default {
  name: "loading"
};
</script>
<style>
.loader,
.loader:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.loader {
  margin-bottom: -3px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(42, 171, 156, 1);
  border-right: 3px solid rgba(42, 171, 156, 1);
  border-bottom: 3px solid rgba(42, 171, 156, 1);
  border-left: 3px solid #22f0c4;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
