<template>
  <div>
    <div class="gutter"></div>
    <div class="inner-page-top-bg contact">
      <div>
        <h1 class="h1">İLETİŞİM</h1>
        <p>Görüş, Öneri ve Talepleriniz İçin</p>
        <a href="tel:+908504500575">0 850 450 05 75</a>
        <img src="public/images/arrow-down.png" alt />
      </div>
    </div>

    <div class="container page-negative negative-2">
      <div class="col-md-5 col-xs-12">
        <span class="h1">Görüş, Öneri ve Talepleriniz İçin</span>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="message.name"
            placeholder="AD"
          />
          <span class="error" :class="{ show: notValid.includes('name') }"
            >Lütfen adınızı giriniz.</span
          >
        </div>
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="message.surname"
            placeholder="SOYAD"
          />
          <span class="error" :class="{ show: notValid.includes('surname') }"
            >Lütfen soyadınızı giriniz.</span
          >
        </div>
        <div class="form-group">
          <the-mask
            mask="0 (5##) ### ## ##"
            v-model="message.gsm"
            class="brand-input"
            type="phone"
            placeholder="CEP TELEFONU"
            maxlength="20"
          ></the-mask>
          <span class="error" :class="{ show: notValid.includes('gsm') }"
            >Lütfen geçerli bir sabit telefon numarası giriniz.</span
          >
        </div>
        <div class="form-group">
          <input
            type="text"
            class="brand-input"
            v-model="message.email"
            placeholder="e-posta adresiniz"
          />
        </div>
        <span class="error" :class="{ show: notValid.includes('email') }"
          >Lütfen e-posta adresinizi giriniz.</span
        >

        <div class="form-group btn-textarea">
          <textarea
            type="text"
            class="brand-input"
            v-model="message.message"
            placeholder="MESAJINIZ"
            maxlength="2000"
          ></textarea>
          <span class="error" :class="{ show: notValid.includes('message') }"
            >Lütfen mesajınızı giriniz.</span
          >
        </div>

        <div class="form-group">
          <label for="kvkk" class="btn-radio">
            <input type="checkbox" name="a2" id="kvkk" v-model="kvkk" />

            <u data-toggle="modal" data-target="#safetyModal">KVKK metnini</u>
            okudum kabul ediyorum.
            <span class="checkmark"></span>
          </label>
          <span class="error" :class="{ show: notValid.includes('kvkk') }"
            >Lütfen onaylayınız.</span
          >
        </div>

        <div class="form-group">
          <button type="button" class="brand-button" @click="send">
            GÖNDER
            <Loading v-if="onProcess"></Loading>
          </button>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <span
      class="hide"
      id="toggleModal"
      data-toggle="modal"
      data-target="#myModal"
    ></span>
    <div class="modal fade" id="myModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <span class="h3">MESAJINIZ GÖNDERİLDİ</span>
          Mesajınız başarılı bir şekilde gönderilmiştir.
        </div>
      </div>
    </div>
    <!-- Modal End -->

    <!-- Safety Modal -->

    <div class="modal fade" id="safetyModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <SafetyPrivacyText></SafetyPrivacyText>
        </div>
      </div>
    </div>
    <!-- Safety Modal End -->
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import { TheMask } from "vue-the-mask";
import Loading from "@/components/loading";
import SafetyPrivacyText from "@/components/static/Safety-Privacy";

export default {
  name: "Contact",
  data() {
    return {
      message: {
        name: "",
        surname: "",
        gsm: "",
        email: "",
        message: ""
      },
      kvkk: true,
      notValid: [],
      onProcess: false
    };
  },
  components: {
    Loading,
    TheMask,
    SafetyPrivacyText
  },
  methods: {
    send: function() {
      this.onProcess = true;
      this.checkForm();
      if (this.notValid.length == 0) {
        this.message.gsm = "5" + this.message.gsm;
        HTTP.post("Login/SendContactMail", this.message).then(result => {
          document.getElementById("toggleModal").click();
          this.onProcess = false;
          if (result.data.success) {
            this.message = {
              name: "",
              surname: "",
              gsm: "",
              email: "",
              message: ""
            };
            setTimeout(() => {
              this.notValid = [];
            }, 100);
          }
        });
      } else {
        this.onProcess = false;
      }
      console.log(this.notValid);
    },
    validEmail: function(email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
      /* eslint-disable */
    },
    isNumber: function(val) {
      let re = /^[0-9]*$/;
      return val.match(re);
    },
    checkForm: function() {
      if (!this.validEmail(this.message.email) || this.message.email === "") {
        this.notValid.push("email");
      } else {
        this.notValid = this.notValid.filter(item => item != "email");
      }

      if (this.message.name == "") {
        this.notValid.push("name");
      } else {
        this.notValid = this.notValid.filter(item => item != "name");
      }

      if (this.message.surname == "") {
        this.notValid.push("surname");
      } else {
        this.notValid = this.notValid.filter(item => item != "surname");
      }

      this.message.gsm = this.message.gsm.replace(" ", "");

      if (this.message.gsm.length < 9) {
        this.notValid.push("gsm");
      } else {
        this.notValid = this.notValid.filter(item => item != "gsm");
      }

      if (this.message.message == "") {
        this.notValid.push("message");
      } else {
        this.notValid = this.notValid.filter(item => item != "message");
      }

      if (!this.kvkk) {
        this.notValid.push("kvkk");
      } else {
        this.notValid = this.notValid.filter(item => item != "kvkk");
      }
    }
  },
  watch: {
    "message.gsm": function(val) {
      this.message.gsm = this.message.gsm.replace(" ", "");
      if (!this.isNumber(val)) {
        this.message.gsm = this.message.gsm;
        this.notValid.push("gsm");
      } else {
        this.notValid = this.notValid.filter(item => item != "gsm");
      }
    },
    "message.name": function() {
      if (this.message.name.length > 0) {
        this.notValid = this.notValid.filter(item => item != "name");
      }
    },
    "message.message": function() {
      if (this.message.message.length > 0) {
        this.notValid = this.notValid.filter(item => item != "message");
      }
    },
    "message.surname": function() {
      if (this.message.name.length > 0) {
        this.notValid = this.notValid.filter(item => item != "surname");
      }
    },
    "message.email": function() {
      if (!this.validEmail(this.message.email)) {
        this.notValid.push("email");
      } else {
        this.notValid = this.notValid.filter(item => item != "email");
      }
    }
  }
};
</script>
<style lang="scss">
.contact {
  a {
    color: #fff !important;
    font-weight: bold;
  }
}
</style>
	