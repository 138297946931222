<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <h1 class="h1">Baymaklife Bahar Kampanyası</h1>
      <div class="col-md-11 col-md-offset-1 content col-xs-12">
        <img
          src="https://baymak.tutkal.com.tr/bahar-kampanya.jpg"
          style="width:100%"
        />

        <div class="col-md-12 mg-t-10">
          <p>
            Baymaklife, Mart ayı boyunca girişi ve başarılı bir şekilde ilk
            &ccedil;alıştırması ger&ccedil;ekleştirilen Baymak ve Br&ouml;tje
            marka&nbsp;<strong>tam yoğuşmalı (premix)</strong>&nbsp;kombilere
            &ouml;zel 500 TL kazanma fırsatı sunuyor!
          </p>
          <ul>
            <li>
              Proje kapsamında alınan kombiler puan kampanyasına dahil değildir.
            </li>
            <li>
              Kampanya 01.03.2021 ile 31.03.2021 tarihleri arasında
              ge&ccedil;erli olacaktır.
            </li>
            <li>
              Kampanya 01.07.2020 ve sonrasında &uuml;retilmiş seri numaralı
              kombiler i&ccedil;in ge&ccedil;erlidir.
            </li>
            <li>
              Kampanya ge&ccedil;erlilik s&uuml;resi i&ccedil;erisinde
              Baymaklife&rsquo;a girilen ve yine belirtilen tarihlerde ilk
              &ccedil;alıştırması yapılan Baymak ve Br&ouml;tje&nbsp;<strong
                >tam yoğuşmalı (premix)</strong
              >&nbsp;kombilere; 500 TL BES (devlet katkısı hari&ccedil;) veya
              500 puan verilecektir.
            </li>
            <li>
              Kampanya kapsamında başarılı olan seri numaraların hak edişleri,
              BES y&uuml;klemeler BES hesaplarına puan y&uuml;klemeler puan
              hesaplarına olmak &uuml;zere&nbsp;<strong
                >Nisan ayının ikinci haftası</strong
              >&nbsp;itibarı ile yatırılacaktır.
            </li>
            <li>
              Kampanya &ouml;ncesi tarihlerde Baymaklife&rsquo;a girilen
              kombilerin ilk &ccedil;alıştırması kampanya tarihlerinde yapılması
              durumunda kampanyaya dahil olmayacaktır.
            </li>
            <li>
              1-31 Mart 2021 tarihleri arasında Baymaklife'a girilen, ilk
              &ccedil;alıştırması 1 Mart-8 Nisan 2021 tarihleri arasında yapılan
              kombiler kampanyadan faydalanabileceklerdir.
            </li>
          </ul>
          <p>&nbsp;</p>

          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "whatis",
  data() {
    return {};
  }
};
/* eslint-disable */
</script>
<style lang="scss">
.mg-t-10 {
  margin-top: 10px;
}
</style>
