<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <div class="col-md-11 col-md-offset-1 content col-xs-12">

        <div class="col-md-12 mg-t-10">

          <p><span style="font-size:11pt"><span
                style="font-family:Calibri,sans-serif">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; KAMPANYA
                KOŞULLARI:</span></span></p>

          <p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">&nbsp;</span></span></p>

           
          <p>&bull;&nbsp;&nbsp; &nbsp;Kampanya sadece İzmir ilinde ge&ccedil;erlidir.<br />
&bull;&nbsp;&nbsp; &nbsp;1 Ekim &ndash; 30 Kasım 2023 tarihleri arasında alınan Baymak markalı tam yoğuşmalı Duotec ve Lunatec modeli kombilerin hediye Logi S kablosuz oda termostatı hak edişi bulunur. &nbsp;<br />
&bull;&nbsp;&nbsp; &nbsp;Hediye Logi S kablosuz oda termostatı, Baymak Yetkili Servis aracılığıyla temin edilecek ve montajı yapılacaktır.<br />
&bull;&nbsp;&nbsp; &nbsp;Baymak Yetkili Servislerine, montaj sırasında fatura ve fatura &uuml;zerinden seri no bilgisi beyan edilmesi gerekmektedir.<br />
&bull;&nbsp;&nbsp; &nbsp;Toplu iş kapsamında alınan kombiler, kampanya kapsamında dışındadır.<br />
&bull;&nbsp;&nbsp; &nbsp;Kampanya stoklarla sınırlıdır.<br />
&bull;&nbsp;&nbsp; &nbsp;Belirtilen oda termostatı modeli, stok durumu, model g&uuml;ncellemesi gibi sebeplerden değişiklik g&ouml;sterebilir.<br />
&bull;&nbsp;&nbsp; &nbsp;Baymak, kampanya koşullarını bir ihtara gerek olmaksızın değiştirme, yeniden organize etme, yayını durdurma hakkını saklı tutar.<br />
&bull;&nbsp;&nbsp; &nbsp;Bayi, yapı market, zincir mağaza ve internet kanalı &uuml;zerinden yapılan alımlarda da kampanya belirtilen şartlarda ge&ccedil;erlidir.<br />
&bull;&nbsp;&nbsp; &nbsp;Lambert, Dolcevita ve De Dietrich markaları i&ccedil;in ge&ccedil;erli değildir.<br />
&bull;&nbsp;&nbsp; &nbsp;Sadece fatura tarihinin veya sadece ilk &ccedil;alıştırma tarihinin bu aralıkta olması yeterli değildir.<br />
&bull;&nbsp;&nbsp; &nbsp;Hediye oda termostatı Logi S modeldir, değişiklik yapılmaz veya hediye bedeli farklı şekillerde yansıtılmaz. Farklı marka ve model oda termostatı muadili olarak verilemez.<br />
&bull;&nbsp;&nbsp; &nbsp;Belirtilen tarihlerde alınan kombinin ilk &ccedil;alıştırması en ge&ccedil; 30 Haziran 2024 olmalıdır.<br />
&bull;&nbsp;&nbsp; &nbsp;İlk &ccedil;alıştırma esnasında faturanın olmaması ve sonradan ibraz edilmesi talep edilen durumlarda, ek oda termostatı teslimi i&ccedil;in servis &uuml;creti yansıtılacaktır.<br />
&bull;&nbsp;&nbsp; &nbsp;Geriye d&ouml;n&uuml;k fatura ibrazı kabul edilmeyecektir.<br />
&bull;&nbsp;&nbsp; &nbsp;Hediye olan oda termostatını yetkili servis ilk &ccedil;alıştırma i&ccedil;in gittiğinde montajını yapacak ve montaj bedelini Baymak A.Ş. den alacaktır.<br />
&bull;&nbsp;&nbsp; &nbsp;Servis tarafından oda termostatı montajı i&ccedil;in ilk &ccedil;alıştırma esnasında ise t&uuml;keticiden ayrı bir &uuml;cret talep edilmeyecektir.<br />
&bull;&nbsp;&nbsp; &nbsp;Servis tarafından kombi ilk &ccedil;alıştırma hizmet formu a&ccedil;ıklamasına &ldquo;Hediye oda termostatı teslim edilmiş, montajı yapılmıştır.&rdquo; şeklinde yazılacaktır.<br />
&bull;&nbsp;&nbsp; &nbsp;M&uuml;şteri oda termostatının montajını istemez ise, oda termostatı elden bırakılacak ve servis formuna &ldquo;Hediye oda termostatı teslim edilmiştir, montajı istenmemiştir. İlerleyen s&uuml;re&ccedil;teki montaj talebi &uuml;cretli olacaktır.&rdquo; şeklinde yazılacaktır.&nbsp;<br />
&bull;&nbsp;&nbsp; &nbsp;Servis fatura resmini sisteme ekleyecektir.<br />
&bull;&nbsp;&nbsp; &nbsp;Servis tarafından oda termostatı montajı i&ccedil;in ilk &ccedil;alıştırma esnasında ise t&uuml;keticiden herhangi bir &uuml;cret talep edilmeyecektir.</p>




      </div>
    </div>
  </div>
</div></template>

<script>
/* eslint-disable */

export default {
  name: "whatis",
  data() {
    return {};
  }
};
/* eslint-disable */
</script>
<style lang="scss">.mg-t-10 {
  margin-top: 10px;
}</style>
