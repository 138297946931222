<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <h1 class="h1">PROFİLİM / HESABIM</h1>
      <div class="col-md-11 col-md-offset-1 content col-xs-12">
        <div class="col-md-3 col-xs-12">
          <button
            v-if="!isRequiredChangePassword"
            class="brand-button-outline"
            :class="{ 'btn-active': selectedComponent == 'Info' }"
            @click="selectedComponent = 'Info'"
          >
            ÜYELİK BİLGİLERİM
          </button>
          <div v-if="!hideMenu">
            <button
              class="brand-button-outline"
              :class="{ 'btn-active': selectedComponent == 'changePassword' }"
              @click="selectedComponent = 'changePassword'"
            >
              ŞİFRE DEĞİŞTİR
            </button>
            <button
              v-if="!isRequiredChangePassword && userRoleId != 11"
              class="brand-button-outline"
              :class="{
                'btn-active': selectedComponent == 'AccountTransactions',
              }"
              @click="selectedComponent = 'AccountTransactions'"
            >
              HESAP HAREKETLERİM
            </button>
            <button
              class="brand-button-outline"
              :class="{ 'btn-active': selectedComponent == 'segmentGifts' }"
              @click="selectedComponent = 'segmentGifts'"
            >
              STATÜ HEDİYELERİM
            </button>
            <button
              v-if="!isRequiredChangePassword && userRoleId != 11"
              class="brand-button-outline"
              :class="{
                'btn-active': selectedComponent == 'UnsuccessSerialCodes',
              }"
              @click="selectedComponent = 'UnsuccessSerialCodes'"
            >
              BAŞARISIZ SERİ NO
            </button>
            <button
              v-if="!isRequiredChangePassword && userRoleId != 11"
              class="brand-button-outline"
              :class="{
                'btn-active': selectedComponent == 'BesAccountDetails',
              }"
              @click="selectedComponent = 'BesAccountDetails'"
            >
              BES HESAP DETAYLARIM
            </button>
            <button
              v-if="!isRequiredChangePassword && userRoleId != 11"
              class="brand-button-outline"
              :class="{ 'btn-active': selectedComponent == 'pointDetailsPage' }"
              @click="selectedComponent = 'pointDetailsPage'"
            >
              PUAN ÖZETİM
            </button>
            <button class="brand-button-outline" @click="logout">
              ÇIKIŞ YAP
            </button>
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <component :is="selectedComponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Info from "@/components/account/Info";
import changePassword from "@/components/account/Change-Password";
import UnsuccessSerialCodes from "@/components/account/Unsuccess-Serial-Codes";
import AccountTransactions from "@/components/account/Account-Transactions";
import BesAccountDetails from "@/components/account/Bes-Account-Details";
import pointDetailsPage from "@/components/account/pointDetailsPage";
import segmentGifts from "@/components/account/SegmentGifts.vue";
import router from "@/router";
import { mapActions } from "vuex";
import EventBus from "@/components/event-bus";

export default {
  name: "Account",
  data() {
    return {
      selectedComponent: this.$route.params.active
        ? this.$route.params.active
        : "Info",
      hideMenu: false,
    };
  },
  components: {
    Info,
    changePassword,
    UnsuccessSerialCodes,
    AccountTransactions,
    BesAccountDetails,
    pointDetailsPage,
    segmentGifts
  },
  created() {
    if (this.isRequiredChangePassword) {
      this.selectedComponent = changePassword;
    }
  },
  methods: {
    ...mapActions(["setLoginStatus"]),
    logout: function() {
      this.setLoginStatus(false);
      
      sessionStorage.removeItem("notifyseen");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("modalClosed");
      EventBus.$emit("logged", false);
      router.push({
        name: "Login",
      });
    },
  },
  computed: {
    isRequiredChangePassword: function() {
      return this.$route.query.changePassword;
    },
    userRoleId: function() {
      return sessionStorage.userRoleId;
    },
  },
  watch: {
    "$route.params.active": function() {
      this.selectedComponent = this.$route.params.active
        ? this.$route.params.active
        : "Info";
    },
  },
  mounted() {
    EventBus.$on("updateFormActive", (value) => {
      this.hideMenu = value;
    });
  },
};
/* eslint-disable */
</script>
<style lang="scss"></style>
