<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative">
      <h1 class="h1">BES HESAPLARIM</h1>
      <div class="content">
        <div class="col-md-10 col-md-offset-1 col-xs-12">
          <div class="sub-navigation">
            <button
              class="brand-button-outline"
              :class="{ 'btn-active': selectedComponent == 'createBesAccount' }"
              @click="selectedComponent = 'createBesAccount'"
            >
              BES HESABI EKLE
            </button>
            <button
              class="brand-button-outline"
              :class="{
                'btn-active': selectedComponent == 'besAccountDetails'
              }"
              @click="selectedComponent = 'besAccountDetails'"
            >
              BES HESAPLARIM
            </button>
            <router-link
              class="brand-button-outline"
              :to="{
                name: 'myAccount',
                params: {
                  active: 'BesAccountDetails'
                }
              }"
              >BES HESAP DETAYLARIM</router-link
            >
          </div>
          <component
            :is="selectedComponent"
            @success="selectedComponent = 'besAccountDetails'"
          ></component>
        </div>
      </div>
      <div class="clearfix"></div>
      <div
        class="col-md-10 col-md-offset-1 col-xs-12 new-entry"
        v-if="selectedComponent == 'besAccountDetails'"
      >
        <newEntries></newEntries>
        <div class="clearfix"></div>
        <div id="gbbva" class="wrap">
          <div class="inner">
            <div class="col-md-1 col-md-offset-1 col-xs-12">
              <img
                src="@/assets/images/bes-accounts.png"
                alt="Bes Hesaplarım"
              />
            </div>
            <div class="col-md-10 col-xs-12">
              BES hesaplarınıza ait devlet katkısı ve diğer detayları görebilmek
              için
              <br />
              <b>
                <a
                  href="https://www.garantiemeklilik.com.tr"
                  target="_blank"
                  rel="noopener noreferrer"
                  >www.garantiemeklilik.com.tr</a
                >
              </b>
              adresini ziyaret ediniz.
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { HTTP } from "@/main-source";
import besAccountDetails from "@/components/besAccounts/Bes-Account-Details";
import createBesAccount from "@/components/besAccounts/Add";
import newEntries from "@/components/besAccounts/newEntries";

export default {
  name: "BesAccounts",
  data() {
    return {
      selectedComponent: "besAccountDetails"
    };
  },
  components: {
    besAccountDetails,
    createBesAccount,
    newEntries
  }
};
</script>
<style lang="scss">
@import "../assets/scss/colors.scss";
@import "@/assets/scss/mixins";
.k-current-page {
  display: none !important;
}
.sub-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
}
.new-entry {
  margin-top: 100px;
}
.sub-title {
  color: $brand-color-2;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}
#gbbva.wrap {
  @include brand-bg;
  border-radius: 5px;
  padding: 5px 1px 1px;
  margin-top: 60px;
  .inner {
    background-color: $white !important;
    border-radius: 5px;
    padding: 20px 10px 10px 5px;
    background-image: url("../assets/images/campaign-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
  }
}
</style>
