<template>
  <div class="col-md-12 notify-list">
    <div
      class="alert alert-primary"
      v-for="(gift, index) in notificaitonsData"
      :key="index"
    >
      {{ gift.description }}
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import "vue2-datepicker/index.css";
import Swal from "sweetalert2";
import EventBus from "@/components/event-bus";

export default {
  name: "Info",
  data() {
    return {
      user: {},
      disabled: true,
      city: null,
      isAcceptMyData: false,
      citys: [],
      provinces: [],
      address: null,
      district: null,
      districts: [],
      subscribeStatus: {
        isSubscribedSMS: false,
        isSubscribedEmail: false,
      },
      computedDateValue: null,
      notificaitonsData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getCity() {
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.user.addressList[0].cityId,
      }).then((result) => {
        console.log("🚀 ~ getCity ~ result:", result);
        this.district = "";
        this.districts = [];
        result.data.forEach((item) => {
          this.districts.push({ id: item.id, label: item.title });
        });
      });
    },
    getData() {
      HTTP.post("SegmentGift/Get", {}).then((result) => {
        this.notificaitonsData = result.data.data || [];
      });
    },
    updateUser() {
      /* eslint-disable */

      if (
        this.user.name.length == 0 ||
        this.user.surname.length == 0 ||
        !this.district ||
        this.address.length < 3 ||
        !this.isAcceptMyData
      ) {
        Swal.fire({
          type: "warning",
          title: "Bilgi Güncelleme",
          customClass: "photo-upload-campaign",
          text:
            "Lütfen girdiğiniz bilgileri kontrol ederek gerekli alanarı doldurduğunuzdan emin olunuz.",
          confirmButtonText: "Tamam",
        });
        return;
      }
      let data = {
        name: this.user.name,
        surname: this.user.surname,
        phone: this.user.gsm,
        email: this.user.email,
        districtId: this.district?.id,
        adress: this.address,
        birthDate: this.user.birthDate,
        isAcceptMyData: this.isAcceptMyData,
      };
      HTTP.post("User/UpdateByReactivate", data).then((result) => {
        if (result.data.success) {
          EventBus.$emit("updateFormActive", false);
          Swal.fire({
            type: "success",
            title: "Bilgi Güncelleme",
            customClass: "photo-upload-campaign",
            text: result.data.data,
            confirmButtonText: "Tamam",
          });
          this.getData();
          // window.location = "/";
        } else {
          Swal.fire({
            type: "warning",
            title: "Bilgi Güncelleme",
            customClass: "photo-upload-campaign",
            text: result.data.data,
            confirmButtonText: "Tamam",
          });
        }
      });
    },
  },
  watch: {
    "subscribeStatus.isSubscribedSMS": function() {
      HTTP.post("User/SubscribeEmailAndSms", this.subscribeStatus);
    },
    "subscribeStatus.isSubscribedEmail": function() {
      HTTP.post("User/SubscribeEmailAndSms", this.subscribeStatus);
    },
  },
};
</script>
<style lang="scss">
.has-head {
  position: relative;
  .head {
    font-weight: normal;
    position: absolute;
    left: 19px;
    top: 8px;
    font-size: 11px;
  }
  input,
  textarea {
    font-weight: 600;
    padding-top: 20px;
    padding-left: 20px;
    border-radius: 30px;
  }
}
.pad {
  padding: 15px;
}
</style>
