<template>
  <!-- Tab content start -->
  <div class="panel-group" id="faqAccordion">
    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question1"
      >
        Baymaklife nedir?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question1" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            “Baymaklife” Baymak Makina Sanayi ve Ticaret A.Ş. bünyesinde bulunan
            ürünlerin satışını gerçekleştirip ilk çalıştırmasını sağlayan
            bayilerine özel hazırladığımız bir ayrıcalıklar dünyasıdır.
          </p>
        </div>
      </div>
    </div>
    <!-- question & answer -->

    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question2"
      >
        Neden üye olmalıyım?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question2" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            -Baymak ürünlerinin üzerindeki seri numaraları Baymaklife web
            portalından girerek puan veya BES kazanabilirsiniz. -Baymak
            ürünlerine özel yeniliklerden ve kampanyalardan ilk sizler haberdar
            olursunuz. -Gerçekleşecek Baymaklife ara dönem kampanyalarına
            katılabilir ve puan, BES veya hediyeler kazanabilirsiniz.
            -Baymaklife mobil uygulamasını kullanarak da kampanyalara
            katılabilir ve kazanımlar sağlayabilirsiniz. -Hesabınızda biriken
            puanlarınızla, hediye kataloğundaki hediyeleri alabilirsiniz.
          </p>
        </div>
      </div>
    </div>
    <!-- question & answer -->

    <!-- question & answer -->
    <div class="panel">
      <div
        class="panel-heading collapsed"
        data-toggle="collapse"
        data-parent="#faqAccordion"
        data-target="#question3"
      >
        Üyelik süreci nasıl işliyor?
        <img src="@/assets/images/faq-arrow.png" class="icon" alt="Oku" />
      </div>
      <div id="question3" class="panel-collapse collapse" style="height: 0px;">
        <div class="panel-body">
          <p>
            Üyelik için öncelikle Baymak bayi statüsünde olmanız ve web sitesi
            ya da mobil uygulama üzerinden üye kayıt formunu doldurmanız
            gerekmektedir. Üye kayıt formunuz tarafımıza ulaştıktan sonra Baymak
            tarafından incelenecektir. Başvurunuz onaylandığı taktirde
            üyeliğiniz aktif hale getirilecektir.
          </p>
        </div>
      </div>
    </div>
    <!-- question & answer -->
  </div>
  <!-- content end -->
</template>

<script>
export default {
  name: "Faq"
};
</script>
