<template>
  <div>
    <span class="sub-title">YENİ BAŞVURULAR</span>
    <div id="newEntries"></div>
  </div>
</template>
<script>
export default {
  name: "newEntries",
  mounted() {
    /* eslint-disable */
    var token = sessionStorage.token;
    kendo.culture("tr-TR");
    function RunKendoUser() {
      var remoteDataSource = new kendo.data.DataSource({
        pageSize: 10,
        transport: {
          read: {
            url: "https://baymak.tutkal.com.tr/Api/PrivatePensionSystem/Get",
            dataType: "json",
            type: "POST",
            contentType: "application/json",
            beforeSend: function(req) {
              req.setRequestHeader("token", token);
            },
            complete: function(data) {}
          },

          parameterMap: function(options, operation) {
            operation = operation;

            if (operation === "read") {
              //Export settings start
              //Export settings stop
            }

            if (operation !== "read" && options.model) {
              return JSON.stringify(options.models[0]);
            } else {
              return JSON.stringify(options);
            }
          }
        },
        filter: [
          { field: "statusId", operator: "eq", value: "2", logic: "and" }
        ],
        schema: {
          data: "data",
          total: "total",
          model: {
            id: "id",
            fields: {
              id: { editable: false, hidden: true, defaultValue: 0 }
            }
          }
        },
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        error: function(e) {}
      });

      $("#newEntries").kendoGrid({
        dataSource: remoteDataSource,

        scrollable: true,
        sortable: true,
        height: 240,

        resizable: true,

        columns: [
          {
            field: "created",
            title: "Başvuru Tarihi",
            template: "#= kendo.toString(new Date(created),'MM/dd/yyyy') #"
          },
          {
            field: "code",
            title: "Poliçe Numarası",
            filterable: true
          },
          {
            field: "name",
            title: "Ad"
          },
          {
            field: "surname",
            title: "Soyad"
          },
          
          {
            field: "statusTitle",
            title: "Durum"
          }
        ]
      });
    }
    RunKendoUser();
  }
};
</script>