<template>
  <div>
    <template v-if="data">
      <div class="col-xs-12 alert alert-info" v-if="false">
        <i class="glyphicon glyphicon-info-sign"></i>&nbsp; &nbsp; 2022 yılı
        sonunda silinecek puanınız:
        {{
          data.dropPoint
            ? new Intl.NumberFormat("tr-TR").format(data.dropPoint)
            : 0
        }}
      </div>
    </template>
    <div class="row point-info">
      <div class="col-md-4">
        <div class="usefullpoint">
          <p v-if="isPoint">
            <span class="point">{{
              data.activeTotalPoint
                ? new Intl.NumberFormat("tr-TR").format(data.activeTotalPoint)
                : 0
            }}</span>
            Harcanabilir Puan
          </p>
          <p v-if="!isPoint">
            <span class="point">{{ data.besTotalPrice }}</span>
            Toplam Kazanılan TL
          </p>
        </div>
      </div>
      <div class="col-md-4" v-if="!isPoint">
        <div class="usefullpoint">
          <p>
            <span class="point">{{ data.besTotalCount }}</span>
            Toplam Yüklenen Seri No
          </p>
        </div>
      </div>
      <div class="col-md-8 details" v-if="isPoint">
        <div>
          <span>Kazanılan Puan: {{ data.totalPoint }}</span>
          <span>Harcanan Puan: {{ data.totalMinusPoint }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
export default {
  name: "pointDetails",
  props: {
    isPoint: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: []
    };
  },
  created() {
    HTTP.get("/Transaction/GetUserPoint").then(result => {
      this.data = result.data.data;
    });
  }
};
</script>
<style lang="scss">
.point-info {
  margin-top: 20px;
  margin-bottom: 20px;

  .details {
    display: flex;
    align-items: center;
    height: 100px;

    span {
      display: block;
      font-size: 16px;
    }
  }
}

.usefullpoint {
  width: 213px;
  height: 213px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background: linear-gradient(#1b587e 0%, #1e867b 100%);
  font-weight: bold;
  font-size: 14px;

  span {
    display: block;
    font-size: 38px;
  }
}
</style>
