var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"five"},[_c('div',{staticClass:"campaign-actions"},[_c('div',{staticClass:"col-md-3 col-xs-12",on:{"click":_vm.showSerialNumberForm}},[_vm._m(0)]),_c('router-link',{staticClass:"col-md-3 col-xs-3",attrs:{"to":{
        name: 'activeCampaigns',
      }}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/active-campaign.png"),"alt":"Aktif Kampanyalar"}}),_c('span',{staticClass:"h4"},[_vm._v("KAMPANYALAR")])])]),_c('router-link',{staticClass:"col-md-3 col-xs-3",attrs:{"to":{
        name: 'besAccounts',
      }}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/bes-accounts.png"),"alt":"Bes Hesaplarım"}}),_c('span',{staticClass:"h4"},[_vm._v(" BES "),_c('br'),_vm._v("HESAPLARIM ")])])]),(false)?_c('router-link',{attrs:{"to":{
        name: 'joinedCampaigns',
      }}},[_c('div',{staticClass:"col-md-3 col-xs-3"},[_c('img',{attrs:{"src":require("@/assets/images/follow-campaign.png"),"alt":"Kampanya Takibi"}}),_c('span',{staticClass:"h4"},[_vm._v("KAMPANYA TAKİBİ")])])]):_vm._e(),(false)?_c('router-link',{attrs:{"to":{
        name: 'completedCampaigns',
      }}},[_c('div',{staticClass:"col-md-3 col-xs-3"},[_c('img',{attrs:{"src":require("@/assets/images/completed-campaign.png"),"alt":"Aktif Kampanyalar"}}),_c('span',{staticClass:"h4"},[_vm._v("TAMAMLANAN KAMPANYALAR")])])]):_vm._e()],1),_c('span',{staticClass:"hide",attrs:{"id":"toggleModal","data-toggle":"modal","data-target":"#serialNumber"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"serialNumber","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('img',{staticClass:"modal-img",attrs:{"src":require("@/assets/images/modal/attention.png"),"alt":"Dikkat"}}),_c('button',{staticClass:"btn btn-default close",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" X ")]),_c('div',{staticClass:"modal-content"},[_c('serialNumber')],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/images/serial-number.png"),"alt":"Seri No Ekle"}}),_c('span',{staticClass:"h4"},[_vm._v("SERİ NO EKLE")])])
}]

export { render, staticRenderFns }