<template>
  <div>
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle"
        data-toggle="collapse"
        data-target=".navbar-collapse"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse pull-right pd0 col-xs-12">
      <ul class="nav navbar-nav pd0">
        <li>
          <router-link :to="{ name: 'whatis' }" @click.native="isSame"
            >BAYMAKLIFE NEDIR?</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'activeCampaigns' }" @click.native="isSame"
            >AKTİF KAMPANYALAR</router-link
          >
        </li>

        <li>
          <router-link :to="{ name: 'besAccounts' }"
            >BES HESAPLARIM</router-link
          >
        </li>
        <li>
          <a
            :href="[
              isPasswordChance == false
                ? '#'
                : 'https://baymak-hediye.tutkal.com.tr/customLogin.aspx?token=' +
                  token,
            ]"
            :target="[isPasswordChance == false ? '' : '']"
            >HEDİYE KATALOĞU</a
          >
        </li>
        <li>
          <router-link to="/myaccount/segmentGifts"
            >STATÜ HEDİYELERİM</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }">İLETİŞİM</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sub-Dealer-Nav",
  methods: {
    isSame: function() {
      if (this.$route.name == this.$route.name) {
        window.scrollTo(0, 400);
      }
    },
  },
  computed: {
    token: function() {
      return sessionStorage.token;
    },
    isPasswordChance: function() {
      return sessionStorage.isPasswordChance;
    },
  },
};
</script>
