<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative account">
      <h1 class="h1">İstanbul’a Özel Baymaklife Bahar Kampanyası</h1>
      <div class="col-md-11 col-md-offset-1 content col-xs-12">
        <img
          src="https://baymak.tutkal.com.tr/upload/istanbul.JPG"
          style="width:100%"
        />

        <div class="col-md-12 mg-t-10">
          <p>
            Baymaklife, 1 Mart-30 Nisan tarihleri arasında girişi ve başarılı
            bir şekilde ilk &ccedil;alıştırması ger&ccedil;ekleştirilen Baymak
            ve Br&ouml;tje marka tam yoğuşmalı (premix) kombilere,
            İstanbul&rsquo;a &ouml;zel 750 TL puan veya BES (devlet katkısı
            hari&ccedil;) kazanma fırsatı sunuyor.
          </p>
          <p>
            Sizleri Baymaklife&rsquo;ın avantajlı d&uuml;nyasına davet ediyoruz.
          </p>
          <p>&nbsp;</p>
          <p>
            İstanbul İline &Ouml;zel Baymaklife Bahar Kampanyası koşulları
            aşağıda sıralanmıştır;
          </p>
          <p>&nbsp;</p>

          <ul>
            <li>
              Proje kapsamında alınan kombiler puan kampanyasına dahil değildir.
            </li>
            <li>
              Kampanya 01.03.2021 ile 31.05.2021 tarihleri arasında
              ge&ccedil;erli olacaktır.
            </li>
            <li>
              Kampanya 01.07.2020 ve sonrasında &uuml;retilmiş seri numaralı
              kombiler i&ccedil;in ge&ccedil;erlidir.
            </li>
            <li>
              Kampanya ge&ccedil;erlilik s&uuml;resi i&ccedil;erisinde
              Baymaklife&rsquo;a girilen ve yine belirtilen tarihlerde
              <strong>İstanbul ilinde</strong> ilk &ccedil;alıştırması yapılan
              Baymak ve Br&ouml;tje
              <strong>tam yoğuşmalı (premix)</strong> kombilere; 750 TL BES
              (devlet katkısı hari&ccedil;) veya 750 puan verilecektir.
            </li>
            <li>
              Kampanya kapsamında başarılı olan seri numaraların hak edişleri,
              BES y&uuml;klemeler BES hesaplarına puan y&uuml;klemeler puan
              hesaplarına olmak &uuml;zere &nbsp;<strong
                >Haziran ayının ikinci haftası</strong
              >
              itibarı ile yatırılacaktır.
            </li>
            <li>
              Kampanya &ouml;ncesi tarihlerde Baymaklife&rsquo;a girilen
              kombilerin ilk &ccedil;alıştırması kampanya tarihlerinde yapılması
              durumunda kampanyaya dahil olmayacaktır.
            </li>
            <li>
              Kampanya tarihlerinde Baymaklife'a girilen ancak ilk
              &ccedil;alıştırması kampanya tarihleri dışında yapılan kombiler
              kampanyaya dahil olmayacaktır.
            </li>
          </ul>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "whatis",
  data() {
    return {};
  }
};
/* eslint-disable */
</script>
<style lang="scss">
.mg-t-10 {
  margin-top: 10px;
}
</style>
