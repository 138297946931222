<template>
  <div class="campaign-actions">
    <router-link
      :to="{
        name: 'activeCampaigns'
      }"
    >
      <div class="col-md-3 col-xs-6">
        <img
          src="@/assets/images/active-campaign.png"
          alt="Aktif Kampanyalar"
        />
        <span class="h4">AKTİF KAMPANYALAR</span>
      </div>
    </router-link>
    <router-link
      :to="{
        name: 'passiveCampaigns'
      }"
    >
      <div class="col-md-3 col-xs-6">
        <img
          src="@/assets/images/follow-campaign.png"
          alt="Aktif Kampanyalar"
        />
        <span class="h4">PASİF KAMPANYALAR</span>
      </div>
    </router-link>
    <router-link
      :to="{
        name: 'subFirmsCampaigns'
      }"
    >
      <div class="col-md-3 col-xs-6">
        <img
          src="@/assets/images/sub-company.png"
          alt="ALT FİRMALAR KAMPANYA TAKİBİ"
        />
        <span class="h4">ALT FİRMALAR KAMPANYA TAKİBİ</span>
      </div>
    </router-link>
    <router-link
      :to="{
        name: 'completedCampaigns'
      }"
    >
      <div class="col-md-3 col-xs-6">
        <img
          src="@/assets/images/completed-campaign.png"
          alt="ALT FİRMALAR TAMAMLANAN KAMPANYANLAR"
        />
        <span class="h4">ALT FİRMALAR TAMAMLANAN KAMPANYALAR</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "mainDealer"
};
</script>
<style lang="scss">
@media (max-width: 767.98px) {
  .campaign-actions {
    .col-xs-6 {
      padding: 15px;
      .h4 {
        margin-top: 20px;
      }
    }
  }
}
</style>
