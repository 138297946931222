<template>
  <div class="text-left">
    <h3 class="h3">KİŞİSEL VERİLERİN KORUNMASI VE GİZLİLİK</h3>
    <p class="p1">
      <span class="s1">
        <b>BAYMAK MAKİNA SANAYİ VE TİCARET A.Ş.</b>
      </span>
    </p>
    <p class="p1">
      <span class="s1">
        <b>AYDINLATMA METNİ</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >Son Güncelleme Tarihi: Click or tap to enter a date.</span
      >
    </p>
    <p class="p1">
      <span class="s1">
        <b>KİŞİSEL VERİLERİNİZİN KORUNMASINA İLİŞKİN BİLGİLENDİRME</b>
      </span>
    </p>
    <p class="p3">
      <span class="s1">
        Baymak Makina Sanayi ve Ticaret A.Ş. (“
        <b>Baymak</b>” veya “ <b>Şirket</b>”) tarafından toplanan ve işlenen
        kişisel veriler, şirketimizin koruması altındadır. Kişisel veriler,
        Baymak tarafından işbu Aydınlatma Metni kapsamında 6698 sayılı Kişisel
        Verilerin Korunması Kanunu (“ <b>KVKK</b>”) ve ikincil düzenlemeleri ve
        kişisel verilerin korunmasına ilişkin sair mevzuatı uygun olarak
        işlenmektedir.
      </span>
    </p>
    <p class="p1">
      <span class="s1"
        >KVKK uyarınca kişisel veriler, kimliğinizi belirli ya da belirlenebilir
        kılan her türlü bilgi anlamına gelmektedir. Şirket tarafından işlenen
        kişisel verileriniz, bunların işlenme amaçları, aktarılabileceği alıcı
        grupları, toplanma yöntemi, hukuki sebebi ve söz konusu kişisel verilere
        ilişkin haklarınız aşağıda yer almaktadır.</span
      >
    </p>
    <p class="p1">
      <span class="s1">
        <b>A- WEB SİTESİ KULLANICILARI (ÇEVRİMİÇİ ZİYARETÇİ)</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>A.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p5">
      <span class="s1"
        >İnternet sitemizi ziyaret etmeniz halinde aşağıdaki kişisel verileriniz
        işlenebilecektir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li5">
        <span class="s3">
          <b>Kimlik Bilgileriniz:</b>
        </span>
        <span class="s4">Ad soyad, TCKN,</span>
        <b></b>
      </li>
      <li class="li5">
        <span class="s3">
          <b>İletişim Bilgileriniz:</b>
        </span>
        <span class="s4"
          >E-posta adresi, telefon numarası, cep telefonu numarası, adres
          bilgileri</span
        >
      </li>
      <li class="li5">
        <span class="s3">
          <b>İşlem Güvenliğine İlişkin Bilgiler:</b>
        </span>
        <span class="s4">internet sitesi giriş çıkış</span>
        <b></b>
        <span class="s4">bilgileri, çerez bilgileri.</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>A.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p8">
      <span class="s1"
        >Kişisel verileriniz, ilgili mevzuata uygun olarak aşağıdaki amaçlar ile
        işlenebilecektir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Kullanıcılar tarafından yöneltilen talep ve önerilerin
          değerlendirmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">İletişim faaliyetlerinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >5651 Sayılı Kanun’dan doğan yükümlülüklerin yerine getirilmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >İlgili mevzuat gereği saklanması gereken bilgilerinizin muhafazası;
          bilgi kayıplarının önlenebilmesi için kopyalanması, yedeklenmesi;
          bilgilerinizin tutarlılığının kontrolünün sağlanması, şebekelerimizin
          ve bilgilerinizin güvenliği için gerekli teknik ve idari tedbirlerin
          alınması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Düzenleyici ve denetleyici kurumlarla, yasal düzenlemelerin
          gerektirdiği veya zorunlu kıldığı hukuki yükümlülüklerin yerine
          getirilmesi, yasal takiplerin ve hukuki süreçlerin yürütülmesi.</span
        >
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>A.III. KİŞİSEL VERİLERİNİZİN AKTARIMI</b>
      </span>
    </p>
    <p class="p12">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin aktarılmasına ilişkin
        hükümleri kapsamında işbu Aydınlatma Metni’nin</span
      >
      <span class="s6">A.II. başlığı</span>
      <span class="s1"
        >altında yer alan amaçların gerektirdiği ölçüde; ilgili mevzuat gereği
        talep edilmesi halinde adli makamlar veya kolluk kuvvetleri ile
        paylaşılabilecektir.</span
      >
    </p>
    <p class="p4">
      <span class="s1">
        <b
          >A.IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p10">
      <span class="s1"
        >Kişisel verileriniz internet sitemizi ziyaret etmeniz sırasında
        elektronik mecralardan yukarıda yer verilen amaç dahilinde Kanun’un 5 ve
        8. madde hükümlerinde sayılan ve aşağıda yer verilen hukuka uygunluk
        sebeplerine dayanılarak otomatik veya otomatik olmayan yollarla
        işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Baymak’ın tabi olduğu mevzuatta öngörülmüş olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Baymak’ın hukuki yükümlülüklerini yerine getirebilmesi için veri
          işlemenin zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Tarafınızca alenileştirilmiş olması,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, Baymak’ın
          meşru menfaatleri için veri işlemenin zorunlu olması.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>B- FİZİKSEL ZİYARETÇİLER</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>B.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p14">
      <span class="s1"
        >Baymak yerleşkesine ziyaretiniz kapsamında aşağıdaki kişisel
        verileriniz işlenebilecektir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li16">
        <span class="s3">
          <b>Kimlik Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Ad-soyadı, TCKN/VKN, pasaport numarası, doğum tarihi, medeni
          hal,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>İletişim Bilgileriniz</b>
        </span>
        <span class="s4">: Telefon numarası, e-posta adresi,</span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>Diğer Bilgileriniz:</b>
        </span>
        <span class="s4">Araç plaka bilgileri.</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>B.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p12">
      <span class="s1"
        >Kişisel verileriniz, Şirket yerleşkesini ziyaret etmeniz halinde ilgili
        mevzuata uygun olarak fiziksel mekan güvenliğinin sağlanması amacı ile
        işlenebilecektir.</span
      >
    </p>
    <p class="p4">
      <span class="s1">
        <b>B.III. İŞLENEN KİŞİSEL VERİLERİN AKTARIMI</b>
      </span>
    </p>
    <p class="p12">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin aktarılmasına ilişkin
        hükümleri kapsamında</span
      >
      <span class="s6">B.II. başlığı</span>
      <span class="s1">
        altında yer alan amaçların gerektirdiği ölçüde adli makamlar ve kolluk
        kuvvetleri
        <span class="Apple-converted-space"></span>ile paylaşılabilecektir.
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b
          >B.IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p10">
      <span class="s1"
        >Kişisel verileriniz, Şirket yerleşkesini ziyaret etmeniz halinde
        sizlerden; fiziki, yazılı, sözlü ya da CCTV kamerası vasıtasıyla
        yukarıda belirtilen amaçlar dâhilinde KVKK’nın 5. ve 8. madde
        hükümlerinde sayılan ve aşağıda yer verilen hukuka uygunluk sebeplerine
        dayanılarak otomatik veya otomatik olmayan yollarla işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Fiili imkânsızlık halleri ortaya çıkması durumunda, rızanızı
          açıklayamayacak durumda bulunmanız veya rızasına hukuki geçerlilik
          tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden
          bütünlüğünün korunması için veri işlemenin zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Şirket’in hukuki yükümlülüğün yerine getirebilmesi için zorunlu
          olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
          olması.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>C- TEDARİKÇİ ÇALIŞANI</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>C.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1">Baymak</span>
      <span class="s4"
        >ile ilişkiniz kapsamında aşağıdaki kişisel verileriniz
        işlenmektedir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li16">
        <span class="s3">
          <b>Kimlik Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Ad-soyadı, anne-baba adı, TCKN/VKN, pasaport numarası, uyruk,
          ehliyet, doğum tarihi/yeri, medeni hal,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>İletişim Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Adres bilgileri, telefon numarası, e-posta adresi, kayıtlı elektronik
          posta adresi (KEP),</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Mesleki Deneyim Bilginiz:</b>
        </span>
        <span class="s4"
          >Meslek bilgisi, diploma bilgileri, meslek için eğitim bilgileri,
          sertifikalar,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Finansal Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Banka hesabı bilgileri, banka/hesap/kart bilgileri,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Özel Nitelikli Verileriniz:</b>
        </span>
        <span class="s4">Engellilik durumuna ait bilgiler,</span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>Diğer Bilgileriniz:</b>
        </span>
        <span class="s4">Araç plaka bilgileri.</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>C.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p12">
      <span class="s1"
        >Kişisel verileriniz, ilgili mevzuata uygun olarak aşağıdaki amaçlar ile
        işlenebilecektir:</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Cari kartı açılması ve cari hesap görüntüleme süreçlerinin
          yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Bireysel emeklilik işlemlerinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1">Tedarikçi personelinin takip edilebilmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Tedarikçi personel bilgilerinin saklanması ve sisteme
          kaydedilmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Çağrı merkezi kayıtlarının oluşturulması,</span>
      </li>
      <li class="li10">
        <span class="s1">Yetkili servis kayıtlarının oluşturulması,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Çözüm merkezi şikâyet kayıtlarının oluşturulması,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Ek garanti satışı aramalarının yapılması,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Tedarikçi çağrı merkezi çalışanlarına CRM erişim yetkisinin
          verilmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Araç/şoför bilgilerinin takip edilmesi, malzeme lojistik sürecinin
          takip edilmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Sipariş formlarının değerlendirilmesi,</span>
      </li>
      <li class="li10">
        <span class="s1">İrsaliye düzenlenmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Bakım ve tedarik şirketleri ile koordinasyon sağlanması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Sözleşme ve kıymetli evrak inceleme süreçlerinin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">İş kazasına yönelik kaza raporu hazırlanması,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Tedarikçi personeline yönelik yeterlilik sürecinin yönetilmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Servis cari hesap ve yan sanayi cari hesap ödemelerinin
          yapılması,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Cari hesap mutabakatlarının yapılması,</span>
      </li>
      <li class="li10">
        <span class="s1">Operasyonel faaliyetlerin yürütülmesi.</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>C.III. KİŞİSEL VERİLERİNİZİN AKTARIMI</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin yurtiçi ve yurtdışına
        aktarılmasına ilişkin hükümleri kapsamında işbu İşbu Aydınlatma
        Metni’nin</span
      >
      <span class="s6">C.II başlığı</span>
      <span class="s1"
        >altında yer alan amaçların gerektirdiği ölçüde; yurt içindeki yetkili
        kişi, kurum ve kuruluşlara, iş ortaklarına ve tedarikçilere
        aktarılabilmektedir.</span
      >
    </p>
    <p class="p4">
      <span class="s1">
        <b
          >C. IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Baymak, kişisel verilerinizi iş ortaklarımızdan, Şirketimizde görev
        almanız sırasında doğrudan sizlerden veya yasal mercilerden olmak
        kaydıyla internet, telefon, e-posta aracılığıyla ve fiziki, yazılı,
        sözlü ve elektronik mecralardan yukarıda belirtilen amaçlar dahilinde
        KVKK’nın 5, 6, 8. ve 9. madde hükümlerinde sayılan ve aşağıda yer
        verilen hukuka uygunluk sebeplerine dayanılarak otomatik ve otomatik
        olmayan yollarla işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Hukuki yükümlülüğün yerine getirebilmesi için zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,</span
        >
      </li>
    </ul>
    <p class="p16">
      <span class="s1"
        >Özel nitelikli kişisel verileriniz ise açık rızanın bulunması hukuka
        uygunluk sebeplerine dayanılarak toplanmakta, saklanmakta ve
        işlenmektedir.</span
      >
    </p>
    <p class="p1">
      <span class="s1">
        <b>D- BAYİ ÇALIŞANI/YETKİLİSİ</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>D.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1">BAYMAK</span>
      <span class="s4"
        >ile ilişkiniz kapsamında aşağıdaki kişisel verileriniz
        işlenmektedir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li16">
        <span class="s3">
          <b>Kimlik Bilgileriniz</b>
        </span>
        <span class="s4">
          <b>:</b> Ad soyad, anne baba adı, TCKN/VKN, medeni hal, doğum tarihi,
          doğum yeri,
        </span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>İletişim Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Telefon numarası, adres bilgileri, e-posta adresi,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Finans Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Fatura, senet, çek bilgileri, fatura ve borç bilgisi, finansal
          performans bilgileri, tahsilat riski bilgileri, kredi ve risk
          bilgileri, banka hesap kart bilgileri,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Lokasyon Bilginiz:</b>
        </span>
        <span class="s4">Konum bilgisi,</span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>Diğer Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Alışveriş geçmişi bilgileri, kampanya çalışmasıyla elde edilen
          bilgiler, sipariş bilgisi.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>D.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p12">
      <span class="s1"
        >Kişisel verileriniz, ilgili mevzuata uygun olarak aşağıdaki amaçlar ile
        işlenebilecektir:</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1">KDV iadesi sürecinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1">İmza sirkülerinin kontrol edilmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Bayilere yönelik Pazar araştırmalarının yapılması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >BES formunun doldurulması ve ilgili sürecin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Web sitemizdeki bayi bilgilerinin görüntülenmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Bayilerin risk yönetimi süreçlerinin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Sevkiyat bilgilerinin aktarılması.</span>
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>D. III. İŞLENEN KİŞİSEL VERİLERİN AKTARIMI</b>
      </span>
    </p>
    <p class="p1">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin aktarılmasına ilişkin
        hükümleri kapsamında işbu Aydınlatma Metni’nin</span
      >
      <span class="s6">D.II başlığı</span>
      <span class="s1"
        >altında yer alan amaçların gerektirdiği ölçüde yurt içindeki; yetkili
        kişi, kurum ve kuruluşlara, iş ortaklarına ve tedarikçilere
        aktarılabilmektedir.</span
      >
    </p>
    <p class="p1">
      <span class="s1">
        <b
          >D. IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, Şirketimizle hukuki ilişkinizin kurulması
        esnasında ve söz konusu ilişkinin devamı süresince sizlerden, iş
        ortaklarımızdan, yasal mercilerden olmak kaydıyla internet sitesi,
        muhtelif sözleşmeler, elektronik posta araçları üzerinden, Şirketimiz
        ile yapılan yazılı veya sözlü iletişim kanalları aracılığıyla sözlü,
        yazılı veya elektronik ortamda toplanmaktadır. Bu doğrultuda toplanan
        kişisel verileriniz, KVKK’nın 5, 8 ve 9. maddelerinde belirtilen ve
        aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak
        işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Hukuki yükümlülüğün yerine getirebilmesi için zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
          olması.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>E- MÜŞTERİ</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>E.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1">Baymak</span>
      <span class="s4"
        >tarafından aşağıdaki kişisel verileriniz işlenmektedir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li16">
        <span class="s3">
          <b>Kimlik Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Ad soyad, anne baba adı, TCKN/VKN, medeni hal, doğum tarihi, doğum
          yeri,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>İletişim Bilgileriniz</b>
        </span>
        <span class="s4"
          >: Telefon numarası, e-posta adresi, adres bilgileri, sosyal medya
          hesapları, kayıtlı elektronik posta adresi (KEP),</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Finansal Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Fatura/senet/çek bilgileri, fatura ve borç bilgisi, finansal
          performans bilgileri, kredi ve risk bilgileri, banka hesap bilgileri,
          banka/hesap/kart bilgileri, malvarlığı bilgileri,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Müşteri İşlem Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Alışveriş geçmiş bilgileri, kampanya ve anket çalışmalarıyla elde
          edilen bilgiler, kullanım alışkanlıkları/davranış analizi, sipariş
          bilgisi, talep bilgisi,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>İşlem Güvenliğine İlişkin Bilgileriniz:</b>
        </span>
        <span class="s4">IP adresi bilgileri, log kayıtları,</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>E.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p22">
      <span class="s1"
        >Baymak ile ilişkiniz kapsamında elde edilen kişisel verileriniz aşağıda
        yer verilen amaçlarla işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Satış ve pazarlama süreçlerinin yürütülmesi ve satış raporlarının
          yapılması,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Saha testi sürecinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Log kayıtlarının tutulması ve incelenmesi süreçlerinin
          yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Üst düzey iş ilişkilerinin takibi ve geliştirilmesi süreçlerinin
          yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Garanti sürecinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Sosyal medya şikayetlerinin koordinasyonunun sağlanması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Tüketici Hakem Heyeti kararlarının yerine getirilmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Gider pusulası kullanılması sürecinin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Nihai müşteriler ile iletişime geçilmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Organizasyonların düzenlenmesi ve promosyon gönderilmesine ilişkin
          süreçlerin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Yerinde montaj ve servis hizmeti sunulması,</span>
      </li>
      <li class="li10">
        <span class="s1">Şikâyet yönetiminin sağlanması.</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>E.III. KİŞİSEL VERİLERİNİZİN AKTARIMI</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin aktarılmasına ilişkin
        hükümleri kapsamında işbu Aydınlatma Metni’nin</span
      >
      <span class="s6">E.II başlığı</span>
      <span class="s1"
        >altında yer alan amaçların gerektirdiği ölçüde yurtiçindeki; iş
        ortakları ve tedarikçileri ile paylaşılmaktadır.</span
      >
    </p>
    <p class="p4">
      <span class="s1">
        <b
          >E. IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, Şirketimize yaptığınız iş başvurusu sırasında
        bizzat sizin tarafınızdan veya iş ortaklarımız vasıtasıyla internet,
        telefon, e-posta aracılığıyla ve fiziki, yazılı, sözlü ve elektronik
        mecralardan yukarıda belirtilen amaçlar dahilinde Kanun’un 5 ve 8. madde
        hükümlerinde sayılan ve aşağıda yer verilen hukuka uygunluk sebebine
        dayanılarak toplanmakta, saklanmakta ve işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1">Açık rızanızın bulunması,</span>
      </li>
      <li class="li10">
        <span class="s1">Hukuki yükümlülüğün yerine getirilmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>F- POTANSİYEL MÜŞTERİ</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>F.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1">Baymak</span>
      <span class="s4"
        >tarafından aşağıdaki kişisel verileriniz işlenmektedir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li16">
        <span class="s3">
          <b>Kimlik Bilgileriniz:</b>
        </span>
        <span class="s4">Ad soyad,</span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>İletişim Bilgileriniz</b>
        </span>
        <span class="s4"
          >: Telefon numarası, e-posta adresi, adres bilgileri, kayıtlı
          elektronik posta adresi (KEP).</span
        >
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>F.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p22">
      <span class="s1"
        >Baymak ile ilişkiniz kapsamında elde edilen kişisel verileriniz aşağıda
        yer verilen amaçlarla işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Yapılan fuarlar sonrası potansiyel müşteri takip listesinin
          tutulması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Üst düzey iş ilişkileri ve geliştirilmesi sürecinin
          yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Satış ve pazarlama faaliyetleri kapsamında potansiyel müşteri
          listelerinin oluşturulması.</span
        >
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>F.III. KİŞİSEL VERİLERİNİZİN AKTARIMI</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin aktarılmasına ilişkin
        hükümleri kapsamında işbu Aydınlatma Metni’nin</span
      >
      <span class="s6">F.II başlığı</span>
      <span class="s1"
        >altında yer alan amaçların gerektirdiği ölçüde şirket içi ekipler ve
        yurtdışındaki; topluluk şirketleri ile paylaşılmaktadır.</span
      >
    </p>
    <p class="p4">
      <span class="s1">
        <b
          >F. IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, Şirketimize yaptığınız iş başvurusu sırasında
        bizzat sizin tarafınızdan veya iş ortaklarımız vasıtasıyla internet,
        telefon, e-posta aracılığıyla ve fiziki, yazılı, sözlü ve elektronik
        mecralardan yukarıda belirtilen amaçlar dahilinde Kanun’un 5 ve 8. madde
        hükümlerinde sayılan ve aşağıda yer verilen hukuka uygunluk sebebine
        dayanılarak toplanmakta, saklanmakta ve işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1">Açık rızanızın bulunması,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>G- İŞ ORTAĞI ÇALIŞANI/YETKİLİSİ</b>
      </span>
    </p>
    <p class="p4">
      <span class="s1">
        <b>G.I. İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1">Baymak</span>
      <span class="s4"
        >tarafından aşağıdaki kişisel verileriniz işlenmektedir;</span
      >
    </p>

    <ul class="ul1">
      <li class="li16">
        <span class="s3">
          <b>Kimlik Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Ad soyad, anne baba adı, TCKN/VKN, uyruk, medeni hal, ehliyet, doğum
          tarihi, doğum yeri,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>İletişim Bilgileriniz</b>
        </span>
        <span class="s4"
          >: Adres bilgileri, telefon numarası, e-posta adresi,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>Eğitim, İş ve Profesyonel Yaşama İlişkin Bilgileriniz</b>
        </span>
        <span class="s4">: Meslek bilgisi, özgeçmiş bilgileri,</span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>Finansal Bilgileriniz:</b>
        </span>
        <span class="s4"
          >Fatura/borç bilgileri, malvarlığı bilgileri, banka/hesap/kart
          bilgileri, finansal performans bilgileri, kredi ve risk bilgileri,
          malvarlığı bilgileri, banka hesabı bilgileri,</span
        >
      </li>
      <li class="li16">
        <span class="s3">
          <b>İşlem Güvenliğine İlişkin Bilgileriniz:</b>
        </span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>Görsel ve İşitsel Kayıtlara İlişkin Bilgileriniz:</b>
        </span>
        <span class="s4">Fotoğraf,</span>
      </li>
      <li class="li16">
        <span class="s3">
          <b>Lokasyon Bilginiz:</b>
        </span>
        <span class="s4">Konum bilgisi.</span>
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>G.II. KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</b>
      </span>
    </p>
    <p class="p22">
      <span class="s1"
        >Baymak ile ilişkiniz kapsamında elde edilen kişisel verileriniz aşağıda
        yer verilen amaçlarla işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Adli ve idari birimlerden gelen yazılara cevap verilmesine ilişkin
          sürecin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Yetkili servis BES desteği kayıtlarının tutulması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Erişim yetkisinin tanınmasına ilişkin sürecin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Cari kart oluşturulmasına ilişkin sürecin yürütülmesi,</span
        >
      </li>
      <li class="li10">
        <span class="s1">Sözleşme süreçlerinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1">Sipariş sürecinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1">Satış sürecinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1">Lojistik faaliyetinin yürütülmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >İş Ortakları ve servisler ile iletişime geçilmesi.</span
        >
      </li>
    </ul>
    <p class="p4">
      <span class="s1">
        <b>G.III. KİŞİSEL VERİLERİNİZİN AKTARIMI</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, KVKK’nın kişisel verilerin aktarılmasına ilişkin
        hükümleri kapsamında işbu Aydınlatma Metni’nin</span
      >
      <span class="s6">G.II başlığı</span>
      <span class="s1"
        >altında yer alan amaçların gerektirdiği ölçüde yurtiçindeki; yetkili
        kişi, kurum ve kuruluşlar, iş ortakları ve tedarikçileri ile
        paylaşılmaktadır.</span
      >
    </p>
    <p class="p4">
      <span class="s1">
        <b
          >G. IV. KİŞİSEL VERİLERİN İŞLENMESİNİN HUKUKİ SEBEBİ VE TOPLANMA
          YÖNTEMİ</b
        >
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Kişisel verileriniz, Şirketimize yaptığınız iş başvurusu sırasında
        bizzat sizin tarafınızdan veya iş ortaklarımız vasıtasıyla internet,
        telefon, e-posta aracılığıyla ve fiziki, yazılı, sözlü ve elektronik
        mecralardan yukarıda belirtilen amaçlar dahilinde Kanun’un 5 ve 8. madde
        hükümlerinde sayılan ve aşağıda yer verilen hukuka uygunluk sebebine
        dayanılarak toplanmakta, saklanmakta ve işlenmektedir.</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1">Hukuki yükümlülüğün yerine getirilmesi,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
          işlenmesinin gerekli olması,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
          olması.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>H. KİŞİSEL VERİLERİNİZİN KORUNMASINA YÖNELİK HAKLARINIZ</b>
      </span>
    </p>
    <p class="p2">
      <span class="s1"
        >Bu Aydınlatma Metni’nin “İletişim” bölümünde yer alan yöntemlerle
        Şirketimize başvurarak,</span
      >
    </p>

    <ul class="ul1">
      <li class="li10">
        <span class="s1"
          >Kişisel verilerinizin işlenip işlenmediğini öğrenme,</span
        >
      </li>
      <li class="li10">
        <span class="s1">İşlenmişse buna ilişkin bilgi talep etme,</span>
      </li>
      <li class="li10">
        <span class="s1"
          >Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri
          bilme,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların
          düzeltilmesini isteme,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >KVKK’da öngörülen şartlar çerçevesinde kişisel verilerinizin
          silinmesini veya yok edilmesini isteme,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Yukarıda belirtilen düzeltme, silinme ve yok edilme şeklindeki
          haklarınız uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı
          üçüncü kişilere bildirilmesini isteme,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >İşlenen kişisel verilerinizin münhasıran otomatik sistemler ile
          analiz edilmesi sureti ile aleyhinize bir sonucun ortaya çıkmasına
          itiraz etme,</span
        >
      </li>
      <li class="li10">
        <span class="s1"
          >Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi
          sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep
          etme haklarına sahiptir.</span
        >
      </li>
    </ul>
    <p class="p1">
      <span class="s1">
        <b>I. HAK VE TALEPLERİNİZ İÇİN İLETİŞİM</b>
      </span>
    </p>
    <p class="p22">
      <span class="s1">
        Kişisel verilerinizle ilgili sorularınızı ve taleplerinizi, Veri
        Sorumlusuna Başvuru Usul ve Esasları hakkında Tebliğ’de belirtilen
        şartlara uygun düzenlenmiş dilekçeyle Orhanlı Beldesi, Orta Mahalle
        Akdeniz Sokak No:8 Tepeören Mevkii Orhanlı/Tuzla 34959 İSTANBUL adresine
        kimlik tespiti yapılmak suretiyle bizzat elden iletebilir ya da noter
        kanalıyla ulaştırabilirsiniz. Bunun yanında,
        <a href="mailto:baymak@hs03.kep.tr">baymak@hs03.kep.tr</a> kayıtlı
        elektronik posta (KEP) adresine, güvenli elektronik imza ve mobil imza
        ya da tarafınızca şirketimize daha önce bildirilen ve tarafımızca teyit
        edilmiş olan şirket sistemimizdeki elektronik posta adresini kullanmak
        suretiyle merkez1@baymak.com.tr elektronik posta adresine
        iletebilirsiniz.
      </span>
    </p>
    <p class="p1">
      <span class="s1">
        <b>J. AYDINLATMA METNİ HAKKINDA</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1"
        >Bu Aydınlatma Metni Baymak tarafından yayımlandığı tarih itibariyle
        geçerli olacaktır. Şirket, bu Aydınlatma Metni’nin, gerekli olduğu
        takdirde, her zaman değişiklik yapabilir. Şirket tarafından yapılacak
        değişiklikler, Aydınlatma Metni’nin www.baymaklife.com.tr adresinde
        yayımlanmasıyla birlikte geçerlilik kazanır.</span
      >
    </p>

    <p class="p1">
      <span class="s1">
        <b>Gizlilik</b>
      </span>
    </p>
    <p class="p16">
      <span class="s1">
        Üye, Baymak ile ilgili gizlilik kurallarına ve ticari itibar ve
        unvanının korunmasına ve korumaya hassasiyetle riayet edecektir. Her ne
        suretle olursa olsun istem dışı olarak dahi vakıf olduğu veya kendisine
        Baymak tarafından verilen bilgileri başka bir Üyeye, üçüncü şahıslara ve
        ilgisi olmayan kişilere sözlü, yazılı veya herhangi bir başka biçimde
        aktarmayacaktır. Bu maddedeki yükümlülüğe aykırılık halinde, Baymak’ ın
        Üye’ ye ve sorumlu şahsa karşı gizlilik ve sadakat borçlarına aykırılık
        nedeniyle her türlü dava ve talep hakkı mevcuttur.
      </span>
    </p>

    <p class="p16">
      <span class="s1">
        Baymak’ ın hizmetleri ile ilgili ürünler, aktiviteler, organizasyonlar,
        eğitimler ve kampanyalar hakkında link ve yönlendirmeleri, tanıtımları,
        haberleri veya reklamları da içerebilen şekilde beni bilgilendirmesi
        için Baymak’ın tüm bilgilerimi (telefon numaraları, faks, posta ve
        e-posta adreslerimi) bana ulaşmak için kullanmasına, yasal düzenleme
        kapsamında ticari iletişim ve ticari elektronik ileti (sms, e-mail v.s.)
        göndermesine izin/onay veriyorum.
      </span>
    </p>

    <p class="p16">
      <span class="s1">
        Baymak’a, işbu form ile beyan etmiş olduğum kişisel verilerimin
        (“Kişisel Veri/ler”) doğru olduğunu ve Kişisel Verilerin Korunması
        Hakkındaki Kanun’da belirlenmiş olan esaslar çerçevesinde işlenmesine,
        3. kişilere aktarılmasına açıkça rıza gösterdiğimi kabul ve beyan
        ederim. Kişisel verilerin işlenmesi ve 3. kişilere aktarılması, Baymak
        ile aramızda kurulan iş ilişkisine dayanmaktadır. Kişisel Verilerimin,
        Baymak ve iştirakleri tarafından işbu iş ilişkisi kapsamında, sözleşme
        ilişkisi bulunan iş ortakları tarafından karşılıklı yükümlülükler,
        analiz, istatistik gibi amaçlarla işlenebileceğini, 3. kişilere
        aktarılarak veya anonimleştirilerek kullanılabilir.
      </span>
    </p>

    <p class="p16">
      <span class="s1">
        Madde 24- Tüm bu koşullar Üye’nin ayrıca imzasına gerek olmaksızın
        üyelik için başvuruda bulunması, sistemden kayıtlı olarak fiili
        kullanımla yararlanıyor olması, ya da hediye talep etmesi ve/veya
        gönderilen hediyeyi teslim alması ile işbu hükümlere tabidir ve hükümler
        hukuken geçerli olur.
      </span>
    </p>

    <p class="p16">
      <span class="s1">
        Üye, tüketicilerden KVKK ve ETK kapsamında veri işleme ve elektronik
        ticari ileti göndermek için alacağı onayları Baymak tarafından istenen
        şekilde ve tüketicinin mobil telefonuna SMS ile onay kodu gönderilmek
        suretiyle alacaktır. Aksi halde hiç alınmayan veya kanuna ve Baymak
        tarafından belirtilen usule göre alınmayan onay sebebiyle tüketici
        şikayeti sonucu bir zarar meydana gelmesi halinde üye zararı
        karşılamakla yükümlüdür.
        <br />
        İşbu koşulların uygulanmasından doğabilecek her türlü uyuşmazlıkların
        çözümünde İstanbul Anadolu Mahkemeleri ve İcra Müdürlükleri yetkili
        olacaktır.
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "SafetyPrivacy"
};
</script>
